import { FollowUpUserServiceClient } from "./followUpUser";
import { PatientServiceClient } from "./patient";
import { SystemServiceClient } from "./system";
import { UserServiceClient } from "./user";
import { InstantMessageClient } from "./instantMessage";
import { FollowUpServiceClient } from "./followUpProject";
import { HospitalDataClient } from "./hospitalData";
import { HomeServiceClient } from "./home";
import { FollowUpOutpatientServiceClient } from "./followUpOutpatient";
import { FollowUpFormServiceClient } from "./followUpForm";
import { FollowUpProjectAllServiceClient } from "./followUpProjectAll";

export const serviceClient = {
  user: new UserServiceClient(),
  patient: new PatientServiceClient(),
  followUpUser: new FollowUpUserServiceClient(),
  system: new SystemServiceClient(),
  instantMessage: new InstantMessageClient(),
  followUpProject: new FollowUpServiceClient(),
  hospitalData: new HospitalDataClient(),
  home: new HomeServiceClient(),
  followUpOutpatient: new FollowUpOutpatientServiceClient(),
  followUpForm: new FollowUpFormServiceClient(),
  followUpProjectAll: new FollowUpProjectAllServiceClient(),
};
