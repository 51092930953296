/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useMemo } from "react";
import { Typography } from "../../components";
import { TypographyProps } from "../../components/Typography";

export interface DropdownTitleType extends TypographyProps {
  changeColor?: boolean;
  title?: string;
  isSelected?: boolean;
  children?: ReactNode;
  className?: string;
}

export default React.memo(function DropdownTitle(props: DropdownTitleType) {
  const {
    changeColor = true,
    title,
    isSelected = false,
    children,
    className,
    ...rest
  } = props;

  const color = useMemo(() => {
    return changeColor
      ? isSelected
        ? "primaryColor"
        : "graySecondaryColor"
      : "grayMainColor";
  }, [changeColor, isSelected]);

  const _css = useMemo(() => {
    return [
      rest?.type === "title" &&
        css`
          font-weight: 400;
        `,
    ];
  }, [rest?.type]);

  return (
    <>
      <Typography {...rest} colorType={color} css={_css} className={className}>
        {title}
      </Typography>
      {children}
    </>
  );
});
