/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import {
  Slider as AntdSlider,
  SliderProps as AntdSliderProps,
} from "antd-mobile";

export interface SliderProps extends AntdSliderProps {}

export default React.memo(function Slider(props: SliderProps) {
  const _className = useMemo(() => {
    return [
      css`
        &.adm-slider-disabled .adm-slider-mark,
        &.adm-slider-disabled .adm-slider-thumb-icon {
          opacity: 1;
        }
        &.adm-slider-disabled .adm-slider-tick-active::after,
        &.adm-slider-disabled .adm-slider-fill::after {
          background-color: rgba(255, 255, 255, 0);
        }
        &.adm-slider-disabled .adm-slider-thumb {
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
        }
        .adm-slider-thumb-container {
          width: 14px;
          height: 14px;
        }
        .adm-slider-thumb {
          width: 12px;
          height: 12px;
          margin: 1px 2px;
          line-height: 12px;
          border: 3px solid #1677ff;
        }
        .adm-slider-thumb-icon {
          display: none;
        }
      `,
    ];
  }, []);

  return <AntdSlider className="w-48 px-1" ticks {...props} css={_className} />;
});
