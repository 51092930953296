/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Divider } from "antd-mobile";
import useDoctorStore from "../../store/doctor";
import { Typography } from "../../components";
import FollowupIndex from "../../coreUIComponents/FollowUp";
import ProjectSelect from "../../coreUIComponents/FollowUp/ProjectSelect";

export default observer(function FollowUpProject() {
  const doctorStore = useDoctorStore();
  const {
    userLogicStore,
    followUpStore,
    followUpProjectStore,
    patientEntityStore,
  } = doctorStore;

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const { projectPlanInfo, projectHealthInfo } = useMemo(
    () => followUpProjectStore,
    [followUpProjectStore]
  );

  const selectedProject = useMemo(
    () => followUpStore.selectedProject,
    [followUpStore.selectedProject]
  );

  const activeKey = useMemo(
    () => followUpStore.activeKey,
    [followUpStore.activeKey]
  );

  useEffect(() => {
    selectedProject?.empIds?.length > 0 &&
      patientEntityStore.serachPatients(selectedProject?.empIds);
  }, [patientEntityStore, selectedProject?.empIds]);

  useEffect(() => {
    selectedProject?.id &&
      followUpProjectStore.followUpPlanInfo(selectedProject?.id);
  }, [followUpProjectStore, selectedProject?.id]);

  return (
    <>
      <div className="page-padding">
        <Typography block type="title-bold" className="mb-2">
          {userLogicStore.saasToken
            ? `您好，${userInfo?.name}医生～`
            : "您好，访客～"}
        </Typography>

        <ProjectSelect
          keyId="dProject"
          type="OneNoColor"
          className="justify-start"
        />

        <Divider className="-mx-4 mt-0.5 mb-1"></Divider>
      </div>

      <FollowupIndex
        type="FollowUpProject"
        activeKey={activeKey}
        healthType="HealthManageProject"
        projectId={selectedProject?.id}
        nodes={projectPlanInfo}
        healthInfo={projectHealthInfo}
      />
    </>
  );
});
