import React from "react";
import { Typography, Slider } from "../../../../components";
import { GetProjectListPromiseType } from "../../../../models/entity/followUpProject";

export interface DropdownItemProgressType {
  data?: GetProjectListPromiseType;
}

export default React.memo(function DropdownItemProgress(
  props: DropdownItemProgressType
) {
  const { data } = props;

  return (
    <div className="flex items-center mt-2">
      {data?.sort === 1 ? (
        <>
          <Typography type="secondary">进度：</Typography>
          <Slider disabled defaultValue={Number(data?.progress || 0)} />
          <Typography colorType="primaryColor" className="font-medium ml-2">
            {data?.progress || 0}%
          </Typography>
        </>
      ) : (
        <Typography type="secondary">
          {data?.status === 2 ? "已结束" : "已终止"}
        </Typography>
      )}
    </div>
  );
});
