/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  CheckList as AntdCheckList,
  CheckListProps as AntdCheckListProps,
} from "antd-mobile";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

export type CheckListValueType =
  | string
  | number
  | Array<string | number>
  | undefined;
export interface CheckListProps<T extends CheckListValueType>
  extends Omit<AntdCheckListProps, "value" | "onChange"> {
  value?: T;
  onChange?: (value?: T) => void;
  options?: { value: string | number; label: ReactNode }[];
  labelTextAlign?: "center" | "left";
}

export default React.memo(function CheckList<
  T extends CheckListValueType = string
>(props: CheckListProps<T>) {
  const {
    value,
    onChange,
    options,
    multiple,
    labelTextAlign = "left",
    children,
    ...restProps
  } = props;

  const [internalValue, setInternalValue] = useState<
    (string | number)[] | undefined
  >(undefined);

  const _onChange = useCallback(
    (val: string[] | undefined) => {
      if (!multiple && val && !val?.length) {
        onChange?.(internalValue?.[0] as T);
        return;
      }
      const _value = multiple ? val : val?.[0];
      setInternalValue(val as string[]);
      onChange?.(_value as T);
    },
    [internalValue, multiple, onChange]
  );

  useEffect(() => {
    const _value = multiple ? value : [value];
    setInternalValue(_value as string[]);
  }, [multiple, value]);

  return (
    <AntdCheckList
      css={[
        css`
          .adm-list-body {
            font-size: 16px;
            .adm-list-item {
              padding-left: 0;
            }
          }
        `,
        labelTextAlign === "center" &&
          css`
            .adm-list-item-content {
              position: relative;
              text-align: center;
              .adm-check-list-item-extra {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          `,
      ]}
      onChange={_onChange as any}
      value={internalValue as string[]}
      multiple={multiple}
      {...restProps}
    >
      {options
        ? options.map((item) => {
            return (
              <AntdCheckList.Item value={item.value as string} key={item.value}>
                {item.label}
              </AntdCheckList.Item>
            );
          })
        : children}
    </AntdCheckList>
  );
});

export const CheckListItem = AntdCheckList.Item;
