/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { start } from "qiankun";
import { useEffect } from "react";

export default observer(function FormGather() {
  useEffect(() => {
    start();
  }, []);

  return (
    <div
      id="formGather"
      css={css`
        height: 100%;
        #__qiankun_microapp_wrapper_for_visit__ {
          height: inherit;
          #visit-app {
            height: inherit;
            .app-wrapper {
              height: inherit;
              .main-container {
                height: inherit;
                .visit-app-main {
                  height: inherit;
                }
              }
            }
          }
        }
      `}
    ></div>
  );
});
