/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Form, Input, Radio, Toast } from "antd-mobile";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { serviceClient } from "../../service";
import { encodeStr } from "../../utils/jsencrypt";
import { useNavigate } from "react-router-dom";
import { useActivate } from "react-activation";

const DoctorLogin = () => {
  const navigateTo = useNavigate();
  const numberErrors = useRef<number>(0);

  const [form] = Form.useForm();
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [isCheckedAgreement, setIsCheckedAgreement] = useState(false);
  const [lockedOrNot, setLockedOrNot] = useState(false);

  const userInfo = useRef<any>({});

  const onValuesChange = useCallback(
    (changedValues: any, allValues: object) => {
      const emptyCount = Object.entries(allValues).filter(([key, value]) => {
        return [undefined, null, ""].includes(value);
      });
      setIsAllFilled(!emptyCount.length);
    },
    []
  );

  useLayoutEffect(() => {
    userInfo.current = JSON.parse(localStorage.getItem("wxInfo") ?? "{}");
    checkAccount();
  }, []);

  useActivate(() => {
    userInfo.current = JSON.parse(localStorage.getItem("wxInfo") ?? "{}");
    checkAccount();
  });

  const checkAccount = async () => {
    try {
      await serviceClient.followUpUser.checkAccount(userInfo.current);
      setLockedOrNot(false);
    } catch {
      setLockedOrNot(true);
    }
  };

  const onFinish = useCallback(async () => {
    const { username, password } = form.getFieldsValue();

    let info = userInfo.current;

    info.type = 2;
    const payload: any = {
      ...info,
      username: encodeStr(username, info.publicKey),
      password: encodeStr(password, info.publicKey),
    };

    try {
      setLoading(true);
      const res = await serviceClient.followUpUser.roleBinding(payload);

      if (res) {
        numberErrors.current = 0;
        localStorage.setItem("wxInfo", JSON.stringify({ ...info }));

        window.location.replace(`serviceSubscription`);
      } else {
        Toast.show({
          content: "登录失败",
        });
      }
    } catch (err: any) {
      if (err?.code === 406) {
        setLockedOrNot(true);
        return;
      }

      if (err.data && typeof err.data === "number") {
        numberErrors.current = err.data;
      } else {
        numberErrors.current = 0;
      }
      await form.validateFields();
    } finally {
      setLoading(false);
    }
  }, [form]);

  const checkPassword = () => {
    if (numberErrors.current) {
      return Promise.reject(
        new Error(
          numberErrors.current >= 6
            ? "您的账号已被锁定，请联系管理员处理。"
            : `密码错误 (${numberErrors.current}/6)，输入6次错误后需管理员解锁!`
        )
      );
    }
    return Promise.resolve();
  };

  const userAgreement = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    navigateTo("/agreementStatement");
  };

  const clickRadio = (event: any) => {
    event.stopPropagation();
    setIsCheckedAgreement((isCheckedAgreement) => !isCheckedAgreement);
  };

  return (
    <div
      css={css`
        height: 100vh;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;
      `}
    >
      {!lockedOrNot && (
        <div>
          <h2>登录</h2>
          <span
            css={css`
              font-size: 12px;
              color: #999999;
            `}
          >
            请使用科研平台的账号与密码，若忘记密码请至科研平台查询
          </span>
        </div>
      )}
      {lockedOrNot ? (
        <div
          css={css`
            height: 100%;
            text-align: center;
            margin-top: 50px;
          `}
        >
          <div>
            <p>账号已被锁定,请联系管理员解除锁定!</p>
            <p>解除锁定后，即可重新登录</p>
            {/* <Button
              color="primary"
              onClick={() => {
                setLockedOrNot(false);
              }}
            >
              重新登录
            </Button> */}
          </div>
        </div>
      ) : (
        <Form
          css={css`
            --prefix-width: 76px;
            --border-top: 0px;
            .adm-list {
              font-size: 16px;
              margin-top: 20px;
              .adm-list-item {
                padding-left: 0;
                .adm-list-item-content-prefix {
                  color: #666666;
                }
              }
            }
          `}
          form={form}
          layout="horizontal"
          onValuesChange={onValuesChange}
          footer={
            <div className=" text-center">
              <Button
                loading={loading}
                block
                shape="rounded"
                type="submit"
                color="primary"
                size="large"
                disabled={!(isAllFilled && isCheckedAgreement)}
                onClick={onFinish}
              >
                登录
              </Button>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  padding-top: 15px;
                  line-height: 22px;
                `}
              >
                <span onClick={clickRadio}>
                  <Radio
                    css={css`
                      --font-size: 14px;
                      --icon-size: 18px;
                    `}
                    checked={isCheckedAgreement}
                  >
                    <span>同意</span>
                  </Radio>
                </span>
                <Button
                  onClick={(e) => userAgreement(e)}
                  color="primary"
                  fill="none"
                  size="mini"
                >
                  随访用户服务协议及隐私声明
                </Button>
              </div>
            </div>
          }
        >
          <Form.Item name="username" rules={[{ required: true }]}>
            <Input placeholder="请输入账号" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true },
              { validator: checkPassword, warningOnly: true },
            ]}
          >
            <Input type="password" placeholder="请输入密码" />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default DoctorLogin;
