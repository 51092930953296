import { useRoutes } from "react-router-dom";

import { patientRouters, TPatientRouterKey } from "./config";
import { useRouter } from "..";

export function PatientRouter() {
  const element = useRoutes(patientRouters);
  return element;
}

export const usePatientRouter = function () {
  return useRouter<TPatientRouterKey>();
};
