/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { ArrowIcon, Typography } from "../../../components";
import { useTheme } from "../../../theme";

export default observer(function UserInfoItem(props: {
  title: ReactNode;
  value: ReactNode;
  showArrow?: boolean;
}) {
  const theme = useTheme();
  const { title, value, showArrow } = props;

  return (
    <div
      className="py-4"
      css={css`
        border-bottom: ${theme.styles.border};
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Typography
        type="title"
        colorType="graySecondaryColor"
        className="mr-1"
        css={css`
          flex-basis: 30%;
          flex-shrink: 0;
        `}
      >
        {title}
      </Typography>
      <div
        css={css`
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          overflow: auto;
        `}
      >
        <Typography type="title" truncation>
          {value}
        </Typography>
        {showArrow && <ArrowIcon direction="right"></ArrowIcon>}
      </div>
    </div>
  );
});
