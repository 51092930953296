/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperRef } from "antd-mobile";
import { useSearchParams } from "react-router-dom";
import { useAppUserIdentityType } from "../../../hooks";
import { useDoctorRouter } from "../../../router/Doctor";
import { usePatientRouter } from "../../../router/Patient";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
import { basePath as doctorBasePath } from "../../../router/Doctor/config";
import { basePath as patientBasePath } from "../../../router/Patient/config";
import { Typography, Button, SearchBar } from "../../../components";
import DropdownSelect from "../../DropdownSelect";
import DropdownTitle from "../../DropdownSelect/DropdownTitle";
import SearchItem from "./SearchItem";
import patientEdit from "../../../assets/icons/patientEdit.svg";
import usergroup from "../../../assets/icons/usergroup.svg";
import usergroupCheck from "../../../assets/icons/usergroupCheck.svg";
import { FormListType } from "../../../models/entity/followUpProject";

export default observer(function SecondaryHead(props: {
  index?: number;
  formType?: number;
  formName?: string;
  nodeTime?: string[];
  nodeName?: string;
  gatherList: FormListType[];
}) {
  const [search] = useSearchParams();
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();
  const {
    index = 0,
    formType,
    nodeTime,
    nodeName,
    formName,
    gatherList,
  } = props;

  const ref = useRef<SwiperRef>(null);
  const [selected, setSelected] = useState({} as any);

  const { appLogicStore } = useMemo(
    () => (appUserIdentityType === "doctor" ? doctorStore : patientStore),
    [appUserIdentityType, doctorStore, patientStore]
  );

  const formId = useMemo(() => search.get("formId"), [search]);
  const type = useMemo(() => search.get("formOrigin"), [search]);
  const getPayload = useMemo(() => search.get("payload"), [search]);
  const payload = useMemo(() => {
    return getPayload ? JSON.parse(getPayload) : null;
  }, [getPayload]);

  const iType = useMemo(() => {
    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const isHealthManage = useMemo(() => {
    return (
      type === "HealthManageProject" ||
      type === "HealthManagePatient" ||
      type === "HealthManage"
    );
  }, [type]);

  const identityType = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const router = useMemo(() => {
    return identityType ? routerDoctor : routerPatient;
  }, [identityType, routerDoctor, routerPatient]);

  const path = useMemo(() => {
    return identityType ? doctorBasePath : patientBasePath;
  }, [identityType]);

  const toSearchPage = useCallback(() => {
    routerDoctor.navigateTo("searchSubmitPatient", {
      replace: true,
      state: {
        formId,
        type,
        payload: JSON.stringify(payload),
        gatherList: JSON.stringify(gatherList),
      },
    });
  }, [formId, gatherList, payload, routerDoctor, type]);

  const toFormBrowse = useCallback(
    (data: FormListType) => {
      // const base =
      //   process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxbd/" : "/";

      const base = process.env.REACT_APP_PUBLIC_URL === "/nfmssxsf/" ? "/nfmssxbd/" : "/";

      router.navigateTo(
        {
          // pathname: `/nfmssxbd/visit${path}followUpMiniProgramFormGatner`,
          pathname: `${base}visit${path}followUpMiniProgramFormGatner`,
          search: `?formId=${data?.formId}&formAttrs=${JSON.stringify({
            isDisabled: true,
            hideOperationButtons: iType,
          })}&formOrigin=${type}&payload=${JSON.stringify({
            ...payload,
            id: data?.id,
            formName: data?.formName || payload?.formName,
            formType: data?.formType,
          })}`,
        },
        { replace: true }
      );
    },
    [iType, path, payload, router, type]
  );

  const followUpProjectIndex = useRef(index); // 默认为0

  const lastCopy = useCallback(() => {
    if (followUpProjectIndex.current !== 0) {
      followUpProjectIndex.current -= 1;
      const data = gatherList[followUpProjectIndex.current];
      toFormBrowse(data);
      ref.current?.swipePrev();
      appLogicStore.setTitle(data?.formName || "");
    }
  }, [appLogicStore, gatherList, toFormBrowse]);

  const nextCopy = useCallback(() => {
    if (followUpProjectIndex.current !== gatherList?.length - 1) {
      followUpProjectIndex.current += 1;
      const data = gatherList[followUpProjectIndex.current];
      toFormBrowse(data);

      ref.current?.swipeNext();
      appLogicStore.setTitle(data?.formName || "");
    }
  }, [appLogicStore, gatherList, toFormBrowse]);

  const handleSelect = useCallback(
    (val: any) => {
      setSelected(val);
      const index =
        gatherList?.findIndex((item) => {
          return item.id === val.id;
        }) || 0;
      const data = gatherList[index];
      toFormBrowse(data);
      followUpProjectIndex.current = index;
      ref.current?.swipeTo(index);
    },
    [gatherList, toFormBrowse]
  );

  useEffect(() => {
    if (iType && !!gatherList?.length) {
      const val =
        gatherList?.find((item: FormListType) => {
          return item.id === payload?.id;
        }) || 0;
      handleSelect(val);
    }
  }, [gatherList, handleSelect, iType, payload?.id]);

  return (
    <div
      className="px-3 py-2"
      css={css`
        background-color: rgba(247, 248, 250, 0.6);
      `}
    >
      <Typography block type="title-bold" className="mb-2">
        {payload?.formName || formName}
      </Typography>
      {/* 健康管理没有这一块 */}
      {!isHealthManage && (
        <div className="flex items-center mb-3">
          {identityType && formType !== 1 && (
            <img className="w-4 h-4 mr-2 mt-0.5" src={patientEdit} alt="" />
          )}
          <div className="mr-4">
            <Typography type="mini">节点：</Typography>
            <Typography type="mini" colorType="grayMainColor">
              {nodeName}
            </Typography>
          </div>
          {nodeTime && nodeTime.length > 0 && (
            <div>
              <Typography type="mini">时间：</Typography>
              <Typography type="mini" colorType="grayMainColor">
                <>
                  {nodeTime && nodeTime.length > 0 ? (
                    <>
                      {nodeTime[0]}至{nodeTime[1]}
                    </>
                  ) : (
                    "日期待获取..."
                  )}
                </>
              </Typography>
            </div>
          )}
        </div>
      )}
      {!isHealthManage && gatherList?.length ? (
        <>
          {/* 这一块只有医生端-项目信息有 */}
          {type === "FollowUpProject" && (
            <div className="flex items-center mb-3">
              <SearchBar
                className="w-11/12"
                placeholder="搜索已提交患者"
                onFocus={toSearchPage}
              />
              <DropdownSelect<FormListType>
                selectValue={selected}
                keyId="submitPatient1"
                type="icon"
                valueStr={"id"}
                showArrow={false}
                list={gatherList || []}
                checkedItem={handleSelect}
                titleRender={
                  selected?.id ? (
                    <img className="w-6 h-6 ml-2" src={usergroupCheck} alt="" />
                  ) : (
                    <img className="w-6 h-6 ml-2" src={usergroup} alt="" />
                  )
                }
                otherRender={
                  <div className="flex justify-between items-center pt-4 pb-2 pl-3 pr-5">
                    <DropdownTitle type="title" title={"已提交患者"} />
                    <DropdownTitle
                      type="title"
                      title={`${gatherList?.length || 0}位`}
                    />
                  </div>
                }
              >
                {(val: any) => <SearchItem itemValue={val} />}
              </DropdownSelect>
            </div>
          )}
          {/* 这一块医生端-项目信息有、医生端-患者列表有 */}
          {identityType && formType !== 1 && (
            <div className="flex justify-between items-center">
              <Button
                disabled={followUpProjectIndex.current === 0}
                size="mini"
                onClick={lastCopy}
                className="w-1/4"
              >
                <Typography type="mini" colorType="graySecondaryColor">
                  上一份
                </Typography>
              </Button>
              <Swiper
                allowTouchMove={false}
                ref={ref}
                css={css`
                  .adm-swiper-indicator {
                    display: none;
                  }
                  .adm-swiper-track-inner {
                    margin-left: 10%;
                  }
                `}
              >
                {gatherList?.map((item: FormListType, index: number) => {
                  return (
                    <Swiper.Item key={index}>
                      <SearchItem itemValue={item} />
                    </Swiper.Item>
                  );
                })}
              </Swiper>
              <Button
                disabled={
                  followUpProjectIndex.current === gatherList?.length - 1
                }
                size="mini"
                onClick={nextCopy}
                className="w-1/4"
              >
                <Typography type="mini" colorType="graySecondaryColor">
                  下一份
                </Typography>
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
});
