import { observer } from "mobx-react-lite";
import AppTabBar from "./AppTabBar";

export default observer(function AppFooter() {
  return (
    <footer>
      <AppTabBar />
    </footer>
  );
});
