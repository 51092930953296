/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Popup } from "antd-mobile";
import { CheckOutline } from "antd-mobile-icons";
import { useTheme } from "../../../theme";
import { Typography, Button } from "../../../components";
import { FollowupPlanInfoType } from "../../../models/entity/followUpProject";

export interface SelectNodePopupType {
  visible: boolean;
  selectValue: null | FollowupPlanInfoType;
  list?: FollowupPlanInfoType[];
  handleCancel: () => void;
  handleSubmit: (val: FollowupPlanInfoType) => void;
}

export default React.memo(function SelectNodePopup(props: SelectNodePopupType) {
  const { visible, selectValue, list, handleCancel, handleSubmit } = props;
  const theme = useTheme();

  const [value, setValue] = useState(selectValue);

  const allList = useMemo(() => {
    return list ? [{ status: -1, statusName: "全部" }, ...list] : [];
  }, [list]);

  const newList = useMemo(() => {
    const map = new Map();
    return allList?.filter((v) => !map.has(v.status) && map.set(v.status, v));
  }, [allList]);

  const handleClick = useCallback((item: any) => {
    setValue(item);
  }, []);

  const onCancel = useCallback(() => {
    handleCancel();
  }, [handleCancel]);

  const onSubmit = useCallback(() => {
    value && handleSubmit(value);
  }, [handleSubmit, value]);

  return (
    <Popup visible={visible}>
      <div className="flex justify-between items-center p-2">
        <Button fill="none" onClick={onCancel}>
          <Typography
            type="title"
            colorType="graySecondaryColor"
            css={css`
              font-weight: 400;
            `}
          >
            取消
          </Typography>
        </Button>
        <Typography type="title" colorType="grayMainColor">
          选择节点
        </Typography>
        <Button fill="none" onClick={onSubmit}>
          <Typography
            type="title"
            colorType="primaryColor"
            css={css`
              font-weight: 400;
            `}
          >
            确定
          </Typography>
        </Button>
      </div>
      <div className="pb-8">
        {newList?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="flex justify-between items-center p-4"
              css={css`
                border-top: 1px solid ${theme.borderColor};
              `}
              onClick={() => handleClick(item)}
            >
              <Typography
                type="title"
                colorType="grayMainColor"
                css={css`
                  font-weight: 400;
                `}
              >
                {item.statusName}节点
                {item.status === -1
                  ? list?.length
                  : list?.filter(
                      (k: FollowupPlanInfoType) => k.status === item.status
                    )?.length}
              </Typography>
              {value?.status === item.status && (
                <CheckOutline fontSize={20} color={`${theme.primaryColor}`} />
              )}
            </div>
          );
        })}
      </div>
    </Popup>
  );
});
