/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { useTheme } from "../../../theme";
import { useLocation } from "react-router-dom";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
import { Typography } from "../../../components";
import { MesHistoryType } from "../../../models/entity/instantMessage";
import ImageViewers from "../../../coreUIComponents/ImageViewers";
import pdfImg from "../../../assets/image/pdf.png";
import { serviceClient } from "../../../service";
import { Toast } from "antd-mobile";
import { FolderOutline } from "antd-mobile-icons";
import { PdfViewers, VideoViewers } from "../../FilePreview";

interface ItemType {
  className?: string;
  data: MesHistoryType;
}

export const getFileType = (fileName: string) => {
  const extension = fileName.split(".")?.pop?.()?.toLowerCase();

  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
      return "img";
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
      return "doc";
    case "mp4":
    case "mkv":
    case "avi":
    case "mov":
    case "wmv":
      return "video";
    case "mp3":
    case "wav":
    case "ogg":
      return "audio";
    case "txt":
      return "text";
    default:
      return "Unknown File Type";
  }
};

export default observer(function Item(props: ItemType) {
  const theme = useTheme();
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const { className, data } = props;

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, userSendMesStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const { userProfile, userInfo } = useMemo(
    () => userLogicStore,
    [userLogicStore]
  );

  const patientInfo = useMemo(
    () => userSendMesStore.patientInfo,
    [userSendMesStore.patientInfo]
  );

  const IS_TXT = useMemo(() => data.mesType === 0, [data.mesType]);
  const IS_IMG = useMemo(
    () => getFileType(data.mesContent) === "img",
    [data.mesContent]
  );
  const IS_PDF = useMemo(
    () => getFileType(data.mesContent) === "pdf",
    [data.mesContent]
  );
  const IS_VIDEO = useMemo(
    () => getFileType(data.mesContent) === "video",
    [data.mesContent]
  );

  const savePatientFolder = useCallback(async () => {
    const { patientId, projectId } = patientInfo;
    const srcUrl = `${process.env.REACT_APP_PUBLIC_URL || "/"}api/scf/profile/${
      data.mesContent
    }`;
    if (patientId && projectId) {
      // const format = srcUrl?.substring(srcUrl?.lastIndexOf(".") + 1);
      const spliceLength = srcUrl.lastIndexOf("/");
      const imageName = srcUrl.slice(spliceLength + 1);
      fetch(srcUrl)
        .then((res) => {
          return res.blob();
        })
        .then(async (blob) => {
          const imgFile = new File(
            [blob],
            imageName
            //   {
            //   type: `image/${format}`,
            // }
          );
          const formData = new FormData();
          formData.append("filelist", imgFile);
          formData.append("patientId", patientId);
          formData.append("projectId", projectId);
          formData.append("operator", userInfo?.name || "");
          const res = await serviceClient.patient.fileUpload(formData);
          if (res?.code === 200) {
            Toast.show("保存成功");
          }
        });
    }
  }, [data.mesContent, patientInfo, userInfo?.name]);

  return (
    <div className={className}>
      <Typography type="mini">
        {data.empiOrUserid === userProfile?.empiOrUserid
          ? userInfo?.name
          : state.name}
      </Typography>
      {IS_TXT && (
        <div
          className="mt-2 p-2 rounded-md"
          css={css`
            background: ${data.empiOrUserid === userProfile?.empiOrUserid
              ? theme.primaryColor
              : theme.whiteColor};
          `}
        >
          <Typography
            colorType={`${
              data.empiOrUserid === userProfile?.empiOrUserid
                ? "whiteColor"
                : "grayMainColor"
            }`}
          >
            {data?.mesContent}
          </Typography>
        </div>
      )}
      {IS_IMG && (
        <div className="mt-2 py-2 rounded-md">
          <div onClick={savePatientFolder}>
            <FolderOutline />
            <Typography
              colorType="grayMainColor"
              className="ml-1 leading-loose"
            >
              添加到患者资料夹
            </Typography>
          </div>
          <ImageViewers
            className="w-40 h-40"
            srcUrl={`${
              process.env.REACT_APP_PUBLIC_URL || "/"
            }api/scf/profile/${data.mesContent}`}
            showFooter={isDoctor}
            patientId={patientInfo?.patientId}
            projectId={patientInfo?.projectId}
          />
        </div>
      )}

      {IS_VIDEO && (
        <div className="mt-2 py-2 rounded-md">
          <div onClick={savePatientFolder}>
            <FolderOutline />
            <Typography
              colorType="grayMainColor"
              className="ml-1 leading-loose"
            >
              添加到患者资料夹
            </Typography>
          </div>
          <VideoViewers
            srcUrl={`${
              process.env.REACT_APP_PUBLIC_URL || "/"
            }api/scf/profile/${data.mesContent}`}
          />
        </div>
      )}
      {IS_PDF && (
        <div className="mt-2 py-2 rounded-md">
          <div onClick={savePatientFolder}>
            <FolderOutline />
            <Typography
              colorType="grayMainColor"
              className="ml-1 leading-loose"
            >
              添加到患者资料夹
            </Typography>
          </div>
          <div
            css={css`
              background: #fff;
              padding: 10px;
            `}
            className="flex justify-between"
          >
            <PdfViewers
              srcUrl={`${
                process.env.REACT_APP_PUBLIC_URL || "/"
              }api/scf/profile/${data.mesContent}`}
            >
              {data.mesContent}
            </PdfViewers>
            <img src={pdfImg} alt="pdf" style={{ width: "35px" }} />
          </div>
        </div>
      )}
    </div>
  );
});
