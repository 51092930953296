import FollowUpProject from "../../pagesDoctor/FollowUpProject";
import InstantMessage from "../../pagesDoctor/InstantMessage";
import MessageDetail from "../../coreUIComponents/SendMessage";
import Patient from "../../pagesDoctor/Patient";
import PatientSearch from "../../pagesDoctor/Patient/SearchResult";
import PatientDetail from "../../pagesDoctor/Patient/PatientDetail";
import HospitalData from "../../pagesDoctor/Patient/HospitalData";
import ReportDetail from "../../coreUIComponents/HospitalData/ReportDetail";
import PatientFolder from "../../pagesDoctor/Patient/PatientFolder";
import SearchSubmitPatient from "../../coreUIComponents/FollowUp/FormBrowse/SearchSubmitPatient";
import PreachAndTeach from "../../coreUIComponents/FollowUp/PreachAndTeach";
import User from "../../pagesDoctor/User";
import FormGatherWrapper from "../../coreUIComponents/FormEditor/FormGatherWrapper";
import { RouteItem } from "../common";
import NotFound from "../../coreUIComponents/NotFound";
import { Navigate } from "react-router-dom";
import FollowUpProjectQRCode from "../../pagesDoctor/User/FollowUpProjectQRCode";
import UserUpdate from "../../pagesDoctor/User/UserUpdate";
import UserUpdatePhone from "../../pagesDoctor/User/UserUpdatePhone";

export const basePath = "/ui/doctor-entry/";

// const base = process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxbd/" : "/";
const base = process.env.REACT_APP_PUBLIC_URL === "/nfmssxsf/" ? "/nfmssxbd/" : "/";

export type TDoctorRouterKey =
  | "followUpProject"
  | "patient"
  | "patientSearch"
  | "patientDetail"
  | "hospitalData"
  | "reportDetail"
  | "patientFolder"
  | "searchSubmitPatient"
  | "preachAndTeach"
  | "instantMessage"
  | "messageDetail"
  | "user"
  | "followUpQrCode"
  | "formGatner"
  | "userUpdate"
  | "userUpdatePhone"
  | "submitSuccessPage";

export const doctorRouters = [
  {
    path: "",
    element: <Navigate to="followUpProject" />,
  },
  {
    id: "followUpProject",
    path: "followUpProject",
    element: <FollowUpProject />,
    isShowFooterTabBar: true,
    title: "项目信息",
  },
  {
    id: "patient",
    path: "patient",
    element: <Patient />,
    isShowFooterTabBar: true,
    title: "患者列表",
  },
  {
    id: "patientSearch",
    path: "patient/search",
    element: <PatientSearch />,
    title: "搜索",
  },
  {
    id: "patientDetail",
    path: "patient/detail",
    element: <PatientDetail />,
    title: "患者详情",
  },
  {
    id: "hospitalData",
    path: "hospitalData",
    element: <HospitalData />,
    title: "院内数据",
  },
  {
    id: "reportDetail",
    path: "hospitalData/reportDetail",
    element: <ReportDetail />,
    title: "报告详情",
  },
  {
    id: "patientFolder",
    path: "patientFolder",
    element: <PatientFolder />,
    title: "患者资料夹",
  },
  {
    id: "searchSubmitPatient",
    path: "searchSubmitPatient",
    element: <SearchSubmitPatient />,
    title: "搜索",
  },
  {
    id: "preachAndTeach",
    path: "preachAndTeach",
    element: <PreachAndTeach />,
  },
  {
    id: "instantMessage",
    path: "instantMessage",
    element: <InstantMessage />,
    isShowFooterTabBar: true,
    title: "消息",
  },
  {
    id: "messageDetail",
    path: "messageDetail",
    element: <MessageDetail />,
    title: "消息详情",
  },
  {
    id: "user",
    path: "user",
    element: <User />,
    isShowFooterTabBar: true,
    title: " ",
  },
  {
    id: "userUpdate",
    path: "userUpdate",
    element: <UserUpdate />,
    title: "修改科室工号",
  },
  {
    id: "userUpdatePhone",
    path: "userUpdatePhone",
    element: <UserUpdatePhone />,
    title: "修改手机号",
  },
  {
    id: "followUpQrCode",
    path: "followUpProject/QrCode",
    element: <FollowUpProjectQRCode />,
  },
]
  .map((item) => {
    item.path = `${basePath}${item.path}`;
    return item;
  })
  .concat([
    {
      id: "formGatner",
      path: `${base}visit/ui/doctor-entry/followUpMiniProgramFormGatner`,
      element: <FormGatherWrapper />,
    },
    {
      id: "404",
      path: "*",
      element: <NotFound />,
    },
  ]) as RouteItem<TDoctorRouterKey>[];
