/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { SearchBar, Typography } from "../../components";
import useDoctorStore from "../../store/doctor";
import { Divider } from "antd-mobile";
import { useTheme } from "../../theme";
import { useLocation } from "react-router-dom";
import PatientFileList from "./PatientFolder/PatientFileList";
import PatientList from "./PatientList";

/**
 * type:
 *   患者列表页面的搜索跳转过来 -- list
 *   患者资料夹页的搜索跳转过来 -- file
 */
// 防抖
export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export default observer(function SearchResult() {
  const theme = useTheme();
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const { patientEntityStore } = doctorStore;

  const searchVal = useMemo(
    () => patientEntityStore.searchVal,
    [patientEntityStore.searchVal]
  );

  const historyList = useMemo(
    () => patientEntityStore.historyList,
    [patientEntityStore.historyList]
  );

  const list = useMemo(
    () =>
      state.type === "list"
        ? patientEntityStore.list
        : patientEntityStore.fileList,
    [patientEntityStore.fileList, patientEntityStore.list, state.type]
  );

  useEffect(() => {
    return () => {
      patientEntityStore.setSearchVal("");
    };
  }, [patientEntityStore]);

  return (
    <>
      <div className="page-padding">
        <SearchBar
          value={searchVal}
          placeholder={
            state.type === "list" ? "请输入患者姓名、患者号" : "请输入文件名称"
          }
          onChange={(val: string) => {
            // val.replace(/\s+/g, "")
            var _trim = new RegExp(/\s+/g);
            if (_trim.test(val)) {
              return;
            }
            patientEntityStore.setSearchVal(val);
          }}
        />

        {state.type === "list" && historyList?.length > 0 && (
          <div className="pt-2">
            <Typography type="mini">最近查看患者</Typography>

            <div className="flex items-center flex-wrap pt-2">
              {historyList?.map((item) => {
                return (
                  <div
                    className="rounded-md mr-2 px-3 py-1 mb-2"
                    css={css`
                      background: ${theme.borderColor};
                    `}
                    key={item.id}
                    onClick={() => {
                      patientEntityStore.setSearchVal(item.key);
                    }}
                  >
                    <Typography type="mini" colorType="grayMainColor">
                      {item.key}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {list.length && searchVal !== "" ? (
        <Divider className="mt-0 mx-0 mb-0"></Divider>
      ) : null}

      <div className="page-padding">
        {searchVal !== "" ? (
          state.type === "list" ? (
            <PatientList type="search" />
          ) : (
            <PatientFileList type="search" activeKey={state?.activeKey} />
          )
        ) : null}
      </div>
    </>
  );
});
