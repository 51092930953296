/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { Badge } from "antd-mobile";
import { useCallback, useEffect, useMemo } from "react";
import { useTheme } from "../../theme";
import { Typography, Button, ArrowIcon } from "../../components";
import calendarImg from "../../assets/icons/doctorPhoto.svg";
import { usePatientRouter } from "../../router/Patient";
import { useAppUserIdentityType } from "../../hooks";
import usePatientStore from "../../store/patient";
import Header from "../Home/Header";
import { PatientMessageListType } from "../../models/entity/instantMessage";
import Empty from "../../coreUIComponents/Empty";
import { getConfirmation } from "../../utils/http";

export default observer(function InstantMessage() {
  const theme = useTheme();
  const router = usePatientRouter();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();
  const { userLogicStore, userSendMesStore } = patientStore;
  const { patientMessageList, allMesList } = userSendMesStore;

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  useEffect(() => {
    // "1000367569"
    userLogicStore.saasToken &&
      userSendMesStore.fetchPatientMessageList({
        empiOrUserid: userProfile?.empiOrUserid || "",
        type: isDoctor ? 2 : 1,
      });
  }, [isDoctor, userLogicStore.saasToken, userProfile?.empiOrUserid, userSendMesStore]);

  useEffect(() => {
    if (window._settings.isWXLogin) {
      userLogicStore.saasToken && userSendMesStore.fetchGetMesSessionList();
    }
  }, [userLogicStore.saasToken, userSendMesStore]);

  const sendMessage = useCallback(
    (val: PatientMessageListType) => {
      getConfirmation();
      // 更改状态
      userSendMesStore.fetchPatientMessageStatus([val.id]);
      router.navigateTo("messageDetail", {
        state: {
          doctorId: val.doctorId,
          projectId: val.projectId,
          name: val.doctorName,
        },
      });
    },
    [router, userSendMesStore]
  );

  const mesNum = useCallback(
    (doctorId: string | number, projectId: string | number) => {
      return (
        allMesList.find(
          (k) => k.empiOrUserId === doctorId && k.projectId === projectId
        )?.unread || 0
      );
    },
    [allMesList]
  );

  return (
    <>
      <div className="page-padding">
        <Header />
      </div>
      {patientMessageList?.length > 0 ? (
        <>
          {patientMessageList.map((item) => {
            return (
              <div
                key={item.id}
                className="pb-3 mb-4"
                css={css`
                  border-bottom: 1px solid ${theme.borderColor};
                `}
              >
                <div
                  className="flex items-center py-2 px-3"
                  css={css`
                    background: #f5f7fa;
                  `}
                >
                  {/* 0-新加入 1-非新加入 */}
                  {item.status === 0 && (
                    <Badge content="New" className="mr-1" />
                  )}
                  <Typography block className="font-medium">
                    #{item.projectName}
                  </Typography>
                </div>
                <div
                  className="flex py-3 mx-3 mb-3"
                  css={css`
                    border-bottom: 1px solid ${theme.borderColor};
                  `}
                >
                  <img src={calendarImg} className="w-9 h-9" alt="" />
                  <div className="flex flex-col ml-2">
                    <div className="flex justify-between items-center">
                      <Typography
                        className="font-medium mb-1"
                        css={css`
                          line-height: 16px;
                        `}
                      >
                        {item.doctorName}
                      </Typography>
                      <Typography type="mini">{item.createTime}</Typography>
                    </div>
                    <Typography colorType="grayLightColor">
                      {item.content}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center px-3">
                  <div className="flex items-center">
                    {mesNum(item.doctorId, item.projectId) > 0 && (
                      <>
                        <Badge
                          content={mesNum(item.doctorId, item.projectId)}
                          className="mr-1"
                        />
                        <Typography colorType="grayLightColor">
                          收到
                          {mesNum(item.doctorId, item.projectId)}
                          条新消息
                        </Typography>
                      </>
                    )}
                  </div>
                  <Button
                    size="small"
                    color="primary"
                    block
                    className="w-24"
                    onClick={() => sendMessage(item)}
                  >
                    <Typography colorType="whiteColor">发消息</Typography>
                    <ArrowIcon
                      direction="right"
                      css={css`
                        color: ${theme.whiteColor};
                      `}
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </>
  );
});
