import { hospitalHttpInstance } from "../utils/http";
import {
  GetHomeFormListParamsType,
  GetHomeFormListPromiseType,
} from "../models/entity/followUpForm";

export class FollowUpFormServiceClient {
  async getHomeFormList(
    params: GetHomeFormListParamsType
  ): Promise<GetHomeFormListPromiseType[]> {
    return hospitalHttpInstance.get("/mini/project/homeForm", { params });
  }
}
