import {
  HomeParamsType,
  HomePromiseType,
  HomeRemindParamsType,
} from "../models/entity/home";
import { hospitalHttpInstance } from "../utils/http";

export class HomeServiceClient {
  async getHome(params: HomeParamsType): Promise<HomePromiseType> {
    return hospitalHttpInstance.get("/mini/project/home", { params });
  }

  async homeRemind(params: HomeRemindParamsType) {
    return hospitalHttpInstance.post("/mini/project/homeRemind", params);
  }
}
