import { useRoutes } from "react-router-dom";
import { useRouter } from "..";
import { doctorRouters, TDoctorRouterKey } from "./config";

export function DoctorRouter() {
  const element = useRoutes(doctorRouters);
  return element;
}

export const useDoctorRouter = function () {
  return useRouter<TDoctorRouterKey>();
};
