/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Popup, SafeArea } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { Fragment, useCallback, useMemo, useState } from "react";
import { ArrowIcon, Button, CheckList, Typography } from "../../../components";
import { CheckListValueType } from "../../../components/CheckList";

export default observer(function SelectUserRelation({
  value,
  onChange,
}: {
  value?: number;
  onChange?: (value?: number) => void;
}) {
  const [userRelationPopupVisible, setUserRelationPopupVisible] =
    useState(false);
  const isSelf = useMemo(() => value === 0, [value]);
  const options = useMemo(
    () => [
      {
        label: (
          <span
            css={css`
              color: #0052d9;
            `}
          >
            本人
          </span>
        ),
        value: 0,
      },
      {
        label: (
          <Fragment>
            <div
              css={css`
                color: #0052d9;
              `}
            >
              非本人
            </div>
            <Typography type="secondary">
              若您非随访人本人，请选择“非本人”
            </Typography>
          </Fragment>
        ),
        value: 1,
      },
    ],
    []
  );

  const onClosePopup = useCallback(() => {
    setUserRelationPopupVisible(false);
  }, []);

  const _onChange = useCallback(
    (val: number) => {
      onChange?.(val);
      onClosePopup();
    },
    [onChange, onClosePopup]
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: 12px 0;
        `}
        onClick={() => setUserRelationPopupVisible(true)}
      >
        {value !== undefined ? (
          <span
            css={css`
              color: #333333;
            `}
          >
            {isSelf ? "本人" : "非本人"}
          </span>
        ) : (
          <span
            css={css`
              color: #cccccc;
            `}
          >
            请选择
          </span>
        )}

        <ArrowIcon direction="down"></ArrowIcon>
      </div>

      <Popup visible={userRelationPopupVisible} onMaskClick={onClosePopup}>
        <div>
          <Typography type="secondary" className="my-3 text-center" block>
            选择与随访人关系
          </Typography>
          <CheckList
            options={options}
            onChange={_onChange as (value: CheckListValueType) => void}
            value={value}
            labelTextAlign="center"
          ></CheckList>
          <Button
            size="middle"
            css={css`
              span {
                position: relative;
                right: 8px;
              }
            `}
            block
            onClick={onClosePopup}
          >
            取消
          </Button>
        </div>
        <SafeArea position="bottom" />
      </Popup>
    </div>
  );
});
