/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { GetOpcProjectListPromiseType } from "../../../models/entity/followUpOutpatient";
import DropdownTitle from "../../../coreUIComponents/DropdownSelect/DropdownTitle";
import DropdownSelect from "../../../coreUIComponents/DropdownSelect";
import { useEffect, useMemo } from "react";
import usePatientStore from "../../../store/patient";
import { useAppUserIdentityType } from "../../../hooks";

export interface OpcProjectSelectType {
  keyId: string;
}

export default observer(function OpcProjectSelect(props: OpcProjectSelectType) {
  const { keyId } = props;
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, followUpOutpatientStore } = useMemo(
    () => patientStore,
    [patientStore]
  );

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const { selectedOpcProject, opcProjectList, setSelectedOpcProject } =
    followUpOutpatientStore;

  useEffect(() => {
    followUpOutpatientStore.getOpcProjectList({
      empiOrUserid: userProfile?.empiOrUserid || "",
      type: isDoctor ? 2 : 1,
    });
  }, [isDoctor, followUpOutpatientStore, userProfile?.empiOrUserid]);

  return (
    <>
      {opcProjectList.length > 1 ? (
        <div className="page-padding">
          <DropdownSelect<GetOpcProjectListPromiseType>
            selectValue={selectedOpcProject}
            keyId={keyId}
            labelStr={"projectName"}
            valueStr={"projectId"}
            list={opcProjectList}
            titleRender={
              <DropdownTitle
                type="title"
                title={selectedOpcProject?.projectName || "全部随访"}
                isSelected={!!selectedOpcProject?.projectName}
              />
            }
            checkedItem={(item: GetOpcProjectListPromiseType) => {
              setSelectedOpcProject(item);
            }}
          >
            {(val: GetOpcProjectListPromiseType) => (
              <DropdownTitle
                type="title"
                title={val.projectName}
                changeColor={false}
              />
            )}
          </DropdownSelect>
        </div>
      ) : null}
    </>
  );
});
