/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { css } from "@emotion/react";

export default observer(function NotFound() {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <h1>404</h1>
    </div>
  );
});
