import FollowUpProject from "../FollowUp/FollowUpProject";
import FollowUpSchedule from "../FollowUp/FollowUpSchedule";
import { observer } from "mobx-react-lite";
import { ArrowIcon, Typography, Card } from "../../components";
import Header from "./Header";
import { useCallback, useEffect, useMemo } from "react";
import { usePatientRouter } from "../../router/Patient";
import { Modal } from "antd-mobile";
import KeyValueTxt from "../../coreUIComponents/Patient/KeyValueTxt";
import { comTabList } from "../../coreUIComponents/HospitalData";
import usePatientStore from "../../store/patient";
import Empty from "../../coreUIComponents/Empty";

export default observer(function Home() {
  const router = usePatientRouter();
  const patientStore = usePatientStore();
  const { userLogicStore, homeEntityStore, appLogicStore, hospitalDataStore } =
    patientStore;
  const color = "graySecondaryColor";

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const homeInfo = useMemo(
    () => homeEntityStore.homeInfo,
    [homeEntityStore.homeInfo]
  );

  const toHospitalData = useCallback(
    (item: any) => {
      router.navigateTo(
        item.value as "medicalRecord" | "checkout" | "inspect",
        {
          state: {
            empid: userProfile?.empiOrUserid || "",
            patientName: userInfo?.name || "--",
          },
        }
      );
      appLogicStore.setTitle(item.label);
      hospitalDataStore.setActiveKey(item.value);
    },
    [
      appLogicStore,
      hospitalDataStore,
      router,
      userInfo?.name,
      userProfile?.empiOrUserid,
    ]
  );

  const getHome = useCallback(() => {
    homeEntityStore.getHome({
      empiOrUserid: userProfile?.empiOrUserid || "",
      type: userProfile?.type || 1,
      projectId: "-1",
    });
  }, [homeEntityStore, userProfile?.empiOrUserid, userProfile?.type]);

  const Remind = useCallback(
    async (type: number) => {
      if (homeInfo?.projectId) {
        await homeEntityStore.homeRemind({
          nodeId: homeInfo?.nodeId,
          patientId: homeInfo?.patientId,
          projectId: homeInfo?.projectId,
          type: type,
        });
        getHome();
      }
    },
    [
      getHome,
      homeEntityStore,
      homeInfo?.nodeId,
      homeInfo?.patientId,
      homeInfo?.projectId,
    ]
  );

  // 不再提醒
  const handleCancel = useCallback(async () => {
    Remind(2);
  }, [Remind]);

  const handleConfirm = useCallback(async () => {
    Remind(1);
  }, [Remind]);

  useEffect(() => {
    userLogicStore.saasToken && getHome();
  }, [getHome, userLogicStore.saasToken]);

  useEffect(() => {
    hospitalDataStore.setEmpty();
  }, [hospitalDataStore]);

  useEffect(() => {
    if (String(homeInfo?.remind) === "0") {
      Modal.confirm({
        title: "门诊随访提醒",
        content: (
          <div>
            <KeyValueTxt
              lColor={color}
              label="随访人："
              value={userInfo?.name}
            />
            <KeyValueTxt
              lColor={color}
              label="就诊时间："
              value={homeInfo?.formatFollowTime}
            />
            <KeyValueTxt
              lColor={color}
              label="门诊科室："
              value={homeInfo?.department}
            />
            <KeyValueTxt
              lColor={color}
              label="医院："
              value={userInfo?.hospitalName}
            />
            <KeyValueTxt
              lColor={color}
              label="随访项目："
              value={homeInfo?.projectName}
            />
          </div>
        ),
        onCancel: handleCancel,
        onConfirm: handleConfirm,
        cancelText: <Typography type="title">不再提醒</Typography>,
        confirmText: (
          <Typography type="title" colorType="whiteColor">
            我知道了
          </Typography>
        ),
      });
    }
  }, [handleCancel, handleConfirm, homeInfo?.department, homeInfo?.formatFollowTime, homeInfo?.projectName, homeInfo?.remind, userInfo?.hospitalName, userInfo?.name]);

  return (
    <div className="page-padding">
      <Header />

      <FollowUpSchedule />

      {userLogicStore.saasToken ? (
        <>
          <FollowUpProject />

          <div>
            <div className="flex justify-between items-center mt-5 mb-4">
              <Typography type="title" colorType="graySecondaryColor">
                院内数据
              </Typography>
            </div>
            <Card>
              {comTabList.map((item, index) => {
                return (
                  <div
                    key={item.value}
                    className="flex justify-between items-center py-3"
                    onClick={() => toHospitalData(item)}
                  >
                    <div className="flex items-center">
                      {item.img}
                      <div className="ml-2">
                        <Typography type="title" block className="mb-1">
                          {item.label}
                        </Typography>
                        <Typography type="secondary" colorType="grayLightColor">
                          {item.description}
                        </Typography>
                      </div>
                    </div>
                    <ArrowIcon direction="right"></ArrowIcon>
                  </div>
                );
              })}
            </Card>
          </div>
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </div>
  );
});
