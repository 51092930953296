/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Tabs } from "../../components";
import FollowupPlan from "./FollowupPlan";
import HealthManage from "./HealthManage";
import { FormOriginType } from "../FormEditor/FormGatherWrapper";
import {
  HealthInfoType,
  FollowupPlanInfoType,
} from "../../models/entity/followUpProject";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";

interface FollowupPlanProps {
  type: FormOriginType; // 项目信息 | 患者列表随访 | 随访；默认是随访
  activeKey: string;
  healthType: FormOriginType;
  nodes?: FollowupPlanInfoType[];
  healthInfo?: HealthInfoType;
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default observer(function FollowupIndex(props: FollowupPlanProps) {
  const {
    type,
    activeKey,
    healthType,
    nodes,
    healthInfo,
    patientId,
    projectId,
  } = props;

  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { followUpStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const tabList = useMemo(() => {
    return [
      {
        label: "随访计划",
        value: "1",
        content: (
          <FollowupPlan
            type={type}
            nodes={nodes}
            patientId={patientId}
            projectId={projectId}
          />
        ),
      },
      {
        label: "计划外随访",
        value: "2",
        content: (
          <HealthManage
            type={healthType}
            healthInfo={healthInfo}
            patientId={patientId}
            projectId={projectId}
          />
        ),
      },
    ];
  }, [type, nodes, patientId, projectId, healthType, healthInfo]);

  return (
    <Tabs
      tabPaneList={tabList}
      className="sticky flex-1"
      activeLineMode="auto"
      activeKey={activeKey}
      onChange={(key: string) => {
        followUpStore.setActiveKey(key);
      }}
    />
  );
});
