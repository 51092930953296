import { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import PatientFileList from "./PatientFileList";
import { Tabs } from "../../../components";
import PatientAvatar from "../../../coreUIComponents/Patient/PatientAvatar";
import calendarImg from "../../../assets/icons/doctorPhoto.svg";
import useDoctorStore from "../../../store/doctor";

export default observer(function PatientFolder() {
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const { patientEntityStore } = doctorStore;

  const [activeKey, setActiveKey] = useState<string>("-1");
  const [checkboxValue, setCheckboxValue] = useState<string[]>([]);

  const handleChange = useCallback((val: string[]) => {
    setCheckboxValue(val);
  }, []);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <PatientAvatar src={calendarImg} name={state?.patientName || "--"} />
        ),
        value: "user",
        disabled: true,
      },
      {
        label: "全部",
        value: "-1",
        content: (
          <PatientFileList
            checkValue={checkboxValue}
            activeKey={activeKey}
            onchange={handleChange}
          />
        ),
      },
      {
        label: "照片",
        value: "1",
        content: (
          <PatientFileList
            checkValue={checkboxValue}
            activeKey={activeKey}
            onchange={handleChange}
          />
        ),
      },
      {
        label: "视频",
        value: "2",
        content: (
          <PatientFileList
            checkValue={checkboxValue}
            activeKey={activeKey}
            onchange={handleChange}
          />
        ),
      },
      {
        label: "文件",
        value: "3",
        content: (
          <PatientFileList
            checkValue={checkboxValue}
            activeKey={activeKey}
            onchange={handleChange}
          />
        ),
      },
    ];
  }, [activeKey, checkboxValue, handleChange, state?.patientName]);

  return (
    <>
      <div className="page-padding">
        <Tabs
          tabPaneList={tabList}
          className="sticky"
          isBorder={false}
          activeLineMode="fixed"
          activeKey={activeKey}
          onChange={(key: string) => {
            setActiveKey(key);
            setCheckboxValue([]);
            patientEntityStore.setSearchVal("");
          }}
        />
      </div>
    </>
  );
});
