import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import { SelectListType } from "../../models/entity/patient";
import {
  VisitRecordType,
  CheckOutRecordType,
  CheckUpRecordType,
  CheckOutItemRecordType,
} from "../../models/entity/hospitalData";

class HospitalDataStore {
  visitRecord = [] as VisitRecordType[]; // 就诊记录
  checkOutRecord = [] as CheckOutRecordType[]; // 检验记录
  checkUpRecord = [] as CheckUpRecordType[]; // 检查记录

  activeKey = "medicalRecord" as string;

  checkOutRecordInfo = null as CheckOutItemRecordType | null;

  selectedTime = { label: "", value: "0" } as SelectListType;
  selectedNode = { label: "", value: "0" } as SelectListType;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveKey = (val: string) => {
    this.activeKey = val;
  };

  setEmpty = () => {
    this.selectedTime = { label: "", value: "0" } as SelectListType;
    this.selectedNode = { label: "", value: "0" } as SelectListType;
  };

  setSelectedTime = (val: SelectListType) => {
    this.selectedTime = val;
  };

  setSelectedNode = (val: SelectListType) => {
    this.selectedNode = val;
  };

  getVisitRecord = async (params: {
    empiseqid: string;
    type: string;
    visitType: string;
  }) => {
    this.visitRecord = await serviceClient.hospitalData.getVisitRecord(params);
  };

  getCheckOutRecord = async (params: { empiseqid: string; type: string }) => {
    this.checkOutRecord = await serviceClient.hospitalData.getCheckOutRecord(
      params
    );
  };

  getCheckUpRecord = async (params: { empiseqid: string; type: string }) => {
    this.checkUpRecord = await serviceClient.hospitalData.getCheckUpRecord(
      params
    );
  };

  getCheckOutItemRecord = async (reportId: string) => {
    this.checkOutRecordInfo =
      await serviceClient.hospitalData.getCheckOutItemRecord(reportId);
  };
}

export default HospitalDataStore;
