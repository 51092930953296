/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Badge, TabBar } from "antd-mobile";
import {
  AppOutline,
  FileOutline,
  MessageOutline,
  UserOutline,
} from "antd-mobile-icons";
import { useDoctorRouter } from "../../router/Doctor";
import { observer } from "mobx-react-lite";
import useDoctorStore from "../../store/doctor";
import { TDoctorRouterKey } from "../../router/Doctor/config";
import { useTheme } from "../../theme";
import { css } from "@emotion/react";
import { AllMesListType } from "../../models/entity/instantMessage";

export default observer(function AppTabBar() {
  const router = useDoctorRouter();
  const currentRouter = router.currentRouter();
  const theme = useTheme();
  const { userLogicStore, userSendMesStore, followUpStore } = useDoctorStore();
  const { saasToken } = userLogicStore;
  const { allMesList } = userSendMesStore;

  const interval = useRef(null);
  const [activeKey, setActiveKey] =
    useState<TDoctorRouterKey>("followUpProject");

  const setTabActive = (value: string) => {
    setActiveKey(value as TDoctorRouterKey);
    followUpStore.setActiveKey("1");
    router.navigateTo(value as TDoctorRouterKey);
  };

  // 计算未读消息
  const count = useMemo(() => {
    return allMesList.length
      ? allMesList.reduce(
          (previousValue: number, currentValue: AllMesListType) => {
            return previousValue + currentValue.unread;
          },
          0
        )
      : 0;
  }, [allMesList]);

  const tabs: {
    key: TDoctorRouterKey;
    title: ReactNode;
    icon: JSX.Element;
  }[] = [
    {
      key: "followUpProject" as TDoctorRouterKey,
      title: "项目信息",
      icon: <AppOutline />,
    },
    {
      key: "patient" as TDoctorRouterKey,
      title: "患者列表",
      icon: <FileOutline />,
    },
    {
      key: "instantMessage" as TDoctorRouterKey,
      title: "消息",
      icon: (
        <Badge
          content={count > 0 ? count : ""}
          css={css`
            --top: 4px;
          `}
        >
          <MessageOutline />
        </Badge>
      ),
    },
    {
      key: "user" as TDoctorRouterKey,
      title: "个人中心",
      icon: <UserOutline />,
    },
  ].filter((item) => {
    return !window._settings.isWXLogin ? item.key !== "instantMessage" : true;
  });

  useEffect(() => {
    if (currentRouter?.id !== activeKey) {
      setActiveKey(currentRouter?.id ?? "followUpProject");
    }
  }, [activeKey, currentRouter?.id]);

  useEffect(() => {
    if (saasToken) {
      if (interval.current) {
        clearInterval(interval.current);
      }

      // (interval.current as any) = setInterval(() => {
      //   userSendMesStore.fetchGetMesSessionList();
      // }, 2000);
      getMesSessionList();

      return () => {
        if (interval.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearInterval(interval.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saasToken, userSendMesStore]);

  const getMesSessionList = () => {
    if (window._settings.isWXLogin) {
      (interval.current as any) = setTimeout(async () => {
        await userSendMesStore.fetchGetMesSessionList();
        getMesSessionList();
      }, 2000);
    }
  };

  return (
    <TabBar
      activeKey={activeKey}
      onChange={setTabActive}
      css={css`
        border-top: ${theme.styles.border};
        padding-bottom: 8px;
      `}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
});
