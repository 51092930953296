/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Popup, SafeArea } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Button, CheckList, Typography } from "../../components";
import { CheckListItem } from "../../components/CheckList";
import usePatientStore from "../../store/patient";

export default observer(function HospitalSelect({
  onVisibleHandle,
  defaultValue,
  onSelect,
  onSelectItem,
}: {
  onVisibleHandle: (value: boolean) => void;
  defaultValue: string;
  onSelect?: (value: string) => void;
  onSelectItem?: (value: any) => void;
}) {
  const { hospitalEntityStore } = usePatientStore();
  let [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const onConfirm = useCallback(() => {
    onSelect?.(selectedValue ?? "");

    if (selectedValue) {
      const res = hospitalEntityStore.hospitalList?.find(
        (item) => item.id === selectedValue
      );
      onSelectItem?.(res);
    }

    onVisibleHandle(false);
  }, [
    hospitalEntityStore.hospitalList,
    onSelect,
    onSelectItem,
    onVisibleHandle,
    selectedValue,
  ]);

  useEffect(() => {
    hospitalEntityStore.getHospitalList();
  }, [hospitalEntityStore]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <Popup
      visible={true}
      onMaskClick={() => {
        onVisibleHandle(false);
      }}
      bodyStyle={{ height: "100vh" }}
    >
      <div className="px-4">
        <div
          className="py-3"
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Button
            fill="none"
            size="mini"
            onClick={() => {
              onVisibleHandle(false);
            }}
          >
            取消
          </Button>
          <Typography type="title">选择医院</Typography>
          <Button fill="solid" color="primary" size="mini" onClick={onConfirm}>
            确定
          </Button>
        </div>
        <CheckList value={selectedValue} onChange={setSelectedValue as any}>
          {hospitalEntityStore.hospitalList?.map((item) => (
            <CheckListItem key={item.id} value={item.id}>
              {item.hospital}
            </CheckListItem>
          ))}
        </CheckList>
        <SafeArea position="bottom" />
      </div>
    </Popup>
  );
});
