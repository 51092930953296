import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import HttpClient from "./base";
import { Toast } from "antd-mobile";

// 取消请求操作
const allPendingRequestsRecord: any[] = [];

const removeAllPendingRequestsRecord = () => {
  allPendingRequestsRecord &&
    allPendingRequestsRecord.forEach((func) => {
      func(null);
    });
  // 移除所有记录
  allPendingRequestsRecord.splice(0);
};

const getConfirmation = () => {
  removeAllPendingRequestsRecord();
};

const instanceInterceptorsFunction = (httpInstance: AxiosInstance) => {
  httpInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      console.log(res);

      return res;
    },
    (err) => {
      const code: number | string = err?.code;
      if (code === 401) {
        setTimeout(() => {
          Toast.show({
            content: `登录状态已过期,请重新登录`,
            duration: 3000,
            afterClose: () => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = process.env.REACT_APP_PUBLIC_URL || "/";
            },
          });
        }, 1);
        // 登录相关

        return Promise.reject(
          new Error("无效的会话，或者会话已过期，请重新登录。")
        );
      }

      return Promise.reject(err);
    }
  );

  httpInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      config.cancelToken = new axios.CancelToken((c) => {
        allPendingRequestsRecord.push(c);
      });

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const saasHttpClient = new HttpClient();

const url = process.env.REACT_APP_PUBLIC_URL || "/";

const saasHttpInstance: AxiosInstance = saasHttpClient.create({
  baseURL: `${url}api/scf`,
});
instanceInterceptorsFunction(saasHttpInstance);

const hospitalHttpClient = new HttpClient();
const hospitalHttpInstance: AxiosInstance = hospitalHttpClient.create({
  baseURL: `${url}api/${window?._settings?.hospitalCode}`,
});
instanceInterceptorsFunction(hospitalHttpInstance);

export { saasHttpInstance, hospitalHttpInstance, getConfirmation };
