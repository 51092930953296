/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Form, Input, Radio } from "antd-mobile";
import SelectUserRelation from "../../pagesPatient/FollowUpUser/components/SelectUserRelation";
import SelectGender from "../../pagesPatient/FollowUpUser/components/SelectGender";
import { useCallback, useState } from "react";
import { FollowUpUser } from "../../models/entity/followUpUser";
import { serviceClient } from "../../service";
import { encodeStr } from "../../utils/jsencrypt";
import HospitalSelect from "../../pagesPatient/User/HospitalSelect";
import { useNavigate } from "react-router-dom";

const PatientRegister = () => {
  const navigateTo = useNavigate();
  const [form] = Form.useForm();
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(false);

  const [hospitalDialogVisible, setHospitalDialogVisible] = useState(false);
  const hospitalId = Form.useWatch("hospitalId", form);
  const [hospitalName, setHospitalName] = useState("");

  const onValuesChange = useCallback(() => {
    const allValues = form.getFieldsValue();
    const emptyCount = Object.values(allValues).filter((value: any) => {
      return [undefined, null, ""].includes(value);
    });
    setIsAllFilled(!emptyCount.length);
  }, [form]);

  const onSelectItemHospital = (value: any) => {
    form.setFieldValue("hospitalId", value?.id ?? "");
    setHospitalName(value?.hospital ?? "");
    onValuesChange();
  };

  const checkPhoneNumber = (_: any, value: any) => {
    var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (reg.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`手机号格式错误`));
    }
  };

  const onFinish = useCallback(async () => {
    try {
      const { relation, phoneNumber, name, sex, idcard, hospitalId } =
        await form.validateFields();

      let info = JSON.parse(localStorage.getItem("wxInfo") || "{}");

      info.type = 1;
      const payload: Partial<FollowUpUser> = {
        ...info,
        relation,
        phoneNumber: encodeStr(`${phoneNumber}`, info.publicKey),
        idcard: encodeStr(idcard, info.publicKey),
        name,
        sex,
        hospitalId,
      };
      setLoading(true);
      const res = await serviceClient.followUpUser.roleBinding(payload);
      if (res) {
        localStorage.setItem("wxInfo", JSON.stringify({ ...info }));
        window.location.href = `serviceSubscription`;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [form]);

  const userAgreement = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    navigateTo("/agreementStatement");
  };

  const clickRadio = (event: any) => {
    event.stopPropagation();
    setIsCheckedAgreement((isCheckedAgreement) => !isCheckedAgreement);
  };

  return (
    <div
      css={css`
        height: 100%;
        padding: 0 20px;
      `}
    >
      <div>
        <h2>注册</h2>
        <span
          css={css`
            font-size: 12px;
            color: #999999;
          `}
        >
          为保证您顺利参与随访项目，请输入并确认以下信息
        </span>
      </div>
      <Form
        css={css`
          --prefix-width: 76px;
          --border-top: 0px;
          .adm-list {
            font-size: 16px;
            margin-top: 20px;
            .adm-list-item {
              padding-left: 0;
              .adm-list-item-content-prefix {
                color: #666666;
              }
            }
          }
        `}
        form={form}
        layout="horizontal"
        onValuesChange={onValuesChange}
        footer={
          <>
            <Button
              loading={loading}
              className="mt-6"
              block
              type="submit"
              color="primary"
              size="large"
              disabled={!(isAllFilled && isCheckedAgreement)}
              onClick={onFinish}
            >
              登录
            </Button>
            <div
              css={css`
                display: flex;
                align-items: center;
                padding-top: 15px;
                line-height: 22px;
              `}
            >
              <span onClick={clickRadio}>
                <Radio
                  css={css`
                    --font-size: 14px;
                    --icon-size: 18px;
                  `}
                  checked={isCheckedAgreement}
                >
                  <span>同意</span>
                </Radio>
              </span>
              <Button
                onClick={(e) => userAgreement(e)}
                color="primary"
                fill="none"
                size="mini"
              >
                随访用户服务协议及隐私声明
              </Button>
            </div>
          </>
        }
      >
        <Form.Item name="relation" label="与随访人关系">
          <SelectUserRelation />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="手机号"
          rules={[{ required: true }, { validator: checkPhoneNumber }]}
        >
          <Input type="number" placeholder="请输入随访人在医院预留的手机号" />
        </Form.Item>
        <Form.Item name="name" label="姓名">
          <Input placeholder="请输入随访人姓名" />
        </Form.Item>
        <Form.Item name="sex" label="性别">
          <SelectGender />
        </Form.Item>
        <Form.Item name="idcard" label="身份证">
          <Input placeholder="请输入随访人身份证号" />
        </Form.Item>
        <Form.Item
          label="医院"
          name="hospitalId"
          onClick={() => {
            setHospitalDialogVisible(true);
          }}
        >
          {hospitalName || (
            <span
              css={css`
                color: #ccccd6;
              `}
            >
              请选择医院
            </span>
          )}
        </Form.Item>
      </Form>
      {hospitalDialogVisible && (
        <HospitalSelect
          defaultValue={hospitalId ?? ""}
          onSelectItem={onSelectItemHospital}
          onVisibleHandle={setHospitalDialogVisible}
        />
      )}
    </div>
  );
};

export default PatientRegister;
