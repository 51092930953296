import { useMemo } from "react";
import {
  NavigateOptions,
  Path,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppUserIdentityType } from "../hooks";
import { RouteItem } from "./common";
import { doctorRouters, TDoctorRouterKey } from "./Doctor/config";
import { patientRouters, TPatientRouterKey } from "./Patient/config";

export const useRouter = function <
  TRouterKey extends TDoctorRouterKey | TPatientRouterKey
>() {
  const navigateTo = useNavigate();
  const location = useLocation();
  const appUserIdentityType = useAppUserIdentityType();

  const routers = useMemo(
    () =>
      (appUserIdentityType === "doctor"
        ? doctorRouters
        : patientRouters) as RouteItem<TRouterKey>[],
    [appUserIdentityType]
  );

  const _useRouter = useMemo(
    () => ({
      navigateTo(
        to: Partial<Path & { id: TRouterKey }> | TRouterKey,
        options?: NavigateOptions
      ) {
        if (typeof to === "string") {
          const id = to;
          const routerItem = routers.find((item) => item.id === id);
          navigateTo(routerItem?.path ?? "/", options);
        } else {
          const _pathname =
            to.pathname === undefined && to.id
              ? routers.find((item) => item.id === to.id)?.path
              : to.pathname;

          navigateTo(
            {
              ...to,
              pathname: _pathname,
            },
            options
          );
        }
      },
      currentRouter(): RouteItem<TRouterKey> | undefined {
        const _pathname = location.pathname;
        const routerItem = routers.find((item) => {
          return item.path === _pathname;
        });
        return routerItem as RouteItem<TRouterKey>;
      },
    }),
    [location.pathname, navigateTo, routers]
  );

  return _useRouter;
};
