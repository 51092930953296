import { AppUserIdentityTypeContextType } from "../hooks";
import { URL_TOKEN_KEY, SAAS_TOKEN_KEY } from "../store/core/user";

export const getAppUserIdentityType = () => {
  const path = process.env.REACT_APP_PUBLIC_URL || "/";

  const pathname = window.location.pathname;

  let _appIdentityType = undefined as AppUserIdentityTypeContextType;

  if (pathname.includes(`${path}ui/doctor-entry`)) {
    _appIdentityType = "doctor";
  } else if (pathname.includes(`${path}ui/patient-entry`)) {
    _appIdentityType = "patient";
  } else if (
    [
      "/",
      `${path}`,
      `${path}bindingRole`,
      `${path}serviceSubscription`,
      `${path}doctorLogin`,
      `${path}patientRegister`,
    ].includes(pathname)
  ) {
    if (window._settings.isWXLogin) {
      _appIdentityType = "bindingRole";
    } else {
      _appIdentityType = "ssoLogin";
    }
  }
  return _appIdentityType;
};

export const setSaasTokenByUrl = () => {
  const saasTokenByUrl = new URLSearchParams(window.location.search).get(
    URL_TOKEN_KEY
  );
  if (saasTokenByUrl) {
    localStorage.setItem(SAAS_TOKEN_KEY, saasTokenByUrl);
    return saasTokenByUrl;
  }
};
