/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import ListItemCard from "./ListItemCard";
import { Typography, Button } from "../../components";
import { VisitRecordType } from "../../models/entity/hospitalData";
import Empty from "../../coreUIComponents/Empty";

interface RecordListType {
  list: VisitRecordType[];
}

export default observer(function RecordList(props: RecordListType) {
  const { list } = props;

  return (
    <div>
      {list?.length > 0 ? (
        <>
          {list?.map((item: VisitRecordType, index: number) => {
            return (
              <ListItemCard key={index} className="pt-2 pb-3">
                <Typography type="title" block className="mb-3">
                  {item?.info?.patientname || "--"}
                </Typography>
                <div className="mb-2 flex items-center">
                  <Button
                    fill="outline"
                    color={
                      item?.visitTypeStr === "门诊" ? "primary" : "success"
                    }
                    className="py-0 px-1 mr-2"
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {item?.visitTypeStr}
                  </Button>
                  <Typography type="secondary">
                    {item?.info?.hospitalname || "--"}
                  </Typography>
                </div>
                <Typography type="secondary" block className="mb-1">
                  科室：
                  {item?.visitTypeStr === "门诊"
                    ? item?.info?.acceptdeptname || "--"
                    : item?.info?.currentdeptname || "--"}
                </Typography>
                {item.visitTypeStr === "门诊" ? (
                  <>
                    {/* <Typography type="secondary" block className="mb-1">
                    诊断：湿疹
                  </Typography> */}
                    <Typography type="secondary" block className="mb-1">
                      就诊医生：{item?.info?.acceptedphysicianname || "--"}
                    </Typography>
                    <Typography type="secondary" block className="mb-1">
                      就诊时间：{item?.info?.accepteddate || "--"}
                    </Typography>
                    {/* <Typography type="secondary" block className="mb-1">
                    处理：他克莫司软膏0.1%（10g）一日涂抹两次，门诊随访，不适随诊
                  </Typography> */}
                  </>
                ) : (
                  <>
                    <Typography type="secondary" block className="mb-1">
                      入院诊断：{item?.info?.admissiondxdescription || "--"}
                    </Typography>
                    <Typography type="secondary" block className="mb-1">
                      出院诊断：{item?.info?.dxdescription || "--"}
                    </Typography>
                    <Typography type="secondary" block className="mb-1">
                      主治医生：{item?.info?.attendingdoctorname || "--"}
                    </Typography>
                    <Typography type="secondary" block className="mb-1">
                      入院时间：{item?.info?.inhospitaldate || "--"}
                    </Typography>
                    <Typography type="secondary" block className="mb-1">
                      出院时间：{item?.info?.dischargedate || "--"}
                    </Typography>
                  </>
                )}
              </ListItemCard>
            );
          })}
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </div>
  );
});
