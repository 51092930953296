/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import {
  Collapse as AntdCollapse,
  CollapseProps as AntdCollapseProps,
} from "antd-mobile";
import moment from "moment";
import { css } from "@emotion/react";
import { useTheme } from "../../../theme";
import CollapseTitle from "./CollapseTitle";
import FormEducateList from "../FormEducateList";
import { FormOriginType } from "../../FormEditor/FormGatherWrapper";
import { Typography, Tag, ArrowIcon } from "../../../components";
import { FollowupPlanInfoType } from "../../../models/entity/followUpProject";
import Empty from "../../../coreUIComponents/Empty";

export interface CollapseMainProps {
  type: FormOriginType;
  list?: FollowupPlanInfoType[];
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default React.memo(function CollapseMain(
  props: AntdCollapseProps & CollapseMainProps
) {
  const { type, list, patientId, projectId, ...rest } = props;
  const theme = useTheme();

  const iType = useMemo(() => {
    return type === "FollowUpProject";
  }, [type]);

  const _style = useMemo(() => {
    return [
      css`
        .adm-list-body {
          border-radius: 4px;
          border: 1px solid ${theme.borderColor};
          box-shadow: ${iType ? "none" : `${theme.styles.boxShadow}`};
        }
        .adm-list-body-inner {
          position: relative;
        }
        .adm-collapse-panel-header {
          background: ${iType ? `${theme.borderColor}` : `${theme.whiteColor}`};
        }
        .adm-list-item-content {
          align-items: flex-start;
        }
        .adm-collapse-panel-content-inner {
          .adm-list-item {
            padding-left: 0;
          }
          .adm-list-item-content {
            padding-right: 0;
          }
          .adm-list-item-content-main {
            padding: 0;
          }
        }
        a.adm-list-item:active:not(.adm-list-item-disabled) {
          background-color: ${theme.borderColor};
        }
      `,
      iType &&
        css`
          .adm-list-item-content-arrow {
            padding: 12px 0;
          }
        `,
      !iType &&
        css`
          .adm-list-item-content-arrow {
            padding: 38px 0 12px;
          }
        `,
    ];
  }, [iType, theme.borderColor, theme.styles.boxShadow, theme.whiteColor]);

  // 获取日期区间计算
  const calculateTime = useCallback(
    (
      nodeTime: string | undefined,
      floatingNum: number,
      floatingDownNum: number,
      format: string | undefined
    ) => {
      if (nodeTime) {
        const beforeMonth =
          moment(nodeTime).subtract(floatingNum, "days").month() + 1;
        const beforeDays = moment(nodeTime)
          .subtract(floatingNum, "days")
          .date();
        const _before = !format
          ? `${beforeMonth}月${beforeDays}日`
          : `${beforeMonth}-${beforeDays}`;
        const afterMonth =
          moment(nodeTime).add(floatingDownNum, "days").month() + 1;
        const afterDays = moment(nodeTime).add(floatingDownNum, "days").date();
        const _after = !format
          ? `${afterMonth}月${afterDays}日`
          : `${afterMonth}-${afterDays}`;
        return [_before, _after];
      } else {
        return [];
      }
    },
    []
  );

  return (
    <>
      {list && list?.length > 0 ? (
        <>
          {list?.map((item: FollowupPlanInfoType, index: number) => {
            return (
              <AntdCollapse
                key={index}
                css={_style}
                {...rest}
                arrow={
                  <ArrowIcon
                    direction="down"
                    css={css`
                      font-size: 14px;
                      color: ${theme.grayMainColor};
                    `}
                  />
                }
                className="mb-3"
              >
                <AntdCollapse.Panel
                  key={String(index)}
                  title={
                    <CollapseTitle
                      type={type}
                      currentItem={item}
                      time={calculateTime(
                        item?.nodeTime,
                        item?.floatingNum,
                        item?.floatingDownNum,
                        undefined
                      )}
                    />
                  }
                >
                  {/* 这一块 UI 上有的包括：患者端-随访-健康管理、医生端-患者列表-患者详情-健康管理 */}
                  {!iType && item?.followUp && (
                    <div
                      className="py-4 px-3"
                      css={css`
                        border-bottom: 1px solid ${theme.borderColor};
                      `}
                    >
                      <Typography className="mr-3">
                        <Tag color="success">门诊随访</Tag>
                      </Typography>
                    </div>
                  )}

                  <FormEducateList
                    type={type}
                    list={item.forms}
                    status={item.status}
                    nodeName={item.nodeName}
                    patientId={patientId}
                    projectId={projectId}
                    time={calculateTime(
                      item?.nodeTime,
                      item?.floatingNum,
                      item?.floatingDownNum,
                      undefined
                    )}
                    time2={calculateTime(
                      item?.nodeTime,
                      item?.floatingNum,
                      item?.floatingDownNum,
                      "-"
                    )}
                  />
                </AntdCollapse.Panel>
              </AntdCollapse>
            );
          })}
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </>
  );
});
