/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Toast } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { DeleteOutline } from "antd-mobile-icons";
import { useTheme } from "../../../theme";
import { Typography, Button } from "../../../components";
import { useDoctorRouter } from "../../../router/Doctor";
import sendIcon from "../../../assets/icons/send.svg";

export default observer(function ActionSheet(props: {
  checkboxValue?: string[];
  fileList: any[];
  onUploadFile: (e: any) => void;
  onDelFile: (val: string[]) => void;
}) {
  const theme = useTheme();
  const { state } = useLocation();
  const router = useDoctorRouter();
  const { checkboxValue = [], fileList, onDelFile, onUploadFile } = props;

  const handleUploadFile = useCallback(
    async (e: any) => {
      onUploadFile(e);
    },
    [onUploadFile]
  );

  const fileInfoList = useMemo(() => {
    return fileList.reduce((result: any[], item: any) => {
      return result.concat(item.files);
    }, []);
  }, [fileList]);

  const selectValue = useMemo(() => {
    return fileInfoList.filter((item) => checkboxValue.includes(item.id));
  }, [checkboxValue, fileInfoList]);

  // 判断文件类型是否为图片
  const isAssetTypeAnImage = useCallback((ext: string) => {
    return (
      [
        "png",
        "jpg",
        "jpeg",
        "bmp",
        "gif",
        "webp",
        "psd",
        "svg",
        "tiff",
      ].indexOf(ext.toLowerCase()) !== -1
    );
  }, []);

  // 当选中word文档发送时，若文件无法发送，需出现无法发送的按钮
  const isImage = useMemo(() => {
    return selectValue?.length > 0
      ? selectValue.every((item) => {
          const index = item?.url?.lastIndexOf(".") || 0;
          const ext = item?.url?.substr(index + 1);
          return isAssetTypeAnImage(ext);
        })
      : false;
  }, [isAssetTypeAnImage, selectValue]);

  const sendMes = useCallback(() => {
    if (checkboxValue.length > 9) {
      Toast.show("一次只能发送9张");
      return;
    }
    const selectFile = selectValue?.map((k) => {
      return `/api/${window._settings.hospitalCode}/${k.url}`;
    });
    router.navigateTo("messageDetail", {
      state: {
        patientId: state?.patientId,
        projectId: state?.projectId,
        name: state?.patientName,
        url: selectFile,
      },
    });
  }, [
    checkboxValue.length,
    router,
    selectValue,
    state?.patientId,
    state?.patientName,
    state?.projectId,
  ]);

  const delFiles = useCallback(() => {
    onDelFile(checkboxValue);
  }, [checkboxValue, onDelFile]);

  return (
    <div
      className="fixed bottom-0 py-4 w-11/12 z-50"
      css={css`
        background: ${theme.whiteColor};
        border-top: 1px solid ${theme.borderColor};
      `}
    >
      {checkboxValue?.length > 0 ? (
        <div className="flex justify-between items-center">
          <Typography block colorType="graySecondaryColor">
            已选 {checkboxValue?.length}
          </Typography>
          <div>
            <Button
              fill="none"
              onClick={sendMes}
              disabled={!(state?.isHas && isImage)}
            >
              <img className="align-middle" src={sendIcon} alt="" />
              <Typography>发送给此患者</Typography>
            </Button>
            <Button fill="none" onClick={delFiles}>
              <DeleteOutline fontSize={14} color={`${theme.grayMainColor}`} />
              <Typography className="ml-1">删除</Typography>
            </Button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <input
            type="file"
            multiple
            onChange={handleUploadFile}
            className="inline-block w-full h-10 absolute inset-0 z-50 opacity-0"
          />
          <Button block color="primary" fill="solid" className="relative z-20">
            上传文件
          </Button>
        </div>
      )}
    </div>
  );
});
