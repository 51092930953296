/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { useTheme } from "../../theme";
import { Ellipsis } from "antd-mobile";
// import moment from "moment";
import { Typography, ArrowIcon } from "../../components";
import formIcon from "../../assets/icons/formIcon.svg";
import teachIcon from "../../assets/icons/teachIcon.svg";
import patientEdit from "../../assets/icons/patientEdit.svg";
import { useAppUserIdentityType } from "../../hooks";
import { useDoctorRouter } from "../../router/Doctor";
import { usePatientRouter } from "../../router/Patient";
import { basePath as doctorBasePath } from "../../router/Doctor/config";
import { basePath as patientBasePath } from "../../router/Patient/config";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import { FormListType } from "../../models/entity/followUpProject";
import { FormOriginType } from "../FormEditor/FormGatherWrapper";

interface FormEducateListType {
  type: FormOriginType;
  list?: FormListType[];
  status?: number;
  time?: string[];
  time2?: string[];
  nodeName?: string;
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default observer(function FormEducateList(props: FormEducateListType) {
  //time2
  const { type, list, status, time, nodeName, patientId, projectId } = props;
  const theme = useTheme();
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();
  const doctorStore = useDoctorStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { appLogicStore, userLogicStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const router = useMemo(() => {
    return isDoctor ? routerDoctor : routerPatient;
  }, [isDoctor, routerDoctor, routerPatient]);

  const path = useMemo(() => {
    return isDoctor ? doctorBasePath : patientBasePath;
  }, [isDoctor]);

  const iType = useMemo(() => {
    // switch (type) {
    //   case "FollowUpProject":
    //     return 1;
    //   case "HealthManageProject":
    //     return 2;
    //   default:
    //     return 0;
    // }

    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const isHealthManage = useMemo(() => {
    return (
      type === "HealthManageProject" ||
      type === "HealthManagePatient" ||
      type === "HealthManage"
    );
  }, [type]);

  const isFollowUp = useMemo(() => {
    return !iType && !isHealthManage;
  }, [iType, isHealthManage]);

  // 患者随访表单填写状态
  const patientForm = useMemo(() => {
    return isFollowUp && (status === 1 || status === 3);
  }, [isFollowUp, status]);

  const statusText = useCallback(
    (formWrite: number, formContent: string) => {
      const save = formWrite === 0 && formContent && formContent !== ""; // 暂存
      const submit = formWrite === 1; // 提交
      const noWrite = formWrite === 0 && (!formContent || formContent === ""); // 未填
      let text = "";
      const _text = save
        ? "已保存"
        : submit
        ? "已提交"
        : noWrite
        ? "去填写"
        : "";
      if (isHealthManage) {
        text = submit ? "已提交" : noWrite ? "去填写" : "";
      } else {
        switch (status) {
          case 0:
            text = "未开始";
            break;
          case 1:
            text = _text;
            break;
          case 2:
            text = "已提交";
            break;
          case 3:
            text = _text;
            break;
          case 4:
            text = "已逾期";
            break;
          default:
            break;
        }
      }
      return text;
    },
    [isHealthManage, status]
  );

  const handleClick = useCallback(
    async (item: FormListType) => {
      if (item.formType === 1) {
        // 跳转到宣教页面
        router.navigateTo("preachAndTeach", {
          state: {
            id: item.formId,
            formName: item.formName,
            nodeName,
            time,
          },
        });
      } else {
        // 不可填写：0-未开始 4-已逾期  2-已完成

        // 可填写: 1-进行中 3-已逾期

        // 0-未开始 1-进行中 3和4都是逾期，他们的区别是 ，3是逾期后下面的表单仍可填写，4的话就是不能填的

        // 如果是 1-进行中 3-已逾期 没有内容时也是不可以填写的

        // isDoctor
        // 是根据方案里的节点设置，逾期可填 或者 逾期不可填但在填写时间窗内的表单可以多次提交编辑，仅限医生端

        // const isDisabled =
        //   item.formWrite === 1 ||
        //   status === 0 ||
        //   (status === 4 && !isDoctor) ||
        //   status === 2 ||
        //   iType ||
        //   ((status === 3 || status === 4) &&
        //     isDoctor &&
        // !(
        //   time2 &&
        //   moment(moment().format("MM-DD")).isBetween(
        //     moment(time2[0]).format("MM-DD"),
        //     moment(time2[1]).format("MM-DD"),
        //     null,
        //     "[]"
        //   )
        // ));

        const isDisabled =
          item.formWrite === 1 ||
          status === 0 ||
          status === 4 ||
          status === 2 ||
          iType;

        // ||
        // ((status === 3 || status === 4) &&
        //   isDoctor &&
        //   !(
        //     time2 &&
        //     moment(moment().format("MM-DD")).isBetween(
        //       moment(time2[0]).format("MM-DD"),
        //       moment(time2[1]).format("MM-DD"),
        //       null,
        //       "[]"
        //     )
        //   ));

        // console.log(item.formWrite);
        // console.log(status);
        // console.log(iType);

        // console.log(
        //   111,
        //   item.formWrite === 1 ||
        //     status === 0 ||
        //     (status === 4 && !isDoctor) ||
        //     status === 2 ||
        //     iType ||
        //     status === 3 ||
        //     status === 4
        // );

        // console.log(
        //   "时间",
        //   moment(moment().format("MM-DD")).isBetween(
        //     moment(time2![0]).format("MM-DD"),
        //     moment(time2![1]).format("MM-DD"),
        //     null,
        //     "[]"
        //   )
        // );

        // console.log(
        //   time2,
        //   !(
        //     time2 &&
        //     moment(moment().format("MM-DD")).isBetween(
        //       moment(time2[0]).format("MM-DD"),
        //       moment(time2[1]).format("MM-DD"),
        //       null,
        //       "[]"
        //     )
        //   )
        // );

        /**
         *
         *  formWrite  判断表单是否填写过  已填 - 1  未填 - 0
         */

        // let isDisabled = false;
        // if (isDoctor) {
        //   isDisabled =
        //     item.formWrite === 1 ||
        //     [0, 2, 4].includes(status || 0) ||
        //     iType ||
        //     !time2 ||
        //     !(
        //       time2 &&
        //       moment(moment().format("MM-DD")).isBetween(
        //         moment(time2[0]).format("MM-DD"),
        //         moment(time2[1]).format("MM-DD"),
        //         null,
        //         "[]"
        //       )
        //     );
        // } else {
        //   isDisabled =
        //     item.formWrite === 1 || [0, 2, 4].includes(status || 0) || iType;
        // }

        /*  const base =
          process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxbd/" : "/"; */

        const base =
          process.env.REACT_APP_PUBLIC_URL === "/nfmssxsf/"
            ? "/nfmssxbd/"
            : "/";

        router.navigateTo({
          // pathname: `/nfmssxbd/visit${path}followUpMiniProgramFormGatner`,
          pathname: `${base}visit${path}followUpMiniProgramFormGatner`,
          search: `?formId=${item.formId}&formAttrs=${JSON.stringify({
            isDisabled,
            hideOperationButtons: isDisabled,
          })}&formOrigin=${type}&payload=${JSON.stringify({
            id:
              iType && item?.gatherList && item?.gatherList?.length > 0
                ? item?.gatherList[0].id
                : "",
            formName: item.formName,
            formType: item.formType,
            nodeId: item.nodeId,
            time,
            nodeName,
            userName: userInfo?.name || "",
            projectId,
            patientId,
          })}`,
        });
      }
      appLogicStore.setTitle(item?.formName || "");
    },
    [
      appLogicStore,
      iType,
      nodeName,
      path,
      patientId,
      projectId,
      router,
      status,
      time,
      type,
      userInfo?.name,
    ]
  );

  return (
    <>
      {list?.map((item: FormListType, index: number) => {
        return (
          <div
            key={index}
            className="flex justify-between items-center py-4 pl-2 pr-4"
            css={css`
              border-bottom: 1px solid ${theme.borderColor};
            `}
            onClick={() => handleClick(item)}
          >
            <div className="flex items-center">
              <img
                className="w-6 h-6"
                src={item.formType === 1 ? teachIcon : formIcon}
                alt=""
              />
              <div className="flex items-center ml-2">
                {(patientForm || isHealthManage) &&
                  item.formWrite === 0 &&
                  item.formContent === "" && (
                    <img className="w-6 h-6 mr-1" src={patientEdit} alt="" />
                  )}
                <Typography
                  type="title"
                  css={css`
                    font-weight: 400;
                  `}
                >
                  <Ellipsis
                    direction="end"
                    rows={1}
                    content={item.formName || ""}
                  />
                </Typography>
              </div>
            </div>
            <div>
              {/* 这一块 UI 上显示状态的包括：患者端-随访-健康管理、医生端-患者列表-患者详情-健康管理 */}
              {/* 未开始的状态应该就是未开始，表单不能填写；进行中就会有未填写、已保存、已提交、已完成的是应该都是已经提交的，逾期 */}
              {item.formType !== 1 && (
                <Typography type="secondary" className="mr-1">
                  {iType
                    ? item?.gatherList?.length || 0
                    : statusText(
                        item?.formWrite as number,
                        item?.formContent as string
                      )}
                </Typography>
              )}
              <ArrowIcon
                direction="right"
                css={css`
                  font-size: 14px;
                `}
              />
            </div>
          </div>
        );
      })}
    </>
  );
});
