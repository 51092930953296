/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import useDoctorStore from "../../store/doctor";
import PatientList from "./PatientList";
import { Typography, SearchBar } from "../../components";
import { useDoctorRouter } from "../../router/Doctor";
import DropdownSelect from "../../coreUIComponents/DropdownSelect";
import DropdownTitle from "../../coreUIComponents/DropdownSelect/DropdownTitle";
import ProjectSelect from "../../coreUIComponents/FollowUp/ProjectSelect";
import {
  GetNodeListPromiseType,
  SelectListType,
} from "../../models/entity/patient";

export default observer(function Patient() {
  const router = useDoctorRouter();
  const doctorStore = useDoctorStore();
  const { patientEntityStore, followUpStore } = doctorStore;
  const { selectedProject, setSelectNodeItem } = followUpStore;
  const { selectedNode, selected, nodeList, list, allNumberPatient } =
    patientEntityStore;

  useEffect(() => {
    selectedProject?.empIds?.length > 0 &&
      patientEntityStore.serachPatients(selectedProject?.empIds);
  }, [patientEntityStore, selectedProject?.empIds]);

  useEffect(() => {
    selectedProject?.id && patientEntityStore.getNodeList(selectedProject?.id);
  }, [patientEntityStore, selectedProject?.id]);

  useEffect(() => {
    setSelectNodeItem(null);
  }, [setSelectNodeItem]);

  const toSearchPage = useCallback(() => {
    router.navigateTo("patientSearch", { state: { type: "list" } });
  }, [router]);

  return (
    <div className="page-padding flex flex-col h-full">
      <ProjectSelect
        keyId="bProject"
        type="OneNoColor"
        className="justify-start"
      />

      {list?.length ? (
        <SearchBar placeholder="搜索患者" onFocus={toSearchPage} />
      ) : null}

      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <DropdownSelect<SelectListType>
            selectValue={selected}
            keyId="patient"
            list={patientEntityStore.patientList}
            titleRender={
              <DropdownTitle
                type="title"
                title={selected?.label || "全部患者"}
                isSelected={!!selected?.label}
              />
            }
            checkedItem={(item: SelectListType) => {
              patientEntityStore.setSelectedPatient(item);
            }}
          >
            {(val: SelectListType) => (
              <DropdownTitle
                type="title"
                title={val.label}
                changeColor={false}
              />
            )}
          </DropdownSelect>

          <DropdownSelect<GetNodeListPromiseType>
            selectValue={selectedNode}
            keyId="node"
            list={nodeList}
            labelStr={"nodeName"}
            valueStr={"nodeId"}
            titleRender={
              <DropdownTitle
                type="title"
                title={selectedNode?.nodeName || "全部节点"}
                isSelected={!!selectedNode?.nodeName}
              />
            }
            checkedItem={(item: GetNodeListPromiseType) => {
              patientEntityStore.setSelectedNode(item);
            }}
          >
            {(val: GetNodeListPromiseType) => (
              <DropdownTitle
                type="title"
                title={val.nodeName}
                changeColor={false}
              />
            )}
          </DropdownSelect>
        </div>
        <Typography colorType="graySecondaryColor">
          共{allNumberPatient}位
        </Typography>
      </div>

      <div className="pt-0 flex-1 overflow-auto">
        <PatientList type="list" />
      </div>
    </div>
  );
});
