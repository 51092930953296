import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../../service";
import {
  HomeParamsType,
  HomePromiseType,
  HomeRemindParamsType,
} from "../../../models/entity/home";

export default class HomeEntityStore {
  homeInfo = null as HomePromiseType | null;

  constructor() {
    makeAutoObservable(this);
  }

  getHome = async (params: HomeParamsType) => {
    this.homeInfo = await serviceClient.home.getHome(params);
  };

  homeRemind = async (params: HomeRemindParamsType) => {
    await serviceClient.home.homeRemind(params);
  };
}
