/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Badge, TabBar } from "antd-mobile";
import {
  AppOutline,
  FileOutline,
  MessageOutline,
  UserOutline,
} from "antd-mobile-icons";
import { usePatientRouter } from "../../router/Patient";
import { observer } from "mobx-react-lite";
import usePatientStore from "../../store/patient";
import { TPatientRouterKey } from "../../router/Patient/config";
import { useTheme } from "../../theme";
import { css } from "@emotion/react";
import { AllMesListType } from "../../models/entity/instantMessage";

export default observer(function AppTabBar() {
  const router = usePatientRouter();
  const currentRouter = router.currentRouter();
  const theme = useTheme();
  const { userLogicStore, userSendMesStore } = usePatientStore();
  const { saasToken } = userLogicStore;
  const { allMesList } = userSendMesStore;

  const interval = useRef(null);
  const [activeKey, setActiveKey] = useState<TPatientRouterKey>("home");

  const setTabActive = (value: string) => {
    setActiveKey(value as TPatientRouterKey);
    router.navigateTo(value as TPatientRouterKey);
  };

  // 计算未读消息
  const count = useMemo(() => {
    return allMesList.length
      ? allMesList.reduce(
          (previousValue: number, currentValue: AllMesListType) => {
            return previousValue + currentValue.unread;
          },
          0
        )
      : 0;
  }, [allMesList]);

  const tabs: {
    key: TPatientRouterKey;
    title: ReactNode;
    icon: JSX.Element;
  }[] = [
    {
      key: "home",
      title: "首页",
      icon: <AppOutline />,
    },
    {
      key: "followUp",
      title: "随访",
      icon: <FileOutline />,
    },
    {
      key: "instantMessage",
      title: "消息",
      icon: (
        <Badge
          content={count > 0 ? count : ""}
          css={css`
            --top: 4px;
          `}
        >
          <MessageOutline />
        </Badge>
      ),
    },
    {
      key: "user",
      title: "个人中心",
      icon: <UserOutline />,
    },
  ];

  useEffect(() => {
    if (currentRouter?.id !== activeKey) {
      setActiveKey(currentRouter?.id ?? "home");
    }
  }, [activeKey, currentRouter?.id]);

  useEffect(() => {
    if (saasToken) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      getMesSessionList();
      return () => {
        if (interval.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearInterval(interval.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saasToken, userSendMesStore]);

  const getMesSessionList = () => {
    if (window._settings.isWXLogin) {
      (interval.current as any) = setTimeout(async () => {
        await userSendMesStore.fetchGetMesSessionList();
        getMesSessionList();
      }, 2000);
    }
  };

  return (
    <TabBar
      activeKey={activeKey}
      onChange={setTabActive}
      css={css`
        border-top: ${theme.styles.border};
        padding-bottom: 8px;
      `}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
});
