/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
} from "antd-mobile";
import { css } from "@emotion/react";
import { useTheme } from "../theme";

export interface ButtonProps extends AntdButtonProps {}

export default React.memo(function Button(props: ButtonProps) {
  const theme = useTheme();
  const { size, fill, color } = props;

  const _className = useMemo(() => {
    return [
      size === "large"
        ? css`
            &.adm-button-large {
              padding-top: 9px;
              padding-bottom: 9px;
            }
          `
        : "",
      fill === "none" && (!color || color === "default")
        ? css`
            color: ${theme.graySecondaryColor};
          `
        : "",
    ];
  }, [color, fill, size, theme.graySecondaryColor]);

  return <AntdButton css={_className} {...props} />;
});
