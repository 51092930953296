import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./pagesDoctor/Layout";
import { rootStore, StoreContext } from "./store/doctor";
import { setSaasTokenByUrl } from "./utils";

function AppDoctor() {
  setSaasTokenByUrl();
  const base = process.env.REACT_APP_PUBLIC_URL || "/";

  return (
    <BrowserRouter basename={base}>
      <StoreContext.Provider value={rootStore}>
        <Layout />
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default AppDoctor;
