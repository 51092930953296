/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import cx from "classnames";
import React, { useCallback } from "react";
import { Divider } from "antd-mobile";
import { useTheme } from "../../../theme";
import { Typography } from "../../../components";
import { FormListType } from "../../../models/entity/followUpProject";

export interface SearchItemType {
  keyword?: string;
  className?: string;
  itemValue: FormListType;
  onclick?: (val: FormListType) => void;
}

export const textHightLight = (
  value: string,
  keyWord: string,
  color: string
) => {
  if (keyWord !== "" && value.indexOf(keyWord) !== -1) {
    return value.replace(keyWord, `<font color=${color}>${keyWord}</font>`);
  }
  return value;
};

export default React.memo(function SearchItem(props: SearchItemType) {
  const theme = useTheme();
  const { keyword = "", className, itemValue, onclick } = props;

  const handleClick = useCallback(
    (itemValue: FormListType) => {
      onclick && onclick(itemValue);
    },
    [onclick]
  );

  return (
    <div
      className={cx("flex items-center", className)}
      onClick={() => handleClick(itemValue)}
    >
      <Typography type="title">
        <div
          dangerouslySetInnerHTML={{
            __html: textHightLight(
              `${itemValue?.patientName}`,
              keyword,
              `${theme.primaryColor}`
            ),
          }}
        ></div>
      </Typography>
      <Divider className="mx-4" direction="vertical"></Divider>
      <Typography type="title">
        {itemValue?.gender === "1"
          ? "男"
          : itemValue?.gender === "2"
          ? "女"
          : "其他"}
      </Typography>
      <Divider className="mx-4" direction="vertical"></Divider>
      <Typography type="title">{itemValue?.age}岁</Typography>
    </div>
  );
});
