import { FollowupPlanInfoType } from "./followUpProject";
import { UserIdentityType } from "./user";

export enum FollowUpUserGender {
  "男" = "1",
  "女" = "2",
  "其他" = "9",
}

export enum FileType {
  "全部" = -1,
  "照片" = 1,
  "视频" = 2,
  "文件" = 3,
}
export interface projectPatient {
  total: number;
  rows: Patient[];
}

export interface Patient {
  currentNodeId?: string;
  beginNodeDate?: string;
  empid: string;
  focus: number;
  age?: number;
  gender?: FollowUpUserGender | undefined;
  id: string;
  patientId: string;
  patientName: string;
  projectId: string;
  projectName?: string;
  status?: number;
  statusName: string;
  todayStatus?: number;
  percentage?: string;
  nodes?: FollowupPlanInfoType[];
  /** 0 - 隐藏  1 - 显示 */
  talk: number;
}

export interface CommonParamsType {
  empiOrUserid: number | string;
  type: UserIdentityType;
}

export interface PatientFocusParamsType extends CommonParamsType {
  patientId: number | string;
  focusType: number;
}

export interface GetPatientListParamsType extends CommonParamsType {
  nodeId: number | string;
  projectId: number | string;
  searchVal: string;
  status: number | string;
  pNum: number;
  pSize?: number;
  total: number;
}

export type GetNodeListPromiseType = {
  nodeId: string;
  nodeName: string;
};

export type SelectListType = {
  label: string;
  value: string;
};

export interface SerachPatientsType {
  empi: string;
  isHas: boolean;
}

export interface FileListParamsType {
  patientId: string;
  projectId: string;
  searchVal: string;
  type: FileType;
}

export interface FilesListType {
  fileName: string;
  id: string;
  time: string;
  url: string;
}

export interface FileListPromiseType {
  time: string;
  timeFormat: string;
  files: FilesListType[];
}

export interface historyListType {
  id: string;
  key: string;
  count: number;
}

export const patientList: SelectListType[] = [
  {
    label: "全部患者",
    value: "-1",
  },
  {
    label: "今日随访",
    value: "0",
  },
  {
    label: "逾期患者",
    value: "1",
  },
  {
    label: "关注患者",
    value: "2",
  },
];
