export const compressImg = (file: File) => {
  return new Promise(function (resolve: any, reject: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img: any = new Image();
      img.src = reader.result;
      img.onload = () => {
        // 图片的宽高
        const w = img.width;
        const h = img.height;
        const canvas = document.createElement("canvas");
        // canvas对图片进行裁剪，这里设置为图片的原始尺寸
        canvas.width = w;
        canvas.height = h;
        const ctx: any = canvas.getContext("2d");
        // canvas中，png转jpg会变黑底，所以先给canvas铺一张白底
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // 绘制图像
        ctx.drawImage(img, 0, 0, w, h);

        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
        // base64格式文件转成Blob文件格式
        // const blobFile = dataURLtoBlob(dataUrl);
        // base64格式文件转成File文件格式
        const blobFile = base64ToFile(dataUrl, file.name);
        // console.log("压缩后的file----------", blobFile);
        resolve(blobFile);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Base64转File
 * @param dataURL   {String}  base64
 * @param fileName	{String}  文件名
 * @param mimeType	{String}  [可选]文件类型，默认为base64中的类型
 * @returns {File}
 */
function base64ToFile(dataURL: any, fileName: string, mimeType = null) {
  const arr = dataURL.split(","),
    defaultMimeType = arr[0].match(/:(.*?);/)[1],
    bStr = atob(arr[1]);
  let n = bStr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mimeType || defaultMimeType });
}

// canvas生成的格式为base64，需要进行转化
export const dataURLtoBlob = (dataurl: any) => {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const saveImg = (imgPath: string) => {
  const blob = new Blob([""], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = imgPath;
  a.download = imgPath.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
  a.click();
  URL.revokeObjectURL(url);
};
