import { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { Divider } from "antd-mobile";
import RecordList from "./RecordList";
import DropdownSelect from "../DropdownSelect";
import DropdownTitle from "../DropdownSelect/DropdownTitle";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import { TimeList, VisitTypeList } from "../../models/entity/hospitalData";
import { SelectListType } from "../../models/entity/patient";

export default observer(function MedicalRecord() {
  const { state } = useLocation();
  const { empid } = state || {};
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();
  const doctorStore = useDoctorStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { hospitalDataStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const visitRecord = useMemo(() => {
    return hospitalDataStore.visitRecord;
  }, [hospitalDataStore.visitRecord]);

  const selectedTime = useMemo(() => {
    return hospitalDataStore.selectedTime;
  }, [hospitalDataStore.selectedTime]);

  const selectedNode = useMemo(() => {
    return hospitalDataStore.selectedNode;
  }, [hospitalDataStore.selectedNode]);

  const activeKey = useMemo(() => {
    return hospitalDataStore.activeKey;
  }, [hospitalDataStore.activeKey]);

  const getVisitRecord = useCallback(() => {
    hospitalDataStore.getVisitRecord({
      empiseqid: empid,
      type: selectedTime?.value || "0",
      visitType: selectedNode?.value || "0",
    });
  }, [empid, hospitalDataStore, selectedNode?.value, selectedTime?.value]);

  useEffect(() => {
    activeKey === "medicalRecord" && getVisitRecord();
  }, [activeKey, getVisitRecord]);

  return (
    <div className={isDoctor ? "" : "page-padding"}>
      <div className="flex items-center">
        <DropdownSelect<SelectListType>
          selectValue={selectedTime}
          keyId="date"
          list={TimeList}
          titleRender={
            <DropdownTitle
              type="title"
              title={selectedTime?.label || "近3个月"}
              isSelected={!!selectedTime?.label}
            />
          }
          checkedItem={(item: SelectListType) => {
            hospitalDataStore.setSelectedTime(item);
          }}
        >
          {(val: SelectListType) => (
            <DropdownTitle type="title" title={val.label} changeColor={false} />
          )}
        </DropdownSelect>

        <DropdownSelect<SelectListType>
          selectValue={selectedNode}
          keyId="type"
          list={VisitTypeList}
          titleRender={
            <DropdownTitle
              type="title"
              title={selectedNode?.label || "就诊类型"}
              isSelected={!!selectedNode?.label}
            />
          }
          checkedItem={(item: SelectListType) => {
            hospitalDataStore.setSelectedNode(item);
          }}
        >
          {(val: SelectListType) => (
            <DropdownTitle type="title" title={val.label} changeColor={false} />
          )}
        </DropdownSelect>
      </div>
      <Divider className="-mx-4 mt-0.5 mb-1"></Divider>
      <RecordList list={visitRecord} />
    </div>
  );
});
