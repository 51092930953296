/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import { Typography, Button } from "../../components";
import { Image, ImageViewer, Toast } from "antd-mobile";
import { serviceClient } from "../../service";
import useDoctorStore from "../../store/doctor";
import defaultPicture from "../../assets/image/defaultPicture.svg";
import { FolderOutline } from "antd-mobile-icons";

interface ImageViewersType {
  srcUrl: string;
  className?: string;
  showFooter?: boolean;
  patientId?: string;
  projectId?: string;
}

export default observer(function ImageViewers(props: ImageViewersType) {
  const { srcUrl, className, showFooter = false, patientId, projectId } = props;
  const doctorStore = useDoctorStore();
  const { userLogicStore } = doctorStore;

  const [visible, setVisible] = useState(false);

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const savePatientFolder = useCallback(async () => {
    if (patientId && projectId) {
      const format = srcUrl?.substring(srcUrl?.lastIndexOf(".") + 1);
      const spliceLength = srcUrl.lastIndexOf("/");
      const imageName = srcUrl.slice(spliceLength + 1);
      fetch(srcUrl)
        .then((res) => {
          return res.blob();
        })
        .then(async (blob) => {
          const imgFile = new File([blob], imageName, {
            type: `image/${format}`,
          });
          const formData = new FormData();
          formData.append("filelist", imgFile);
          formData.append("patientId", patientId);
          formData.append("projectId", projectId);
          formData.append("operator", userInfo?.name || "");
          const res = await serviceClient.patient.fileUpload(formData);
          if (res?.code === 200) {
            Toast.show("保存成功");
          }
        });
    }
  }, [patientId, projectId, srcUrl, userInfo?.name]);

  // const saveAlbum = useCallback((Url: string) => {
  //   var blob = new Blob([""], { type: "application/octet-stream" });
  //   var url = URL.createObjectURL(blob);
  //   var a = document.createElement("a");
  //   a.href = Url;
  //   a.download = Url.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
  //   var e = document.createEvent("MouseEvents");
  //   e.initMouseEvent(
  //     "click",
  //     true,
  //     false,
  //     window,
  //     0,
  //     0,
  //     0,
  //     0,
  //     0,
  //     false,
  //     false,
  //     false,
  //     false,
  //     0,
  //     null
  //   );
  //   a.dispatchEvent(e);
  //   URL.revokeObjectURL(url);
  // }, []);

  return (
    <>
      <Image
        src={srcUrl}
        fallback={<Image src={defaultPicture} />}
        className={cx("rounded-md", className)}
        onContainerClick={() => setVisible(true)}
      />
      <ImageViewer
        image={srcUrl}
        visible={visible}
        onClose={() => setVisible(false)}
        getContainer={() => document.body}
        renderFooter={(image: string) => (
          <div className="flex flex-row-reverse items-center mb-12">
            {/* <div
              className={`flex items-center rounded-sm mr-8`}
              css={css`
                background: ${theme.borderColor};
              `}
              onClick={() => saveAlbum(srcUrl)}
            >
              <Button size="mini" fill="none" block>
                <div className="flex items-center">
                  <DownlandOutline />
                  <Typography
                    colorType="grayMainColor"
                    className="ml-1 leading-loose"
                  >
                    保存到相册
                  </Typography>
                </div>
              </Button>
            </div> */}
            {showFooter && (
              <div
                className={`flex items-center rounded-sm mr-8`}
                css={css`
                  background: #f7f8fa;
                `}
                onClick={savePatientFolder}
              >
                <Button size="mini" fill="none" block>
                  <div className="flex items-center">
                    <FolderOutline />
                    <Typography
                      colorType="grayMainColor"
                      className="ml-1 leading-loose"
                    >
                      添加到患者资料夹
                    </Typography>
                  </div>
                </Button>
              </div>
            )}
          </div>
        )}
      />
    </>
  );
});
