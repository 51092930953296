/** @jsxImportSource @emotion/react */
import React from "react";
import SecondaryHead from "../FormBrowse/SecondaryHead";
import { useLocation } from "react-router-dom";
import HtmlEleContent from "./Content";

export default React.memo(function PreachAndTeach() {
  const { state } = useLocation();
  const { id, formName, nodeName, time } = state || {};

  return (
    <>
      <SecondaryHead
        formType={1}
        nodeTime={time as string[]}
        formName={formName as string}
        nodeName={nodeName as string}
        gatherList={[]}
      />
      <HtmlEleContent id={id} />
    </>
  );
});
