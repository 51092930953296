import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import {
  GetHomeNodeListParamsType,
  GetHomeNodeListPromiseType,
  GetOpcProjectListParamsType,
  GetOpcProjectListPromiseType,
} from "../../models/entity/followUpOutpatient";

class FollowUpOutpatientStore {
  selectedOpcProject = {} as GetOpcProjectListPromiseType;
  opcProjectList = [] as GetOpcProjectListPromiseType[];
  homeNodeList = [] as GetHomeNodeListPromiseType[];
  constructor() {
    makeAutoObservable(this);
  }

  setSelectedOpcProject = (val: GetOpcProjectListPromiseType) => {
    this.selectedOpcProject = val;
  };

  getOpcProjectList = async (params: GetOpcProjectListParamsType) => {
    this.selectedOpcProject.projectName = "";
    this.selectedOpcProject.projectId = "-1";

    const resList = await serviceClient.followUpOutpatient.getOpcProjectList(
      params
    );
    this.opcProjectList = [
      ...[
        {
          projectId: "-1",
          projectName: "全部随访",
        },
      ],
      ...resList,
    ];

    // this.selectedOpcProject = this.opcProjectList[0];
  };

  getHomeNodeList = async (params: GetHomeNodeListParamsType) => {
    this.homeNodeList = [];
    this.homeNodeList = await serviceClient.followUpOutpatient.getHomeNodeList(
      params
    );
  };
}

export default FollowUpOutpatientStore;
