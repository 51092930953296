/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useTheme } from "../../theme";
import ListItemCard from "./ListItemCard";
import { Typography } from "../../components";
import { useLocation } from "react-router-dom";
import increaseIcon from "../../assets/icons/increase.svg";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import {
  ItemRecordType,
  CheckUpRecordType,
  CheckOutItemRecordType,
} from "../../models/entity/hospitalData";

export default observer(function ReportDetail() {
  const theme = useTheme();
  const { state } = useLocation();
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();
  const doctorStore = useDoctorStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, hospitalDataStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const checkOutRecordInfo = useMemo(
    () => hospitalDataStore.checkOutRecordInfo,
    [hospitalDataStore.checkOutRecordInfo]
  );

  const reportType = useMemo(() => {
    return state.activeKey === "checkout";
  }, [state.activeKey]);

  const data = useMemo(() => {
    return reportType
      ? (checkOutRecordInfo as CheckOutItemRecordType)
      : (JSON.parse(state?.details) as CheckUpRecordType);
  }, [checkOutRecordInfo, reportType, state?.details]);

  useEffect(() => {
    reportType && hospitalDataStore.getCheckOutItemRecord(state.reportid);
  }, [hospitalDataStore, reportType, state.reportid]);

  return (
    <div
      css={css`
        background: ${theme.borderColor};
      `}
      className="page-padding"
    >
      <div
        css={css`
          background: ${theme.whiteColor};
        `}
        className="py-4 px-3"
      >
        <div
          css={css`
            border-bottom: 1px dashed ${theme.grayWeakColor};
          `}
          className="pb-2"
        >
          <div className="flex items-center mb-2">
            <Typography type="secondary" block>
              {reportType
                ? (data as CheckOutItemRecordType)?.reporteddatetime || "--"
                : (data as CheckUpRecordType)?.reporteddatetime || "--"}
            </Typography>
            <Typography type="secondary" block className="ml-2">
              {reportType
                ? userInfo?.hospitalName || "--"
                : (data as CheckUpRecordType)?.hospitalname || "--"}
            </Typography>
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <Typography type="secondary">
                {reportType ? "检验" : "检查"}医生：
              </Typography>
              <Typography type="secondary" colorType="grayMainColor">
                {reportType
                  ? (data as CheckOutItemRecordType)?.laboperatorname || "--"
                  : (data as CheckUpRecordType)?.obsphysiciansname || "--"}
              </Typography>
            </div>
            {/* <div className="flex-1">
              <Typography type="secondary">申请科室：</Typography>
              <Typography type="secondary" colorType="grayMainColor">
                内科
              </Typography>
            </div> */}
          </div>
          <div className="flex justify-between items-center mb-2">
            {/* <div className="flex-1">
              <Typography type="secondary">书写医生：</Typography>
              <Typography type="secondary" colorType="grayMainColor">
                李一珍
              </Typography>
            </div> */}
            <div className="flex-1">
              <Typography type="secondary">审核医生：</Typography>
              <Typography type="secondary" colorType="grayMainColor">
                {reportType
                  ? (data as CheckOutItemRecordType)?.assessorname || "--"
                  : (data as CheckUpRecordType)?.assessorname || "--"}
              </Typography>
            </div>
          </div>
        </div>

        {reportType && (
          <div className="pt-4">
            <Typography type="primary" block className="font-medium">
              项目结果
            </Typography>
            {(data as CheckOutItemRecordType)?.list?.map(
              (item: ItemRecordType, index: number) => {
                return (
                  <ListItemCard className="pb-4 mt-4" key={index}>
                    <div className="flex items-center mb-3">
                      <Typography type="primary" block>
                        {item?.labsubitemnamecn || "--"}
                      </Typography>
                      <Typography type="primary" block className="mx-3">
                        {item.labvalue}
                      </Typography>
                      <img
                        className="w-4 h-4 hidden"
                        src={increaseIcon}
                        alt=""
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <Typography type="mini" block>
                        参考范围：{item?.referencesrange || "--"}
                      </Typography>
                      <Typography type="mini" block>
                        单位：{item?.unit || "--"}
                      </Typography>
                    </div>
                  </ListItemCard>
                );
              }
            )}
          </div>
        )}

        {!reportType && (
          <>
            <div className="pt-4">
              <Typography type="primary" block className="font-medium">
                检查所见
              </Typography>
              <ListItemCard className="pb-4 mt-4">
                <Typography type="primary" block>
                  {(data as CheckUpRecordType)?.findings || "--"}
                </Typography>
              </ListItemCard>
            </div>

            <div className="pt-4">
              <Typography type="primary" block className="font-medium">
                检查结论
              </Typography>
              <ListItemCard className="pb-4 mt-4">
                <Typography type="primary" block>
                  {(data as CheckUpRecordType)?.obsdiagtext || "--"}
                </Typography>
              </ListItemCard>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
