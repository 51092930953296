/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Divider } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Button, Typography } from "../../components";
import { useTheme } from "../../theme";
import doctorPhotoImg from "../../assets/icons/doctorPhoto.svg";
import UserInfoItem from "./components/UserInfoItem";
import useLogoutDialog from "../../coreUIComponents/User/LogoutDialog";
import usePatientStore from "../../store/patient";
import { usePatientRouter } from "../../router/Patient";
import HospitalSelect from "./HospitalSelect";
import { serviceClient } from "../../service";

export default observer(function User() {
  const theme = useTheme();
  const router = usePatientRouter();
  const { userLogicStore } = usePatientStore();
  const { userInfo } = userLogicStore;
  const logoutDialog = useLogoutDialog();
  let [hospitalDialogVisible, setHospitalDialogVisible] = useState(false);

  const logout = useCallback(() => {
    logoutDialog(() => userLogicStore.logout());
  }, [logoutDialog, userLogicStore]);

  const navigatorToFollowUpUser = useCallback(() => {
    router.navigateTo("followUpUser");
  }, [router]);

  const onSelectHospital = useCallback(
    async (value: string) => {
      if (value !== userInfo?.hospitalId) {
        await serviceClient.user.switchHospital(value);
        await userLogicStore.initializeUserInfo();
      }
    },
    [userInfo?.hospitalId, userLogicStore]
  );

  return (
    <div
      className="px-4 pt-14 pb-4"
      css={css`
        height: 100%;
        background-color: ${theme.primaryColor};
      `}
    >
      <div
        css={css`
          height: 100%;
          position: relative;
          background-color: ${theme.whiteColor};
          border-radius: 4px;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          className="px-4"
          css={css`
            flex-grow: 1;
            overflow-y: auto;
          `}
        >
          <img
            css={css`
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            `}
            className="w-20 h-20"
            src={
              JSON.parse(localStorage.getItem("wxInfo") || "{}")?.avatarUrl ||
              doctorPhotoImg
            }
            alt=""
          />
          <Typography type="title" className="text-center pt-14 pb-7" block>
            {userInfo?.name}
          </Typography>
          <UserInfoItem
            title="随访人"
            value={
              <span onClick={navigatorToFollowUpUser}>
                <Typography type="title" colorType="grayLightColor">
                  当前选中：
                </Typography>
                {userInfo?.name}
              </span>
            }
            showArrow
          ></UserInfoItem>

          <UserInfoItem
            title="医院"
            value={
              <span onClick={() => setHospitalDialogVisible(true)}>
                {userInfo?.hospitalName}
              </span>
            }
            showArrow
          ></UserInfoItem>
        </div>
        <div>
          <Divider
            css={css`
              margin: 0;
            `}
          ></Divider>
          {userLogicStore.saasToken ? (
            <Button
              fill="none"
              color="danger"
              block
              size="large"
              onClick={logout}
            >
              退出账号
            </Button>
          ) : (
            <Button
              fill="none"
              color="primary"
              block
              size="large"
              onClick={logout}
            >
              去登录
            </Button>
          )}
        </div>
      </div>
      {hospitalDialogVisible && (
        <HospitalSelect
          defaultValue={userInfo?.hospitalId ?? ""}
          onSelect={onSelectHospital}
          onVisibleHandle={setHospitalDialogVisible}
        />
      )}
    </div>
  );
});
