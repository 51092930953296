import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../../service";
import {
  Patient,
  GetPatientListParamsType,
  GetNodeListPromiseType,
  PatientFocusParamsType,
  SelectListType,
  patientList,
  SerachPatientsType,
  FileListParamsType,
  FileListPromiseType,
  historyListType,
} from "../../../models/entity/patient";
class PatientEntityStore {
  list = [] as Patient[]; // 患者列表
  serachPatientList = [] as SerachPatientsType[]; // 判断是否可以进行对话
  fileList = [] as FileListPromiseType[]; // 患者资料夹
  allNumberPatient = 0;

  historyList = [] as historyListType[];

  searchVal = "" as string;
  patientList = patientList;
  nodeList = [] as GetNodeListPromiseType[];
  selected = { label: "", value: "-1" } as SelectListType;
  selectedNode = { nodeId: "-1", nodeName: "" } as GetNodeListPromiseType;

  constructor() {
    makeAutoObservable(this);
  }

  setSearchVal = (val: string) => {
    this.searchVal = val;
    this.list = [];
  };

  setSelectedPatient = (val: SelectListType) => {
    this.selected = val;
    this.list = [];
  };

  setSelectedNode = (val: GetNodeListPromiseType) => {
    this.selectedNode = val;
    this.list = [];
  };

  setHistoryList = (val: historyListType[]) => {
    this.historyList = val;
  };

  getList = async (params: GetPatientListParamsType) => {
    return await serviceClient.patient.getList(params);
  };

  setAllNumberPatient = (value: number) => {
    this.allNumberPatient = value;
  };

  setList = (list: any) => {
    this.list = list;
  };

  getNodeList = async (id: string) => {
    const res = await serviceClient.patient.getNodeList(id);
    this.nodeList = [
      ...[
        {
          nodeId: "-1",
          nodeName: "全部节点",
        },
      ],
      ...res,
    ];
  };

  patientFocus = async (params: PatientFocusParamsType) => {
    await serviceClient.patient.patientFocus(params);
  };

  // 是否可以进行对话
  serachPatients = async (params: string[]) => {
    this.serachPatientList = await serviceClient.patient.serachPatients(params);
  };

  patientFileList = async (params: FileListParamsType) => {
    const res = await serviceClient.patient.patientFileList(params);
    if (res?.code === 200) {
      this.fileList = res.rows;
    }
  };

  delFile = async (ids: string[], params: FileListParamsType) => {
    await serviceClient.patient.delFile(ids);
    this.patientFileList(params);
  };

  renameFile = async (
    myParams: { id: string; name: string },
    params: FileListParamsType
  ) => {
    await serviceClient.patient.renameFile(myParams);
    this.patientFileList(params);
  };
}

export default PatientEntityStore;
