import { Toast } from "antd-mobile";
import axios from "axios";
import { useCallback, useRef, useState, useEffect } from "react";

const ServiceSubscription = () => {
  const [disabled, setDisabled] = useState(true);
  const [template, setTemplate] = useState<string>("");
  const info = useRef<any>({});
  const subscribeBtn = useRef<any>(null);

  const toPage = useCallback(() => {
    if (info?.current?.type === 1) {
      window.location.replace(
        `ui/patient-entry/home?token=${info?.current?.token}`
      );
    } else {
      window.location.replace(
        `ui/doctor-entry/followUpProject?token=${info?.current?.token}`
      );
    }
  }, []);

  const getSignatureInfo = useCallback(async () => {
    setDisabled(true);

    const params = {
      code: info?.current?.wxCode,
      type: info?.current?.type,
      url: encodeURIComponent(window.location.href.split("#")[0]),
    };

    // const reqUrl =
    //   process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxsf/" : "/";

    const reqUrl = process.env.REACT_APP_PUBLIC_URL || "/";
    const result = await axios({
      url: `${reqUrl}fj-api/system/weChat/getWeChatConfig`,
      method: "post",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
    });

    const res = result.data;
    if (res.code !== 200) {
      Toast.show({
        content: "获取订阅授权失败",
        afterClose: () => {
          toPage();
        },
      });
    } else {
      const data = res.data || {};
      setTemplate(data.templateList);

      delete data.templateList;
      wx.config({
        // debug: true,
        ...data,
      });

      wx.ready(() => {
        setDisabled(false);
        document.addEventListener("WeixinOpenTagsError", weixinOpenTagsErrorFn);

        /*  const btn = document.getElementById("subscribe-btn") as HTMLElement;
        btn.addEventListener("success", function (e: any) {
          let subs = e.detail.subscribeDetails;
          let subsStr = JSON.stringify(subs);
          if (subsStr.indexOf("accept") !== -1) {
          } else if (subsStr.indexOf("reject") !== -1) {
          }
          toPage();
        }); */

        subscribeBtn.current?.addEventListener("success", toPage);

        subscribeBtn.current?.addEventListener("error", toPage);
      });

      wx.error(() => {
        setDisabled(false);
        Toast.show({
          content: "微信JS-SDK配置失败",
          afterClose: () => {
            toPage();
          },
        });
      });
    }
  }, [toPage]);

  const weixinOpenTagsErrorFn = () => {
    window.WeixinOpenTagsDisable = true;
  };

  useEffect(() => {
    info.current = JSON.parse(localStorage.getItem("wxInfo") ?? "{}");
    subscribeBtn.current = document.getElementById("subscribe-btn");

    getSignatureInfo();

    return () => {
      document.removeEventListener(
        "WeixinOpenTagsError",
        weixinOpenTagsErrorFn
      );

      subscribeBtn.current?.removeEventListener("error", toPage);
      subscribeBtn.current?.removeEventListener("success", toPage);
    };
  }, [getSignatureInfo, toPage]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 20px 80px 20px",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%" }}>
        <h3>Hi,你好：</h3>
        <h3>欢迎加入</h3>
        <h3>{window._settings.appTitle || "随访系统"}</h3>
        <h3>随访研究项目~</h3>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* @ts-ignore */}
        <wx-open-subscribe
          template={template}
          id="subscribe-btn"
          style={{
            width: "100%",
            display: "block",
            textAlign: "center",
          }}
        >
          <script type="text/wxtag-template" slot="style">
            <style>{`.subscribe-btn {
              border-radius: 4px;
              font-size: 16px;
              color: #fff;
              opacity: ${disabled ? 0.5 : 1};
              background-color: #1677ff;
              border:none;
              padding:10px 60px;
            }
            }`}</style>
          </script>

          <script type="text/wxtag-template">
            <button disabled={disabled} className="subscribe-btn">
              进入随访项目
            </button>
          </script>
          {/* @ts-ignore */}
        </wx-open-subscribe>
      </div>
    </div>
  );
};

export default ServiceSubscription;
