import { initGlobalState } from "qiankun";

const action = initGlobalState({
  app: null,
  type: null,
  event: null,
  data: null,
});

export { action };
