/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useTheme } from "../../theme";

interface ListItemCardType {
  type?: "row" | "column";
  className?: string;
  children: ReactNode;
  handleClick?: () => void;
}

export default React.memo(function ListItemCard(props: ListItemCardType) {
  const theme = useTheme();
  const { type = "column", className, children, handleClick } = props;

  const _style = useMemo(() => {
    return [
      type === "row" &&
        css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `,
      css`
        background: ${theme.whiteColor};
        border-bottom: 1px solid ${theme.borderColor};
      `,
    ];
  }, [theme.borderColor, theme.whiteColor, type]);

  const onHandleClick = useCallback(() => {
    handleClick && handleClick();
  }, [handleClick]);

  return (
    <div css={_style} className={className} onClick={onHandleClick}>
      {children}
    </div>
  );
});
