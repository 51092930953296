import React, { useMemo } from "react";
import { Typography } from "../../../../components";
import {
  SerachPatientCountPromiseType,
  GetProjectListPromiseType,
} from "../../../../models/entity/followUpProject";

export interface DropdownItemNumType {
  count: SerachPatientCountPromiseType[];
  data?: GetProjectListPromiseType;
}

export default React.memo(function DropdownItemNum(props: DropdownItemNumType) {
  const { count, data } = props;

  const addCount = useMemo(() => {
    return count.find(
      (item: SerachPatientCountPromiseType) => item.projectId === data?.id
    );
  }, [count, data?.id]);

  return (
    <div className="mt-2">
      {data?.status === 1 ? (
        <>
          {window._settings.isWXLogin && (
            <>
              <Typography type="mini" colorType="primaryColor">
                {addCount?.count || 0}位已加入小程序
              </Typography>
              <Typography type="mini" colorType="grayMainColor">
                /
              </Typography>
            </>
          )}

          <Typography type="mini" colorType="graySecondaryColor">
            共{data?.totalCount || 0}位患者
          </Typography>
        </>
      ) : (
        <Typography type="secondary">已结束</Typography>
      )}
    </div>
  );
});
