/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useCallback, useMemo, useRef } from "react";
import { Dropdown as AntdDropdown } from "antd-mobile";
import DropdownIcon from "./DropdownIcon";
import DropdownList from "./DropdownList";

/**
 * type:
 *   normal - 正常的下拉选择
 *   icon - icon下拉选择(表单预览中的下拉选择)
 *   OneBandColor - 项目信息中的下拉选择(无背景色)
 *   OneNoColor - 随访首页中的下拉选择(有背景色)
 */

interface DropdownSelectType<T> {
  keyId: string; // 每一个下拉的 key
  type?: "normal" | "icon" | "OneBandColor" | "OneNoColor";
  list: T[];
  labelStr?: string;
  valueStr?: string;
  selectValue: T;
  showArrow?: boolean;
  arrowFill?: boolean; // icon 是否填充
  className?: string;
  titleRender: ReactNode;
  otherRender?: ReactNode;
  children: Function;
  checkedItem?: Function;
}

export default function DropdownSelect<T>(props: DropdownSelectType<T>) {
  const {
    keyId,
    type = "normal",
    list,
    labelStr = "label",
    valueStr = "value",
    selectValue,
    showArrow = true,
    arrowFill = true,
    className,
    titleRender,
    otherRender,
    children,
    checkedItem,
  } = props;
  const DropdownRef = useRef(null);

  const handleSelect = useCallback(
    (item: T, index: number) => {
      checkedItem && checkedItem(item, index);
      if (DropdownRef.current) {
        (DropdownRef.current as any).close();
      }
    },
    [checkedItem]
  );

  const _style = useMemo(() => {
    return [
      type === "OneBandColor" &&
        css`
          background: #f7f8fa;
          .adm-dropdown-item-title {
            width: 100%;
            justify-content: space-between;
          }
        `,
      type === "OneNoColor" &&
        css`
          .adm-dropdown-item-title {
            padding: 12px 24px 12px 0;
            align-items: flex-start;
          }
          .adm-dropdown-item-title-arrow {
            display: block;
            margin-top: 6px;
          }
        `,
      type === "normal" &&
        css`
          .adm-dropdown-item-title {
            padding: 12px 24px 12px 0;
          }
        `,
      type === "icon" &&
        css`
          .adm-dropdown-item-title {
            padding: 4px;
          }
        `,
    ];
  }, [type]);

  return (
    <AntdDropdown
      ref={DropdownRef}
      closeOnClickAway={true}
      css={[
        css`
          &.adm-dropdown-open .adm-dropdown-nav {
            border-bottom: 1px solid transparent;
          }
        `,
        type === "icon" &&
          css`
            &.adm-dropdown {
              background: transparent;
            }
          `,
      ]}
    >
      <AntdDropdown.Item
        key={keyId}
        title={titleRender}
        arrow={
          showArrow ? (
            <DropdownIcon
              fill={arrowFill}
              isSelected={(selectValue as never)[labelStr]}
            />
          ) : null
        }
        className={className}
        css={_style}
      >
        <div className="p-0">
          <>
            {otherRender}
            <DropdownList<T>
              list={list}
              labelStr={labelStr}
              valueStr={valueStr}
              selectValue={selectValue}
              handleSelect={handleSelect}
            >
              {(val: T) => children(val)}
            </DropdownList>
          </>
        </div>
      </AntdDropdown.Item>
    </AntdDropdown>
  );
}
