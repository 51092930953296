import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from "../../components";
import calendarImg from "../../assets/icons/doctorPhoto.svg";
import PatientAvatar from "../../coreUIComponents/Patient/PatientAvatar";
import ReportList from "./ReportList";
import MedicalRecord from "./MedicalRecord";
import medicalRecord from "../../assets/image/medicalRecord.svg";
import checkReport from "../../assets/image/checkReport.svg";
import inspectReport from "../../assets/image/inspectReport.svg";
import { useLocation } from "react-router-dom";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";

export const comTabList = [
  {
    label: "就诊记录",
    value: "medicalRecord",
    description: "本院历次就诊",
    img: <img className="w-12 h-12" src={medicalRecord} alt="" />,
  },
  {
    label: "检验报告",
    value: "checkout",
    description: "检验结果，包括生化、免疫等",
    img: <img className="w-12 h-12" src={checkReport} alt="" />,
  },
  {
    label: "检查报告",
    value: "inspect",
    description: "普放、CT、MRI等报告内容",
    img: <img className="w-12 h-12" src={inspectReport} alt="" />,
  },
];

export default observer(function HospitalData() {
  const { state } = useLocation();
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();
  const doctorStore = useDoctorStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { hospitalDataStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const activeKey = useMemo(
    () => hospitalDataStore.activeKey,
    [hospitalDataStore.activeKey]
  );

  const tabList = useMemo(() => {
    const newArr = comTabList.map((item: any) => {
      if (item.value === "medicalRecord") {
        item["content"] = <MedicalRecord />;
      } else {
        item["content"] = <ReportList />;
      }
      return item;
    });
    return [
      ...[
        {
          label: <PatientAvatar src={calendarImg} name={state.patientName} />,
          value: "1",
          disabled: true,
        },
      ],
      ...newArr,
    ];
  }, [state.patientName]);

  // useEffect(() => {
  //   state?.activeKey && hospitalDataStore.setActiveKey(state?.activeKey);
  // }, [hospitalDataStore, state?.activeKey]);

  return (
    <div className="page-padding">
      <Tabs
        tabPaneList={tabList}
        className="sticky"
        isBorder={false}
        activeLineMode="fixed"
        activeKey={activeKey}
        onChange={(key: string) => {
          hospitalDataStore.setActiveKey(key);
          // hospitalDataStore.setEmpty();
        }}
      />
    </div>
  );
});
