import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../../service";
import { FollowUpUserInList } from "../../../models/entity/followUpUser";

export default class FollowUpUserEntityStore {
  list = null as FollowUpUserInList[] | null;
  constructor() {
    makeAutoObservable(this);
  }
  getList = async () => {
    this.list = await serviceClient.followUpUser.getFollowUpUserList();
  };
}
