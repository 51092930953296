/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, Input, Toast } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Button, Typography } from "../../components";
import { DoctorUserUpdate } from "../../models/entity/user";
import { useRouter } from "../../router";
import { serviceClient } from "../../service";
import useDoctorStore from "../../store/doctor";

export default observer(function UserUpdatePhone() {
  const router = useRouter();

  const [form] = Form.useForm();
  const [isAllFilled, setIsAllFilled] = useState(false);
  const { userLogicStore } = useDoctorStore();
  const { userProfile, userInfo } = userLogicStore;

  const userUpdateCallback = useCallback(async () => {
    await userLogicStore.initializeUserInfo();

    Toast.show("修改成功");
    router.navigateTo("user");
  }, [router, userLogicStore]);

  const onUpdateUser = useCallback(async () => {
    const { phoneNumber } = form.getFieldsValue();

    const payload: Partial<DoctorUserUpdate> = {
      phoneNumber,
      depId: userInfo?.depId,
      jobNumber: userInfo?.jobNumber,
      roleId: userInfo?.roleId,
      hospitalId: userInfo?.hospitalId,
      type: userProfile?.type,
      wxPhoneNumber: userProfile?.wxPhoneNumber,
    };

    await serviceClient.user.updateDoctorInfo(payload);
    await userUpdateCallback();
  }, [
    form,
    userInfo?.depId,
    userInfo?.hospitalId,
    userInfo?.jobNumber,
    userInfo?.roleId,
    userProfile?.type,
    userProfile?.wxPhoneNumber,
    userUpdateCallback,
  ]);

  const onValuesChange = useCallback((changedValues: any, allValues: any) => {
    setIsAllFilled(allValues.phoneNumber);
  }, []);

  return (
    <div className="page-padding">
      <Typography type="primary">
        请确保这是您在医院预留的手机号，项目匹配成功后将不允许修改
      </Typography>
      <Form
        css={css`
          --border-top: 0px;
          .adm-list {
            .adm-list-item {
              padding-left: 0;
            }
          }
        `}
        className="mt-5"
        form={form}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="" name="phoneNumber">
          <Input placeholder="请输入" type="number" />
        </Form.Item>
      </Form>

      <Button
        className="mt-8"
        size="large"
        fill="solid"
        color="primary"
        block
        disabled={!isAllFilled}
        onClick={onUpdateUser}
      >
        完成
      </Button>
    </div>
  );
});
