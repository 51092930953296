import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";

import {
  GetHomeFormListParamsType,
  GetHomeFormListPromiseType,
} from "../../models/entity/followUpForm";

class FollowUpFormStore {
  homeFormList = [] as GetHomeFormListPromiseType[];

  constructor() {
    makeAutoObservable(this);
  }

  getHomeFormList = async (params: GetHomeFormListParamsType) => {
    this.homeFormList = await serviceClient.followUpForm.getHomeFormList(
      params
    );
  };
}

export default FollowUpFormStore;
