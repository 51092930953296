/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Popup, SafeArea } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { ArrowIcon, Button, Typography, CheckList } from "../../../components";
import { CheckListValueType } from "../../../components/CheckList";

export default observer(function SelectGender({
  value,
  onChange,
}: {
  value?: number;
  onChange?: (value?: number) => void;
}) {
  const [genderPopupVisible, setGenderPopupVisible] = useState(false);

  const onClosePopup = useCallback(() => {
    setGenderPopupVisible(false);
  }, []);

  const _onChange = useCallback(
    (val: number) => {
      onChange?.(val);
      onClosePopup();
    },
    [onChange, onClosePopup]
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
        onClick={() => setGenderPopupVisible(true)}
      >
        {value !== undefined ? (
          <span
            css={css`
              color: #333333;
            `}
          >
            {value === 0 ? "男" : "女"}
          </span>
        ) : (
          <span
            css={css`
              color: #cccccc;
            `}
          >
            请选择
          </span>
        )}

        <ArrowIcon direction="down"></ArrowIcon>
      </div>

      <Popup visible={genderPopupVisible} onMaskClick={onClosePopup}>
        <div>
          <Typography type="secondary" className="my-3 text-center" block>
            选择性别
          </Typography>

          <CheckList
            options={[
              {
                label: (
                  <span
                    css={css`
                      color: #0052d9;
                    `}
                  >
                    男
                  </span>
                ),
                value: 0,
              },
              {
                label: (
                  <span
                    css={css`
                      color: #0052d9;
                    `}
                  >
                    女
                  </span>
                ),
                value: 1,
              },
            ]}
            onChange={_onChange as (value: CheckListValueType) => void}
            value={value}
            labelTextAlign="center"
          />
          <Button
            size="large"
            block
            css={css`
              span {
                position: relative;
                right: 5px;
              }
            `}
            onClick={onClosePopup}
          >
            取消
          </Button>
        </div>
        <SafeArea position="bottom" />
      </Popup>
    </div>
  );
});
