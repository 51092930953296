import { Department, Hospital } from "../models/entity/hospital";
import { saasHttpInstance } from "../utils/http";

export class SystemServiceClient {
  async getHospitalList(): Promise<Hospital[]> {
    return saasHttpInstance.post(`/system/WxSystem/LoginHospitals`);
  }

  async getDepartmentList(hospitalId: string): Promise<Department[]> {
    return saasHttpInstance.post(
      `/system/WxSystem/LoginCurHospitalDept/${hospitalId}`
    );
  }
}
