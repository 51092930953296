/** @jsxImportSource @emotion/react */
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { css } from "@emotion/react";
import patient from "../../assets/image/patient.png";
import doctor from "../../assets/image/doctor.png";
import arrowRight from "../../assets/icons/arrowRight.svg";
// import FJSLlogo from "../../assets/image/FJSLlogo.png";
import axios from "axios";
import { Button, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import usePatientStore from "../../store/patient";
import { serviceClient } from "../../service";
import { getWxCode, getUrlParam } from "../../utils/wxBinding";

const BindingRole = () => {
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [wxCode, setWxCode] = useState<string>("");
  const { userLogicStore } = usePatientStore();
  const wxInfo = useRef<any>();

  useLayoutEffect(() => {
    wxInfo.current = JSON.parse(localStorage.getItem("wxInfo") ?? "{}");
  }, []);

  useEffect(() => {
    if (!getUrlParam("code")) {
      getWxCode();
    } else {
      setWxCode(getUrlParam("code") ?? "");
    }
  }, []);

  const getOpenId = async (type: 1 | 2) => {
    const params = {
      code: wxCode,
      type,
    };

    try {
      setLoading(true);
      /*     const reqUrl =
        process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxsf/" : "/"; */

      const reqUrl = process.env.REACT_APP_PUBLIC_URL || "/";

      const result = await axios({
        url: `${reqUrl}fj-api/system/weChat/getInfoByCode`,
        method: "post",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data;charset=UTF-8",
        },
      });

      const res = result.data;
      if (res.code !== 200) {
        Toast.show({
          content: "授权失败",
          afterClose: () => {
            userLogicStore.logout();
          },
        });
        return;
      }

      let url = "";
      let info = Object.assign({ wxCode: wxCode }, res?.data);

      switch (res.data.type) {
        case 1:
        case 2:
          url =
            type === 1
              ? `ui/patient-entry/home?token=${res?.data?.token}`
              : `ui/doctor-entry/followUpProject?token=${res?.data?.token}`;

          localStorage.setItem("wxInfo", JSON.stringify(info));
          window.location.replace(url);
          return;
        default: {
          const result1 = await serviceClient.user.getPublicKey(info.token);
          if (!result1) {
            throw new Error();
          }
          info.publicKey = result1;
          localStorage.setItem("wxInfo", JSON.stringify(info));

          // 前往注册
          if (params.type === 1) {
            url = `/patientRegister`;
          } else {
            url = `/doctorLogin`;
          }
          navigateTo(url);
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const roleList = useMemo(() => {
    return [
      {
        imgPath: doctor,
        type: 2,
        name: "我是医生",
      },
      {
        imgPath: patient,
        type: 1,
        name: "我是患者",
      },
    ];
  }, []);

  const selectRole = (type: 1 | 2) => {
    // 授权完成进入绑定页面，有返回授权页，再次进入绑定页时
    if (wxInfo?.current?.token && wxInfo?.current?.type === 0) {
      navigateTo(type === 1 ? `/patientRegister` : `/doctorLogin`);
      return;
    }
    getOpenId(type);
  };

  return (
    <div className="h-full flex-col flex pt-2">
      <div className=" flex flex-col items-center">
        <img
          src={`${process.env.REACT_APP_PUBLIC_URL || "/"}logo.png`}
          css={css`
            max-width: 240px;
            min-height: 64px;
          `}
          alt=""
        />
        <div className=" py-4 text-center">
          <div className=" pb-3">欢迎使用</div>
          <div>{window._settings.appTitle || "随访系统"}</div>
        </div>
      </div>
      <div className=" flex flex-col items-center justify-center flex-none">
        {roleList.map((item) => (
          <div key={item.type} className="text-center py-4">
            <img src={item.imgPath} alt="" />

            <Button
              block
              disabled={loading}
              css={css`
                padding: 11px 68px;
                border: 1px solid #333333;
                border-radius: 4px;
                font-size: 16px;
                position: relative;
                margin-top: 20px;
              `}
              onClick={() => {
                selectRole(item.type as 1 | 2);
              }}
            >
              <span>{item.name}</span>
              <img
                src={arrowRight}
                alt=""
                css={css`
                  position: absolute;
                  right: 10px;
                  top: 16px;
                `}
              />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BindingRole;
