import { useEffect, useMemo } from "react";
import axios from "axios";
import useDoctorStore from "../../store/doctor";

const SsoLogin = () => {
  const { userLogicStore } = useDoctorStore();
  const token = useMemo(() => {
    return new URLSearchParams(window.location.search).get("token");
  }, []);

  const HandleInitSrp = async () => {
    const result = await axios({
      url: "/visit-api/init/srp",
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
    });

    const config = result.data;
    if (
      config.code === 200 &&
      result.status === 200 &&
      config.data?.srpEnable
    ) {
      const backUrl = window.location.origin + window.location.pathname;
      window.location.replace(
        `${config.data?.srpLoginUrl}&clienturl=${backUrl}`
      );
    }
  };

  useEffect(() => {
    userLogicStore.clearStorage();

    if (token) {
      const url = `ui/doctor-entry/followUpProject?token=${token}`;

      window.location.href = url;
    } else {
      HandleInitSrp();
    }
  }, [token, userLogicStore]);

  return <div></div>;
};

export default SsoLogin;
