/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { CommonColorsType } from "../../theme/index";
import { Typography } from "../../components";
import { Divider } from "antd-mobile";

interface KeyValueTxtType {
  className?: string;
  isDivider?: boolean; // 是否显示分割线
  direction?: "horizontal" | "vertical";
  lColor?: keyof CommonColorsType;
  vColor?: keyof CommonColorsType;
  label?: string;
  value: ReactNode;
}

export default React.memo(function KeyValueTxt(props: KeyValueTxtType) {
  const {
    className,
    isDivider = false,
    direction = "vertical",
    lColor = "grayLightColor",
    vColor,
    label = "",
    value,
  } = props;

  return (
    <>
      <div className={className}>
        <Typography type="primary" colorType={lColor}>
          {label}
        </Typography>
        <Typography type="primary" className="font-medium" colorType={vColor}>
          {value}
        </Typography>
      </div>
      {isDivider && <Divider className="mx-4" direction={direction}></Divider>}
    </>
  );
});
