/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Divider } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Button, Typography } from "../../components";
// import { useDoctorRouter } from "../../router/Doctor";
import { useTheme } from "../../theme";
import doctorPhotoImg from "../../assets/icons/doctorPhoto.svg";
// import qrcodeImg from "../../assets/icons/qrcode.svg";
import UserInfoItem from "./components/UserInfoItem";
import useDoctorStore from "../../store/doctor";
import useLogoutDialog from "../../coreUIComponents/User/LogoutDialog";
import HospitalSelect from "../../pagesPatient/User/HospitalSelect";
import { serviceClient } from "../../service";

export default observer(function User() {
  const theme = useTheme();
  // const router = useDoctorRouter();
  const { userLogicStore } = useDoctorStore();
  const { userInfo } = userLogicStore;
  const logoutDialog = useLogoutDialog();
  const [hospitalDialogVisible, setHospitalDialogVisible] = useState(false);

  // const navigateToFollowUpQrCode = useCallback(() => {
  //   router.navigateTo("followUpQrCode");
  // }, [router]);

  // const navigateToUserUpdate = useCallback(() => {
  //   router.navigateTo("userUpdate");
  // }, [router]);

  // const navigateToUserUpdatePhone = useCallback(() => {
  //   router.navigateTo("userUpdatePhone");
  // }, [router]);

  const onSelectHospital = useCallback(
    async (value: string) => {
      if (value !== userInfo?.hospitalId) {
        await serviceClient.user.switchHospital(value);
        await userLogicStore.initializeUserInfo();
      }
    },
    [userInfo?.hospitalId, userLogicStore]
  );

  const logout = useCallback(() => {
    logoutDialog(() => userLogicStore.logout());
  }, [logoutDialog, userLogicStore]);

  return (
    <div
      className="px-4 pt-14 pb-4"
      css={css`
        height: 100%;
        background-color: ${theme.primaryColor};
      `}
    >
      <div
        css={css`
          height: 100%;
          position: relative;
          background-color: ${theme.whiteColor};
          border-radius: 4px;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          className="px-4"
          css={css`
            flex-grow: 1;
            overflow-y: auto;
          `}
        >
          <img
            css={css`
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            `}
            className="w-20 h-20"
            src={
              JSON.parse(localStorage.getItem("wxInfo") || "{}")?.avatarUrl ||
              doctorPhotoImg
            }
            alt=""
          />
          <Typography type="title" className="text-center pt-12 pb-3" block>
            {userInfo?.name}
          </Typography>
          <UserInfoItem
            title="医院"
            onClick={() => setHospitalDialogVisible(true)}
            value={<span>{userInfo?.hospitalName}</span>}
            showArrow
          ></UserInfoItem>
          <UserInfoItem
            title={
              <span>
                科室
                {/* <Divider direction="vertical" className="mx-1" />
                工号 */}
              </span>
            }
            value={
              <span>
                {userInfo?.deptName}
                {/* <Divider direction="vertical" className="mx-1" />
                {userInfo?.jobNumber} */}
              </span>
            }
            // showArrow
            // onClick={navigateToUserUpdate}
          ></UserInfoItem>
          {/* <UserInfoItem
            title="手机号"
            value={userInfo?.phoneNumber}
            showArrow
            onClick={navigateToUserUpdatePhone}
          ></UserInfoItem> */}
          {/* <Button
            className="mt-11"
            block
            onClick={navigateToFollowUpQrCode}
            size="large"
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <span>随访项目二维码</span>
              <img
                src={qrcodeImg}
                alt=""
                className="w-4 h-4 ml-2"
                css={css`
                  vertical-align: middle;
                `}
              />
            </div>
          </Button> */}
        </div>
        <div>
          <Divider
            css={css`
              margin: 0;
            `}
          ></Divider>
          {userLogicStore.saasToken ? (
            <Button
              fill="none"
              color="danger"
              block
              size="large"
              onClick={logout}
            >
              退出账号
            </Button>
          ) : (
            <Button
              fill="none"
              color="primary"
              block
              size="large"
              onClick={logout}
            >
              去登录
            </Button>
          )}
        </div>
      </div>
      {hospitalDialogVisible && (
        <HospitalSelect
          defaultValue={userInfo?.hospitalId ?? ""}
          onSelect={onSelectHospital}
          onVisibleHandle={setHospitalDialogVisible}
        />
      )}
    </div>
  );
});
