import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import { useDoctorRouter } from "../../router/Doctor";
import PatientCard from "../../coreUIComponents/Patient/PatientCard";
import { Patient, historyListType } from "../../models/entity/patient";
import { useDebounce } from "./SearchResult";
import Empty from "../../coreUIComponents/Empty";
import LazyLoading from "../../coreUIComponents/LazyLoading";
import { DotLoading } from "antd-mobile";
import Typography from "../../components/Typography";
// import VirtualizedList from "../../coreUIComponents/VirtualizedList";

export default observer(function PatientList(props: {
  type: "list" | "search";
}) {
  const { type } = props;
  const router = useDoctorRouter();
  const appUserIdentityType = useAppUserIdentityType();
  const doctorStore = useDoctorStore();
  const { patientEntityStore, followUpStore, userLogicStore } = doctorStore;

  const pNum = useRef<number>(1);
  const [loading, setLoading] = useState(false);
  const lazyRef = useRef<any>(null);

  const hasMore = useRef(true);

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const selectedProject = useMemo(
    () => followUpStore.selectedProject,
    [followUpStore.selectedProject]
  );

  const { selected, selectedNode, searchVal, list, allNumberPatient } = useMemo(
    () => patientEntityStore,
    [patientEntityStore]
  );

  // 数组去重并计算重复个数
  const handleArr = useCallback((arr: historyListType[]) => {
    return arr.reduce((total: historyListType[], cur: historyListType) => {
      const hasValue = total?.findIndex((current: any) => {
        return current?.id === cur?.id;
      });
      if (hasValue === -1) {
        cur.count = cur.count || 1;
        total.push(cur);
      } else {
        total[hasValue].count += 1;
      }
      return total.sort((a, b) => b.count - a.count);
    }, []);
  }, []);

  const getSearchHistory = useMemo(() => {
    const localData = localStorage.getItem("patientSearchHistory");
    const historyList = localData ? JSON.parse(localData) : [];
    return handleArr(historyList);
  }, [handleArr]);

  const toPatientDetail = useCallback(
    (e: any, val: Patient) => {
      if (e.target.innerText === "关注" || e.target.innerText === "对话") {
        return;
      }
      router.navigateTo("patientDetail", { state: { id: val.id } });
      if (type === "search") {
        // todo：前端缓存，如果退出当前账户就清空。
        // localStorage.removeItem("patientSearchHistory");
        const addArr = [
          ...getSearchHistory,
          ...[{ id: val.id, key: val.patientName, count: 0 }],
        ];
        const newArr = handleArr(addArr);
        patientEntityStore.setHistoryList(newArr);
        localStorage.setItem("patientSearchHistory", JSON.stringify(newArr));
      }
    },
    [getSearchHistory, handleArr, patientEntityStore, router, type]
  );

  const useDebounceKey = useDebounce(searchVal, 500);

  const getPatientList = useCallback(async () => {
    if (loading || !hasMore.current) return;
    setLoading(true);

    const params: any = {
      empiOrUserid: userProfile?.empiOrUserid || "",
      nodeId: selectedNode?.nodeId || 0,
      projectId: selectedProject?.id,
      searchVal: useDebounceKey,
      status: selected?.value || "-1",
      type: isDoctor ? 2 : 1,
      pnum: pNum.current,
    };
    try {
      const res = await patientEntityStore.getList(params);
      if (pNum.current > 1) {
        patientEntityStore.setList([...list, ...res.rows]);
      } else {
        patientEntityStore.setAllNumberPatient(res.total);
        patientEntityStore.setList(res.rows);
      }
      if (res.total <= pNum.current * 20) {
        hasMore.current = false;
        return;
      }

      pNum.current = pNum.current + 1;
    } finally {
      setLoading(false);
    }
  }, [
    isDoctor,
    list,
    loading,
    patientEntityStore,
    selected?.value,
    selectedNode?.nodeId,
    selectedProject?.id,
    useDebounceKey,
    userProfile?.empiOrUserid,
  ]);

  // 强制更新 初始化
  useEffect(() => {
    hasMore.current = true;
    pNum.current = 1;
    lazyRef.current?.scrollTop();
    selectedProject?.id && getPatientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProject?.id,
    selected?.value,
    selectedNode?.nodeId,
    userProfile?.empiOrUserid,
    useDebounceKey,
    isDoctor,
  ]);

  useEffect(() => {
    patientEntityStore.setHistoryList(getSearchHistory);
  }, [getSearchHistory, patientEntityStore]);

  return (
    <>
      <div className=" h-full overflow-hidden">
        {list?.length > 0 ? (
          <LazyLoading lazy={getPatientList} ref={lazyRef}>
            {list?.map((item: Patient) => {
              return (
                <div
                  className="mt-3"
                  key={item.id}
                  onClick={(e) => toPatientDetail(e, item)}
                >
                  <PatientCard
                    type="list"
                    curItem={item}
                    keyword={searchVal}
                    // handleCallBack={getPatientList}
                  />
                </div>
              );
            })}
            {allNumberPatient &&
              allNumberPatient > 20 &&
              allNumberPatient === list.length && (
                <div className=" text-xs text-center ">
                  <Typography type="primary" colorType={"grayLightColor"}>
                    已经到最底部了
                  </Typography>
                </div>
              )}
            <div></div>
            {loading && (
              <div className=" text-center">
                <DotLoading color="primary" />
              </div>
            )}
          </LazyLoading>
        ) : (
          <Empty title="暂无数据" />
        )}
      </div>
    </>
  );
});
