/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import cx from "classnames";
import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useMemo } from "react";
import { useTheme } from "../../theme";
import { Typography, Button } from "../../components";
import hospitalData from "../../assets/icons/hospitalData.svg";
import patientFolder from "../../assets/icons/patientFolder.svg";
import dialogue from "../../assets/icons/dialogue.svg";
import { StarOutline, StarFill } from "antd-mobile-icons";
import { useDoctorRouter } from "../../router/Doctor";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import { Patient } from "../../models/entity/patient";

export interface listType {
  label: string;
  value: string;
  disabled: boolean;
  icon?: ReactNode;
}

export interface BlockBtnType {
  code: string[];
  fill?: boolean;
  className?: string;
  curItem: Patient;
  // handleCallBack?: () => void;
}

export default observer(function BlockBtn(props: BlockBtnType) {
  const theme = useTheme();
  const router = useDoctorRouter();
  const appUserIdentityType = useAppUserIdentityType();
  const doctorStore = useDoctorStore();
  const { userLogicStore, patientEntityStore } = doctorStore;

  const { list: patientList } = useMemo(
    () => patientEntityStore,
    [patientEntityStore]
  );

  const { code, fill = true, className, curItem } = props;

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  // const serachPatientList = useMemo(
  //   () => patientEntityStore.serachPatientList,
  //   [patientEntityStore.serachPatientList]
  // );

  // 判断是否可以对话
  // const isHas = useMemo(() => {
  //   return (
  //     serachPatientList.find((item) => item.empi === curItem?.empid)?.isHas &&
  //     !!curItem?.talk
  //   );
  // }, [curItem?.empid, curItem?.talk, serachPatientList]);

  const list = useMemo(() => {
    return [
      {
        label: "院内数据",
        value: "HospitalData",
        disabled: false,
        icon: <img className="w-3.5 h-3.5" src={hospitalData} alt="" />,
      },
      {
        label: "患者资料夹",
        value: "PatientFolder",
        disabled: false,
        icon: <img className="w-3.5 h-3.5" src={patientFolder} alt="" />,
      },
      {
        label: "对话",
        value: "Dialogue",
        disabled: !curItem?.talk,
        icon: <img className="w-3.5 h-3.5" src={dialogue} alt="" />,
      },
      {
        label: "关注",
        value: "Attention",
        disabled: false,
        icon:
          curItem?.focus === 1 ? (
            <StarFill fontSize={14} color={`${theme.warningColor}`} />
          ) : (
            <StarOutline fontSize={14} color={`${theme.warningColor}`} />
          ),
      },
    ];
  }, [curItem?.focus, curItem?.talk, theme.warningColor]);

  const data = useMemo(() => {
    return list.filter(
      (item: listType) => code.includes(item.value) && !item.disabled
    );
  }, [code, list]);

  const onHandleClick = useCallback(
    async (e: any, val: listType) => {
      switch (val.value) {
        case "HospitalData":
          router.navigateTo("hospitalData", {
            state: { empid: curItem?.empid, patientName: curItem?.patientName },
          });
          break;
        case "PatientFolder":
          router.navigateTo("patientFolder", {
            state: {
              patientIdO: curItem?.patientId,
              patientId: curItem?.empid,
              projectId: curItem?.projectId,
              patientName: curItem?.patientName,
              isHas: !!curItem?.talk, // 是否可以发送图片给患者
            },
          });
          break;
        case "Dialogue":
          e.preventDefault();
          e.stopPropagation();
          router.navigateTo("messageDetail", {
            state: {
              patientId: curItem?.empid,
              projectId: curItem?.projectId,
              name: curItem?.patientName,
            },
          });
          break;
        case "Attention":
          e.preventDefault();
          e.stopPropagation();

          await patientEntityStore.patientFocus({
            empiOrUserid: userProfile?.empiOrUserid || "",
            type: isDoctor ? 2 : 1,
            patientId: curItem?.id,
            focusType: curItem?.focus === 1 ? 0 : 1,
          });

          const newPatientList = patientList.map((item) => {
            if (item?.id === curItem?.id) {
              return {
                ...item,
                focus: item?.focus === 1 ? 0 : 1,
              };
            } else {
              return item;
            }
          });

          patientEntityStore.setList(newPatientList);

          // handleCallBack && handleCallBack(true);

          break;
      }
    },
    [
      curItem?.empid,
      curItem?.focus,
      curItem?.id,
      curItem?.patientId,
      curItem?.patientName,
      curItem?.projectId,
      curItem?.talk,
      isDoctor,
      patientEntityStore,
      patientList,
      router,
      userProfile?.empiOrUserid,
    ]
  );

  return (
    <div className={cx("flex items-center", className)}>
      {data.map((item: listType, index: number) => {
        return (
          <div
            key={item.value}
            className={`flex items-center rounded-md ${
              index !== data.length - 1 ? "mr-3" : ""
            }`}
            css={css`
              background: ${fill ? theme.borderColor : "none"};
            `}
            onClick={(e) => {
              onHandleClick(e, item);
            }}
          >
            <Button
              size="mini"
              fill="none"
              block
              disabled={item.disabled}
              css={css`
                padding: 7px 8px;
              `}
            >
              <div className="flex items-center">
                {item?.icon}
                <Typography
                  type="mini"
                  colorType="graySecondaryColor"
                  className="ml-1 leading-loose"
                >
                  {item.label}
                </Typography>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
});
