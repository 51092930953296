/** @jsxImportSource @emotion/react */
import { rootStore, StoreContext } from "./store/doctor";
import {
  useRoutes,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import BindingRole from "./pages/BindingRole";
import DoctorLogin from "./pages/DoctorLogin";
import PatientRegister from "./pages/PatientRegister";
import { css } from "@emotion/react";
import ServiceSubscription from "./pages/ServiceSubscription";
import AgreementStatement from "./pages/AgreementStatement";
import { KeepAlive, AliveScope } from "react-activation";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { SafeArea } from "antd-mobile";

interface RouterProps {
  getTile: (value: string) => void;
}

const Router = ({ getTile }: RouterProps) => {
  const location = useLocation();

  const routers = useMemo(() => {
    return [
      {
        path: "",
        element: <Navigate to="bindingRole" />,
      },
      {
        id: "bindingRole",
        path: "bindingRole",
        element: <BindingRole />,
        title: "授权",
      },
      {
        id: "doctorLogin",
        path: "doctorLogin",
        title: "医生端登录",
        element: (
          <KeepAlive id={"DoctorLogin"} name={"DoctorLogin"}>
            <DoctorLogin />
          </KeepAlive>
        ),
      },
      {
        id: "patientRegister",
        path: "patientRegister",
        title: "患者端登录",
        element: (
          <KeepAlive id={"PatientRegister"} name={"PatientRegister"}>
            <PatientRegister />
          </KeepAlive>
        ),
      },
      {
        id: "serviceSubscription",
        path: "serviceSubscription",
        element: <ServiceSubscription />,
        title: "服务订阅",
      },
      {
        id: "agreementStatement",
        path: "agreementStatement",
        element: <AgreementStatement />,
        title: "协议声明",
      },
    ].map((item) => {
      item.path = `/${item.path}`;
      return item;
    });
  }, []);

  const element = useRoutes(routers);

  const pathname = window.location.pathname;

  useEffect(() => {
    const _pathname = location.pathname;

    const routerItem = routers.find((item) => {
      return item.path === _pathname;
    });

    getTile(routerItem?.title ?? "");
  }, [getTile, location, routers]);

  if (
    ["/", "/bindingRole", `/nfmssxsf/`, `/nfmssxsf/bindingRole`].includes(
      pathname
    )
  ) {
    const wxInfo = JSON.parse(localStorage.getItem("wxInfo") ?? "{}");
    if (wxInfo.token && wxInfo.type) {
      let url = "";
      if (wxInfo.type === 1) {
        url = `ui/patient-entry/home?token=${wxInfo?.token}`;
      } else {
        url = `ui/doctor-entry/followUpProject?token=${wxInfo?.token}`;
      }
      window.location.replace(url);
      return null;
    }
  }

  return element;
};

const Layout = () => {
  const [title, setTitle] = useState("");

  const getTile = useCallback((value: string) => {
    setTitle(value);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        css={css`
          height: 100vh;
        `}
      >
        <main
          css={css`
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
          `}
        >
          <Router getTile={getTile} />
        </main>
        <SafeArea position="bottom" />
      </div>
    </Fragment>
  );
};
function AppDoctor() {
  const base = process.env.REACT_APP_PUBLIC_URL || "/";

  return (
    <BrowserRouter basename={base}>
      <StoreContext.Provider value={rootStore}>
        <AliveScope>
          <Layout />
        </AliveScope>
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default AppDoctor;
