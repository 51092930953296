/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren, useMemo } from "react";
import { css } from "@emotion/react";
import { commonColors, CommonColorsType } from "../theme/index";

export interface TypographyProps extends PropsWithChildren {
  type?: "title-bold" | "title" | "primary" | "secondary" | "mini";
  colorType?: keyof CommonColorsType;
  truncation?: boolean;
  block?: boolean;
  className?: string;
}

export default React.memo(function Typography(props: TypographyProps) {
  const {
    type = "primary",
    children,
    className,
    colorType,
    block = false,
    truncation,
  } = props;

  const colorInfo: CommonColorsType = commonColors;

  const typeStyle = useMemo(() => {
    let _style;

    const primaryStyle = css`
      color: ${colorInfo.grayMainColor};
      font-size: 14px;
      line-height: 22px;
    `;

    switch (type) {
      case "title-bold":
        _style = css`
          color: ${colorInfo.grayMainColor};
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
        `;
        break;
      case "title":
        _style = css`
          color: ${colorInfo.grayMainColor};
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
        `;
        break;
      case "primary":
        _style = primaryStyle;
        break;
      case "secondary":
        _style = css`
          color: ${colorInfo.graySecondaryColor};
          font-size: 14px;
          line-height: 22px;
        `;
        break;
      case "mini":
        _style = css`
          color: ${colorInfo.grayLightColor};
          font-size: 12px;
          line-height: 16px;
        `;
        break;
      default:
        _style = primaryStyle;
        break;
    }

    return _style;
  }, [
    colorInfo.grayMainColor,
    colorInfo.graySecondaryColor,
    colorInfo.grayLightColor,
    type,
  ]);

  const _className = useMemo(
    () => [
      typeStyle,
      colorType
        ? css`
            color: ${colorInfo[colorType]};
          `
        : "",
      truncation
        ? css`
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `
        : "",
    ],
    [colorInfo, colorType, truncation, typeStyle]
  );

  const Tag = useMemo(() => (block ? "div" : "span"), [block]);

  return (
    <Tag css={_className} className={className}>
      {children}
    </Tag>
  );
});
