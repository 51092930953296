/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import Header from "../Home/Header";
import OpcProjectSelect from "../../coreUIComponents/FollowUp/OpcProjectSelect";
import { Divider } from "antd-mobile";
import Node from "./components/Node";
import { css } from "@emotion/react";

export default observer(function FollowUpOutpatient() {
  return (
    <>
      <div className="page-padding flex justify-between items-center">
        <Header />
        <OpcProjectSelect keyId="opcProject" />
      </div>

      <Divider
        css={css`
          margin: 0;
        `}
      />

      <Node />
    </>
  );
});
