/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import { useTheme } from "../../theme";
import { useAppUserIdentityType } from "../../hooks";
import FormEducateList from "./FormEducateList";
import { Typography } from "../../components";
import { FormOriginType } from "../FormEditor/FormGatherWrapper";
import { HealthInfoType } from "../../models/entity/followUpProject";
import Empty from "../../coreUIComponents/Empty";

interface HealthManageType {
  type: FormOriginType;
  healthInfo?: HealthInfoType;
  patientId?: string; // 患者id
  projectId?: string; // 项目id
}

export default React.memo(function HealthManage(props: HealthManageType) {
  const { type = "FollowUp", healthInfo, patientId, projectId } = props;
  const theme = useTheme();
  const appUserIdentityType = useAppUserIdentityType();

  const identityType = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const iType = useMemo(() => {
    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const boxWrapper = useMemo(() => {
    return css`
      background: ${theme.whiteColor};
      border-radius: 4px;
      border: 1px solid ${theme.borderColor};
      box-shadow: ${iType ? "none" : `${theme.styles.boxShadow}`};
    `;
  }, [iType, theme.borderColor, theme.styles.boxShadow, theme.whiteColor]);

  return (
    <div
      className="h-full p-4"
      css={css`
        background: ${iType ? `${theme.whiteColor}` : `${theme.borderColor}`};
      `}
    >
      {healthInfo?.noWriteList?.length || healthInfo?.writeList?.length ? (
        <>
          {/* 这一块 UI 上只有患者端-随访-健康管理里面才有 */}
          {!identityType && !iType && (
            <Typography type="mini" block className="mb-4">
              计划外的表单您可以随时填写，并可重复填写，当您提交后，项目中的医生即可收到。
            </Typography>
          )}

          {/* 这一块 都有 */}
          {healthInfo?.noWriteList?.length ? (
            <div css={boxWrapper}>
              <FormEducateList
                type={type}
                list={healthInfo?.noWriteList}
                patientId={patientId}
                projectId={projectId}
              />
            </div>
          ) : null}

          {/* 这一块 UI 上有的包括：患者端-随访-健康管理、医生端-患者列表-患者详情-健康管理 */}
          {!iType && (healthInfo as any)?.writeList?.length > 0 && (
            <div
              className="mt-4 py-4"
              css={css`
                border-top: 1px dashed ${theme.grayWeakColor};
              `}
            >
              <Typography
                block
                className="pl-1"
                css={css`
                  line-height: 16px;
                  border-left: 2px solid ${theme.primaryColor};
                `}
              >
                已提交表单 {healthInfo?.writeList?.length}
              </Typography>
              {healthInfo?.writeList?.map((item, index) => {
                return (
                  <div className="mt-4" key={index}>
                    <div className="flex justify-between items-center mb-4">
                      <Typography>{item.timeFormat}</Typography>
                      <Typography type="mini">
                        提交表单 {item.forms?.length}
                      </Typography>
                    </div>
                    <div css={boxWrapper}>
                      <FormEducateList
                        type={type}
                        list={item.forms}
                        patientId={patientId}
                        projectId={projectId}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </div>
  );
});
