/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import {
  SearchBar as AntdSearchBar,
  SearchBarProps as AntdSearchBarProps,
} from "antd-mobile";
import { css } from "@emotion/react";
import { useTheme } from "../theme";

export interface SearchBarProps extends AntdSearchBarProps {}

export default React.memo(function SearchBar(props: SearchBarProps) {
  const theme = useTheme();

  const _className = useMemo(() => {
    return [
      css`
        .adm-search-bar-input-box {
          border: 1px solid #ebedf0;
          border-radius: 4px;
          input {
            font-size: 14px;
            line-height: 22px !important;
          }
        }
        --placeholder-color: #cccccc;
        --background: transparent,
        --border-radius: 4px,
      `,
      css`
        &.adm-search-bar-active {
          .adm-search-bar-input-box {
            border: 1px solid ${theme.primaryColor};
          }
        }
      `,
    ];
  }, [theme.primaryColor]);

  return <AntdSearchBar css={_className} {...props} />;
});
