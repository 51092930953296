/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import Header from "../Home/Header";
import OpcProjectSelect from "../../coreUIComponents/FollowUp/OpcProjectSelect";
import { Divider } from "antd-mobile";
import Form from "./components/Form";
import { css } from "@emotion/react";

export default observer(function FollowUpOutForm() {
  return (
    <>
      <div className="page-padding flex justify-between items-center">
        <Header />
        <OpcProjectSelect keyId="formProject" />
      </div>

      <Divider
        css={css`
          margin: 0;
        `}
      />

      <Form />
    </>
  );
});
