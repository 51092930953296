/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useTheme } from "../../theme";
import { Typography } from "../../components";
import BlockBtn from "./BlockBtn";
import KeyValueTxt from "./KeyValueTxt";
import PatientAvatar from "./PatientAvatar";
import calendarImg from "../../assets/icons/doctorPhoto.svg";
import { Divider, Ellipsis } from "antd-mobile";
import { textHightLight } from "../FollowUp/FormBrowse/SearchItem";
import { Patient } from "../../models/entity/patient";
import {
  statusList,
  statusListType,
} from "../../models/entity/followUpProject";

interface PatientCardType {
  type?: "box" | "list" | "none";
  curItem: Patient;
  keyword?: string;
  className?: string;
  // handleCallBack?: () => void;
}

export default observer(function PatientCard(props: PatientCardType) {
  const theme = useTheme();
  const {
    type = "none",
    curItem,
    keyword = "",
    className,
    // handleCallBack,
  } = props;

  const isDivider = useMemo(() => {
    return type === "none";
  }, [type]);

  const currentStatus = useMemo(() => {
    return statusList.find((item) => item.value === String(curItem?.status));
  }, [curItem?.status]);

  const outerLayer = useMemo(() => {
    return [
      css`
        background: ${theme.whiteColor};
      `,
      type === "box" &&
        css`
          border-radius: 4px;
        `,
      type === "list" &&
        css`
          border-bottom: 1px solid ${theme.borderColor};
        `,
    ];
  }, [theme.borderColor, theme.whiteColor, type]);

  const comStyle = useMemo(() => {
    return [
      css`
        display: flex;
        align-items: center;
      `,
    ];
  }, []);

  const wrapperAvatar = useMemo(() => {
    return [
      comStyle,
      type !== "none" &&
        css`
          justify-content: space-between;
        `,
    ];
  }, [comStyle, type]);

  return (
    <div css={outerLayer} className={className}>
      {/* 头像这一列 */}
      <div
        css={wrapperAvatar}
        className={`${type === "list" ? "mb-4" : "mb-3"}`}
      >
        <PatientAvatar
          name={
            <div
              className="inline-block"
              dangerouslySetInnerHTML={{
                __html: textHightLight(
                  `${curItem?.patientName || "--"}`,
                  keyword,
                  `${theme.primaryColor}`
                ),
              }}
            ></div>
          }
          src={calendarImg}
          size={type === "box" ? 54 : 32}
          fill={type === "none"}
        >
          <>
            {type === "list" && (
              <>
                <Divider className="mx-2" direction="vertical"></Divider>
                <Typography type="secondary">
                  <div
                    className="inline-block"
                    dangerouslySetInnerHTML={{
                      __html: textHightLight(
                        `${curItem?.patientId || "--"}`,
                        keyword,
                        `${theme.primaryColor}`
                      ),
                    }}
                  ></div>
                </Typography>
              </>
            )}

            {type === "box" && (
              <div className="mt-2">
                <Typography type="mini">
                  {curItem?.gender === "1"
                    ? "男"
                    : curItem?.gender === "2"
                    ? "女"
                    : "其他"}
                </Typography>
                <Divider className="mx-2" direction="vertical"></Divider>
                <Typography type="mini">
                  {curItem?.age ? `${curItem?.age}岁` : "--"}
                </Typography>
              </div>
            )}
          </>
        </PatientAvatar>

        {type === "none" ? (
          <>
            <Divider className="mx-4" direction="vertical"></Divider>
            <KeyValueTxt
              isDivider={true}
              label="性别："
              value={
                curItem?.gender === "1"
                  ? "男"
                  : curItem?.gender === "2"
                  ? "女"
                  : "其他"
              }
            />
            <KeyValueTxt
              label="年龄："
              value={curItem?.age ? `${curItem?.age}岁` : "--"}
            />
          </>
        ) : (
          <BlockBtn
            curItem={curItem}
            // handleCallBack={handleCallBack}
            code={type === "box" ? ["Attention"] : ["Dialogue", "Attention"]}
            fill={false}
          />
        )}
      </div>

      {/* 患者信息 */}
      {type !== "list" && (
        <div css={comStyle} className="mb-3">
          <KeyValueTxt
            isDivider={isDivider}
            label="患者号："
            value={curItem?.patientId || "--"}
          />
          {/* <KeyValueTxt
            isDivider={isDivider}
            label="状态："
            value={curItem?.statusName}
          /> */}
          {type === "none" && (
            <KeyValueTxt
              vColor="primaryColor"
              label="进度："
              value={`${curItem?.percentage || 0}%`}
            />
          )}
        </div>
      )}

      {type === "box" && (
        <div css={comStyle} className="mb-3">
          <KeyValueTxt
            className="flex"
            label="随访项目："
            value={
              <Ellipsis
                direction="end"
                rows={1}
                content={curItem?.projectName || "--"}
              />
            }
          />
        </div>
      )}

      {type !== "none" && (
        <div css={comStyle} className="mb-3">
          {type === "box" && (
            <KeyValueTxt
              vColor="primaryColor"
              label="随访进度："
              value={`${curItem?.percentage || 0}%`}
            />
          )}

          <div css={comStyle} className={`${type === "box" ? "ml-2" : ""}`}>
            <KeyValueTxt
              label="当前状态："
              value={
                <div
                  className="inline-block"
                  dangerouslySetInnerHTML={{
                    __html: textHightLight(
                      `${curItem?.statusName || "--"}`,
                      (currentStatus as statusListType)?.label,
                      `${theme[(currentStatus as statusListType)?.colorType]}`
                    ),
                  }}
                ></div>
              }
            />
          </div>
        </div>
      )}

      {/* 院内数据按钮操作栏 */}
      {type !== "list" && (
        <BlockBtn
          curItem={curItem}
          // handleCallBack={handleCallBack}
          code={
            type === "box"
              ? ["HospitalData", "PatientFolder"]
              : ["HospitalData", "PatientFolder", "Dialogue", "Attention"]
          }
        />
      )}
    </div>
  );
});
