/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import { Card, Typography } from "../../components";
import calendarImg from "../../assets/icons/calendar.svg";
import followUpClinicImg from "../../assets/image/followUpClinic.svg";
import formImg from "../../assets/image/form.svg";
import { useTheme } from "../../theme";
import moment from "moment";
import { observer } from "mobx-react-lite";
import usePatientStore from "../../store/patient";
import { usePatientRouter } from "../../router/Patient";

const ScheduleCard = observer(function (
  props: PropsWithChildren<{
    title: ReactNode;
    imageUrl: string;
    navigatorTo: (title: ReactNode) => void;
  }>
) {
  const { title, imageUrl, navigatorTo, children } = props;
  const theme = useTheme();

  return (
    <Card
      css={css`
        flex: 1;

        &:last-child {
          margin-right: 0;
        }
      `}
      className="mr-2 p-0"
    >
      <div onClick={() => navigatorTo(title)}>
        <div
          css={css`
            position: relative;
            overflow: hidden;
          `}
          className="pl-3 h-12"
        >
          <span>
            <Typography type="title" block className="pt-3">
              {title}
            </Typography>
          </span>
          <img
            css={css`
              position: absolute;
              top: -8px;
              right: -8px;
            `}
            className="w-14 h-14"
            src={imageUrl}
            alt=""
          />
        </div>
        <div
          css={css`
            background-color: ${theme.boxBackgroundColor};
            border-radius: 4px;
          `}
          className="h-20 m-1 p-1.5"
        >
          {children}
        </div>
      </div>
    </Card>
  );
});

export default observer(function FollowUpSchedule() {
  const patientStore = usePatientStore();
  const { homeEntityStore } = patientStore;
  const router = usePatientRouter();

  const homeInfo = useMemo(
    () => homeEntityStore.homeInfo,
    [homeEntityStore.homeInfo]
  );

  const getMonth = useMemo(() => {
    return moment().format("M");
  }, []);

  const getDay = useMemo(() => {
    return moment().format("D");
  }, []);

  const getWeek = useMemo(() => {
    const week = moment().day();
    switch (week) {
      case 1:
        return "星期一";
      case 2:
        return "星期二";
      case 3:
        return "星期三";
      case 4:
        return "星期四";
      case 5:
        return "星期五";
      case 6:
        return "星期六";
      case 0:
        return "星期日";
    }
  }, []);

  const navigatorTo = useCallback(
    (title: ReactNode) => {
      if (title === "门诊随访") {
        if (!homeInfo?.formatFollowTime) return;
        router.navigateTo("followUpOutpatient");
      } else {
        if (!homeInfo?.writeFormCount) return;
        router.navigateTo("followUpForm");
      }
    },
    [homeInfo?.formatFollowTime, homeInfo?.writeFormCount, router]
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
        className="mt-5 mb-4"
      >
        <Typography type="title" colorType="graySecondaryColor">
          日程安排
        </Typography>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <img src={calendarImg} alt="" />
          &nbsp;
          <Typography type="secondary">
            {getMonth}月{getDay}日
          </Typography>
          &nbsp;
          <Typography type="secondary">{getWeek}</Typography>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <ScheduleCard
          imageUrl={followUpClinicImg}
          title="门诊随访"
          navigatorTo={navigatorTo}
        >
          {homeInfo?.formatFollowTime ? (
            <>
              <Typography type="primary" block={true} className="mb-2">
                {homeInfo?.department}
              </Typography>
              <Typography type="mini" colorType="primaryColor">
                {homeInfo?.formatFollowTime}
              </Typography>
            </>
          ) : (
            <div className="h-full flex justify-center items-center">
              <Typography colorType="grayWeakColor">暂无门诊随访</Typography>
            </div>
          )}
        </ScheduleCard>
        <ScheduleCard imageUrl={formImg} title="表单" navigatorTo={navigatorTo}>
          {homeInfo?.writeFormCount ? (
            <>
              <Typography type="primary" block={true} className="mb-2">
                {homeInfo?.writeFormCount}份表单待填写
              </Typography>
              <Typography type="mini" colorType="primaryColor">
                {homeInfo?.formatTime}
              </Typography>
            </>
          ) : (
            <div className="h-full flex justify-center items-center">
              <Typography block colorType="grayWeakColor">
                暂无待填写表单
              </Typography>
            </div>
          )}
        </ScheduleCard>
      </div>
    </div>
  );
});
