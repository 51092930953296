/** @jsxImportSource @emotion/react */
import React, { ReactNode, useMemo } from "react";
import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from "antd-mobile";
import { css } from "@emotion/react";
import { useTheme } from "../theme";

interface ITabPane {
  label: string | ReactNode;
  value: string;
  disabled?: boolean;
  content?: ReactNode | null;
}

export interface TabsProps extends AntdTabsProps {
  className?: string;
  isBorder?: boolean;
  tabPaneList: ITabPane[];
  sizeType?: string;
}

export default React.memo(function Tabs(props: TabsProps) {
  const { className, tabPaneList, isBorder = true, ...rest } = props;
  const theme = useTheme();

  const _className = useMemo(() => {
    return [
      css`
        .adm-tabs-header {
          background: ${theme.whiteColor};
          border-bottom: 1px solid ${isBorder ? "#ebedf0" : "transparent"};
        }
        --title-font-size: ${rest?.sizeType === "title" ? "16px" : "14px"};
        --content-padding: 0;
        --active-line-color: ${theme.primaryColor};
        --active-title-color: ${theme.primaryColor};
      `,
      css`
        .adm-tabs-tab-wrapper {
          padding: 0 24px 0 0;
        }
        .adm-tabs-tab-wrapper:last-child {
          padding-right: 0;
        }
      `,
      css`
        .adm-tabs-tab-active {
          font-weight: 500;
        }
        .adm-tabs-tab-disabled {
          opacity: 1;
        }
      `,
      rest?.activeLineMode === "auto" &&
        css`
          .adm-tabs-tab {
            padding: 10px 0 16px;
          }
        `,
    ];
  }, [
    isBorder,
    rest?.activeLineMode,
    rest?.sizeType,
    theme.primaryColor,
    theme.whiteColor,
  ]);

  return (
    <div className={className}>
      <AntdTabs {...rest} css={_className}>
        {tabPaneList.map((item) => (
          <AntdTabs.Tab
            destroyOnClose={true}
            title={item.label}
            key={item.value}
            disabled={item.disabled}
          >
            {item.content}
          </AntdTabs.Tab>
        ))}
      </AntdTabs>
    </div>
  );
});
