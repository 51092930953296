/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dialog } from "antd-mobile";
import { useCallback } from "react";
import { Typography } from "../../components";

export default function useLogoutDialog() {
  const _logout = useCallback((onConfirm: () => void) => {
    Dialog.confirm({
      content: (
        <Typography
          type="title-bold"
          block
          css={css`
            text-align: center;
          `}
        >
          确定要退出账号吗？
        </Typography>
      ),
      onConfirm,
      cancelText: <Typography type="title">取消</Typography>,
      confirmText: (
        <Typography type="title" colorType="dangerColor">
          退出
        </Typography>
      ),
    });
  }, []);

  return _logout;
}
