import React from "react";
import { configure } from "mobx";
import PatientEntityStore from "./entity/patient";
import UserLogicStore from "../core/user";
import AppLogicStore from "../core/app";
import HospitalEntityStore from "../core/hospital";
import UserSendMesStore from "../core/instantMessage";
import FollowUpStore from "../core/followUp";
import FollowUpProjectStore from "./entity/followUpProject";
import HospitalDataStore from "../core/hospitalData";

configure({
  enforceActions: "never",
});

class RootStore {
  public userLogicStore: UserLogicStore;
  public appLogicStore: AppLogicStore;

  public userSendMesStore: UserSendMesStore;
  public patientEntityStore: PatientEntityStore;
  public hospitalEntityStore: HospitalEntityStore;
  public followUpStore: FollowUpStore;
  public followUpProjectStore: FollowUpProjectStore;
  public hospitalDataStore: HospitalDataStore;

  constructor() {
    this.userLogicStore = new UserLogicStore();
    this.appLogicStore = new AppLogicStore();

    this.userSendMesStore = new UserSendMesStore();
    this.patientEntityStore = new PatientEntityStore();
    this.hospitalEntityStore = new HospitalEntityStore();
    this.followUpStore = new FollowUpStore();
    this.followUpProjectStore = new FollowUpProjectStore();
    this.hospitalDataStore = new HospitalDataStore();
  }
}

const rootStore = new RootStore();
const StoreContext = React.createContext(rootStore);
const useDoctorStore = () => React.useContext(StoreContext);

export { rootStore, StoreContext };
export default useDoctorStore;
