/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import PatientCard from "../../coreUIComponents/Patient/PatientCard";
import FollowupIndex from "../../coreUIComponents/FollowUp";
import { useTheme } from "../../theme";

export default observer(function PatientDetail() {
  const { state } = useLocation();
  const { id } = state || {};
  const theme = useTheme();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { followUpStore, appLogicStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const info = useMemo(() => {
    return followUpStore.info;
  }, [followUpStore.info]);

  const healthInfo = useMemo(() => {
    return followUpStore.healthInfo;
  }, [followUpStore.healthInfo]);

  const activeKey = useMemo(
    () => followUpStore.activeKey,
    [followUpStore.activeKey]
  );

  const getPatientInfo = useCallback(() => {
    id && followUpStore.patientInfo(id, "2");
    id && followUpStore.healthManagement(id);
  }, [id, followUpStore]);

  useEffect(() => {
    getPatientInfo();
    appLogicStore.setTitle("患者详情");
  }, [appLogicStore, getPatientInfo]);

  return (
    <div
      className="h-full flex flex-col"
      css={css`
        background: ${theme.borderColor};
      `}
    >
      <div
        className="page-padding pb-2"
        css={css`
          background: ${theme.whiteColor};
        `}
      >
        <PatientCard
          curItem={info}
          // handleCallBack={getPatientInfo}
        />
      </div>

      <FollowupIndex
        type="FollowUpPatient"
        activeKey={activeKey}
        healthType="HealthManagePatient"
        nodes={info?.nodes}
        healthInfo={healthInfo}
        patientId={id}
        projectId={info?.projectId}
      />
    </div>
  );
});
