/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import cx from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import { useTheme } from "../../../theme";
import { Typography } from "../../../components";
import { Input, Dialog } from "antd-mobile";
import { EditSOutline } from "antd-mobile-icons";
import { textHightLight } from "../../../coreUIComponents/FollowUp/FormBrowse/SearchItem";
import { FilesListType } from "../../../models/entity/patient";
import ImageViewers from "../../../coreUIComponents/ImageViewers";
import {
  PdfViewers,
  VideoViewers,
} from "../../../coreUIComponents/FilePreview";
import pdfImg from "../../../assets/image/pdf.png";

interface PatientFileItemType {
  data: FilesListType;
  keyword?: string;
  className?: string;
  confirm?: (id: string, name: string) => void;
}

export const getFileType = (fileName: string) => {
  const extension = fileName.split(".")?.pop?.()?.toLowerCase();

  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
      return "img";
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
      return "doc";
    case "mp4":
    case "mkv":
    case "avi":
    case "mov":
    case "wmv":
      return "video";
    case "mp3":
    case "wav":
    case "ogg":
      return "audio";
    case "txt":
      return "text";
    default:
      return "Unknown File Type";
  }
};

export default React.memo(function PatientFileItem(props: PatientFileItemType) {
  const theme = useTheme();
  const { data, keyword = "", className, confirm } = props;

  const InputRef = useRef(null);

  const fileName = useMemo(() => {
    return data?.fileName?.substring(0, data?.fileName?.lastIndexOf("."));
  }, [data.fileName]);

  const fileFormat = useMemo(() => {
    return data?.fileName?.substring(data?.fileName?.lastIndexOf(".") + 1);
  }, [data.fileName]);

  const changeName = useMemo(() => {
    if (InputRef.current) {
      return (InputRef.current as any).nativeElement.value;
    } else {
      return fileName;
    }
  }, [fileName]);

  /**
   * 解决：todo：Dialog.confirm配合Input组件使用时，无法正常绑定
   * 1.使用声明式的语法可以保持 App 和 Dialog 的父子组件关系，从而避免了这种问题
   * 2.对 Input 部分的逻辑独立封装一个组件出来，这样就不会出现“数据和状态在 App 中，Input 组件本身在 Dialog 中”这种割裂的情况了
   * 3. 使用useRef
   */
  const handleConfirm = useCallback(() => {
    if (InputRef.current) {
      const name =
        (InputRef.current as any).nativeElement.value + "." + fileFormat;
      confirm && confirm(data.id, name);
    }
  }, [confirm, data.id, fileFormat]);

  const IS_IMG = useMemo(() => getFileType(data.url) === "img", [data.url]);
  const IS_PDF = useMemo(() => getFileType(data.url) === "pdf", [data.url]);
  const IS_VIDEO = useMemo(() => getFileType(data.url) === "video", [data.url]);

  return (
    <div
      className={cx("flex justify-between items-center relative", className)}
    >
      <div className="flex items-center">
        {IS_IMG && (
          <ImageViewers
            className="w-12 h-12"
            srcUrl={`${process.env.REACT_APP_PUBLIC_URL || "/"}
api${window._settings.hospitalCode ? "/" + window._settings.hospitalCode : ""}${
              data.url
            }`}
          />
        )}
        {IS_PDF && (
          <PdfViewers
            srcUrl={`${process.env.REACT_APP_PUBLIC_URL || "/"}
          api${
            window._settings.hospitalCode
              ? "/" + window._settings.hospitalCode
              : ""
          }${data.url}`}
          >
            <img src={pdfImg} alt="pdf" className="w-12 h-12" />
          </PdfViewers>
        )}
        {IS_VIDEO && (
          <VideoViewers
            controls={false}
            showVideo={false}
            x5-playsinline={false} //安卓需要设置的属性
            playsInline={false} //ios需要设置的属性
            webkit-playsinline={false}
            className="w-12 h-12"
            srcUrl={`${process.env.REACT_APP_PUBLIC_URL || "/"}
api${window._settings.hospitalCode ? "/" + window._settings.hospitalCode : ""}${
              data.url
            }`}
          />
        )}
        <div className="ml-3">
          <Typography type="title" block className="mb-2 font-normal">
            <div
              className="w-48 max-w-3xl truncate"
              dangerouslySetInnerHTML={{
                __html: textHightLight(
                  `${data.fileName}`,
                  keyword,
                  `${theme.primaryColor}`
                ),
              }}
            ></div>
          </Typography>
          <Typography type="mini" colorType="graySecondaryColor">
            {data.time}
          </Typography>
        </div>
      </div>
      <EditSOutline
        className="relative z-20"
        onClick={() => {
          Dialog.confirm({
            title: "重命名",
            content: (
              <div className="flex flex-col items-center">
                <ImageViewers
                  className="w-28 h-28"
                  srcUrl={`${process.env.REACT_APP_PUBLIC_URL || "/"}
                  api${
                    window._settings.hospitalCode
                      ? "/" + window._settings.hospitalCode
                      : ""
                  }${data.url}`}
                />
                <div className="flex items-center mt-2">
                  <Input
                    ref={InputRef}
                    placeholder="请输入内容"
                    defaultValue={changeName}
                    css={css`
                      background: #f3f3f3;
                    `}
                    className="py-2 px-4 rounded-md"
                  />
                  <Typography>.{fileFormat}</Typography>
                </div>
              </div>
            ),
            onConfirm: handleConfirm,
            cancelText: <Typography type="title">取消</Typography>,
            confirmText: (
              <Typography type="title" colorType="primaryColor">
                确定
              </Typography>
            ),
          });
        }}
      />
    </div>
  );
});
