import React from "react";
import { configure } from "mobx";
import UserLogicStore from "../core/user";
import FollowUpUserEntityStore from "./entity/followUpUser";
import AppLogicStore from "../core/app";
import HospitalEntityStore from "../core/hospital";
import UserSendMesStore from "../core/instantMessage";
import FollowUpStore from "../core/followUp";
import HospitalDataStore from "../core/hospitalData";
import HomeEntityStore from "./entity/home";
import FollowUpOutpatientStore from "../core/followUpOutpatient";
import FollowUpFormStore from "../core/followUpForm";
import FollowUpProjectAllStore from "../core/followUpProjectAll";

configure({
  enforceActions: "never",
});

class RootStore {
  public userLogicStore: UserLogicStore;
  public appLogicStore: AppLogicStore;

  public userSendMesStore: UserSendMesStore;
  public followUpUserEntityStore: FollowUpUserEntityStore;
  public hospitalEntityStore: HospitalEntityStore;
  public followUpStore: FollowUpStore;
  public hospitalDataStore: HospitalDataStore;
  public homeEntityStore: HomeEntityStore;
  public followUpOutpatientStore: FollowUpOutpatientStore;
  public followUpFormStore: FollowUpFormStore;
  public followUpProjectAllStore: FollowUpProjectAllStore;

  constructor() {
    this.userLogicStore = new UserLogicStore();
    this.appLogicStore = new AppLogicStore();

    this.userSendMesStore = new UserSendMesStore();
    this.followUpUserEntityStore = new FollowUpUserEntityStore();
    this.hospitalEntityStore = new HospitalEntityStore();
    this.followUpStore = new FollowUpStore();
    this.hospitalDataStore = new HospitalDataStore();
    this.homeEntityStore = new HomeEntityStore();
    this.followUpOutpatientStore = new FollowUpOutpatientStore();
    this.followUpFormStore = new FollowUpFormStore();
    this.followUpProjectAllStore = new FollowUpProjectAllStore();
  }
}

const rootStore = new RootStore();
const StoreContext = React.createContext(rootStore);
const usePatientStore = () => React.useContext(StoreContext);

export { rootStore, StoreContext };
export default usePatientStore;
