import { hospitalHttpInstance } from "../utils/http";
import {
  GetOpcProjectListParamsType,
  GetOpcProjectListPromiseType,
  GetHomeNodeListParamsType,
  GetHomeNodeListPromiseType,
} from "../models/entity/followUpOutpatient";

export class FollowUpOutpatientServiceClient {
  async getOpcProjectList(
    params: GetOpcProjectListParamsType
  ): Promise<GetOpcProjectListPromiseType[]> {
    return hospitalHttpInstance.get("/mini/project/opcProject", { params });
  }

  async getHomeNodeList(
    params: GetHomeNodeListParamsType
  ): Promise<GetHomeNodeListPromiseType[]> {
    return hospitalHttpInstance.get("/mini/project/homeNode", { params });
  }
}
