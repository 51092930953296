/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Popup } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ArrowIcon, Button, CheckList, Typography } from "../../../components";
import { CheckListItem } from "../../../components/CheckList";
import useDoctorStore from "../../../store/doctor";

export default observer(function DepartmentSelect({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const { hospitalEntityStore, userLogicStore } = useDoctorStore();
  const { userInfo } = userLogicStore;
  const { departmentList } = hospitalEntityStore;

  let [popopVisible, setPopopVisible] = useState(false);
  let [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const selectedDepartment = useMemo(() => {
    return departmentList?.find((item) => selectedValue === item.deptId);
  }, [departmentList, selectedValue]);

  const onConfirm = useCallback(() => {
    console.log(selectedValue);
    onChange?.(selectedValue ?? "");
    setPopopVisible(false);
  }, [onChange, selectedValue]);

  useEffect(() => {
    if (userInfo?.hospitalId) {
      hospitalEntityStore.getDepartmentList(userInfo?.hospitalId);
    }
  }, [hospitalEntityStore, userInfo?.hospitalId]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
        onClick={() => setPopopVisible(true)}
      >
        {value ? (
          <span
            css={css`
              color: #333333;
            `}
          >
            {selectedDepartment?.deptName}
          </span>
        ) : (
          <span
            css={css`
              color: #cccccc;
            `}
          >
            请选择
          </span>
        )}

        <ArrowIcon direction="right"></ArrowIcon>
      </div>

      <Popup
        visible={popopVisible}
        onMaskClick={() => {
          setPopopVisible(false);
        }}
        bodyStyle={{ height: "100vh" }}
      >
        <div className="px-4">
          <div
            className="py-3"
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Button
              fill="none"
              size="mini"
              onClick={() => {
                setPopopVisible(false);
              }}
            >
              取消
            </Button>
            <Typography type="title">选择科室</Typography>
            <Button
              fill="solid"
              color="primary"
              size="mini"
              onClick={onConfirm}
            >
              确定
            </Button>
          </div>
          <CheckList value={selectedValue} onChange={setSelectedValue as any}>
            {hospitalEntityStore.departmentList?.map((item) => (
              <CheckListItem key={item.deptId} value={item.deptId}>
                {item.deptName}
              </CheckListItem>
            ))}
          </CheckList>
        </div>
      </Popup>
    </Fragment>
  );
});
