/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { serviceClient } from "../../../service";

export default React.memo(function PreachAndTeach({ id }: { id: string }) {
  const [educationInfo, setEducationInfo] = useState<string>("");

  const getContent = useCallback(async () => {
    const res = await serviceClient.followUpProject.education(id);
    setEducationInfo(res?.educationContent || "");
  }, [id]);

  const unescapehtml = useCallback((html: string) => {
    html = html === null ? "" : html;
    return html
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quto;/g, "=")
      .replace(/&#39;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/&#47;/g, "\\");
  }, []);

  const htmlEle = useMemo(() => {
    return unescapehtml(educationInfo);
  }, [educationInfo, unescapehtml]);

  useEffect(() => {
    id && getContent();
  }, [getContent, id]);

  return (
    <div className="page-padding">
      <div
        dangerouslySetInnerHTML={{ __html: unescapehtml(htmlEle) }}
        css={css`
          video,
          audio {
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }

          p,
          li {
            line-height: 24px;
            font-size: 14px;
            text-align: justify;
          }
        `}
      ></div>
    </div>
  );
});
