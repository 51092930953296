import React from "react";

type PdfViewersProps = {
  srcUrl: string;
} & React.HTMLAttributes<HTMLAnchorElement>;
function PdfViewers(props: PdfViewersProps) {
  const { srcUrl } = props;
  return (
    <a href={srcUrl} target="_blank" rel="noreferrer" {...props}>
      {props.children ?? "null"}
    </a>
  );
}

export default PdfViewers;
