/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useTheme } from "../../theme";
import { Divider, Ellipsis } from "antd-mobile";
import { ArrowIcon, Card, Tag, Typography } from "../../components";
import usePatientStore from "../../store/patient";
import { HomeProjectListType } from "../../models/entity/home";
import { usePatientRouter } from "../../router/Patient";
import { createSearchParams } from "react-router-dom";

export const FollowUpProjectCard = observer(function (props: {
  list: HomeProjectListType[];
}) {
  const { list } = props;
  const theme = useTheme();
  const router = usePatientRouter();

  const navigateToFollowUp = (item: HomeProjectListType) => {
    router.navigateTo({
      id: "followUp",
      search: `?${createSearchParams({
        selectedProjectId: item.projectId,
      })}`,
    });
  };

  return (
    <>
      {list.map((item: HomeProjectListType, index: number) => {
        return (
          <Card key={index} className="mb-4">
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
              className="mb-4"
              onClick={() => navigateToFollowUp(item)}
            >
              <Typography type="title" truncation>
                {item.projectName}
              </Typography>
              <ArrowIcon direction="right">
                <Typography>
                  {item?.nodeStatus && (
                    <Tag color={item?.nodeStatus === 1 ? "success" : "default"}>
                      {item?.nodeStatus === 1 ? "进行中" : "已结束"}
                    </Tag>
                  )}
                  {!item?.nodeStatus && (
                    <Tag color="success">
                      {item.status === 1 ? "进行中" : "已完成"}
                    </Tag>
                  )}
                </Typography>
              </ArrowIcon>
            </div>
            <div>
              <Ellipsis
                direction="end"
                rows={2}
                content={item.remarks || ""}
                expandText="全部"
                collapseText="收起"
                css={css`
                  color: ${theme.graySecondaryColor};
                  word-break: break-word;
                `}
                className={`${item.remarks ? "mb-2" : "mb-0"}`}
              />

              <Typography type="secondary">
                加入时间：{item.joinDate}
              </Typography>
            </div>
            <Divider></Divider>
            <div
              className="p-2 mb-2"
              css={css`
                background-color: ${theme.boxBackgroundColor};
                border-radius: 4px;
              `}
            >
              <Typography type="secondary">门诊随访：</Typography>
              <Typography type="primary">
                {item.department}·{item.formatTime}
              </Typography>
            </div>
            {item?.writeFormCount !== 0 ||
            item?.writeFormCount === undefined ? (
              <div
                className="p-2"
                css={css`
                  background-color: ${theme.boxBackgroundColor};
                  border-radius: 4px;
                `}
              >
                <Typography type="secondary">待填写表单：</Typography>
                <Typography type="primary">
                  {item.nodeName}·{item.formatTime}
                </Typography>
              </div>
            ) : null}
          </Card>
        );
      })}
    </>
  );
});

export default observer(function FollowUpSchedule() {
  const patientStore = usePatientStore();
  const { homeEntityStore } = patientStore;
  const router = usePatientRouter();

  const homeInfo = useMemo(
    () => homeEntityStore.homeInfo,
    [homeEntityStore.homeInfo]
  );

  const list = useMemo(() => {
    return homeInfo
      ? [
          {
            department: homeInfo?.department,
            formatTime: homeInfo?.formatTime,
            joinDate: homeInfo?.joinDate,
            nodeName: homeInfo?.nodeName,
            projectId: homeInfo?.projectId,
            projectName: homeInfo?.projectName,
            remarks: homeInfo?.remarks,
            status: homeInfo?.status,
            nodeStatus: homeInfo?.status,
            writeFormCount: homeInfo?.writeFormCount,
          },
        ]
      : [];
  }, [homeInfo]);

  const navigateToFollowUpProjectAll = () => {
    router.navigateTo("followUpProjectAll");
  };

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
        className="mt-5 mb-4"
        onClick={navigateToFollowUpProjectAll}
      >
        <Typography type="title" colorType="graySecondaryColor">
          随访项目
        </Typography>
        {homeInfo?.projectId && (
          <ArrowIcon direction="right">
            <Typography type="secondary">查看全部</Typography>
          </ArrowIcon>
        )}
      </div>
      {homeInfo?.projectId ? (
        <FollowUpProjectCard list={list}></FollowUpProjectCard>
      ) : (
        <Typography colorType="grayWeakColor">暂无随访项目</Typography>
      )}
    </div>
  );
});
