/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { Divider } from "antd-mobile";
import { css } from "@emotion/react";
import { Tabs } from "../../components";
import { useEffect, useMemo, useState } from "react";
import usePatientStore from "../../store/patient";
import { FollowUpProjectCard } from "../FollowUp/FollowUpProject";
import Empty from "../../coreUIComponents/Empty";

export const comTabList = [
  {
    label: "全部项目",
    value: "-1",
  },
  {
    label: "进行中",
    value: "1",
  },
  {
    label: "已结束",
    value: "2",
  },
];

export default observer(function FollowUpProjectAll() {
  let [activeKey, setActiveKey] = useState<string>("-1");
  const patientStore = usePatientStore();
  const { userLogicStore, followUpProjectAllStore } = patientStore;
  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  useEffect(() => {
    followUpProjectAllStore.getHomeProjectList({
      empiOrUserid: userProfile?.empiOrUserid || "",
      type: userProfile?.type || 1,
      projectId: "-1",
    });
  }, [followUpProjectAllStore, userProfile?.empiOrUserid, userProfile?.type]);

  return (
    <>
      <div className="page-padding">
        <Tabs
          tabPaneList={comTabList}
          isBorder={false}
          activeLineMode="auto"
          activeKey={activeKey}
          sizeType="title"
          onChange={(key: string) => {
            setActiveKey(key);
          }}
        />
      </div>

      <Divider
        css={css`
          margin: 0;
        `}
      />

      <div className="page-padding">
        {(activeKey === "-1" || activeKey === "1") && (
          <>
            {followUpProjectAllStore.inProgressList?.length > 0 ? (
              <FollowUpProjectCard
                list={followUpProjectAllStore.inProgressList}
              />
            ) : activeKey === "1" ? (
              <Empty title="暂无数据" />
            ) : activeKey === "-1" &&
              followUpProjectAllStore.inProgressList?.length === 0 &&
              followUpProjectAllStore.endProgressList.length === 0 ? (
              <Empty title="暂无数据" />
            ) : null}

            {activeKey === "-1" &&
              followUpProjectAllStore.endProgressList.length > 0 && (
                <Divider
                  style={{
                    borderStyle: "dashed",
                  }}
                >
                  以下是已结束项目
                </Divider>
              )}
          </>
        )}

        {(activeKey === "-1" || activeKey === "2") && (
          <>
            {followUpProjectAllStore.endProgressList?.length > 0 ? (
              <FollowUpProjectCard
                list={followUpProjectAllStore.endProgressList}
              />
            ) : activeKey === "2" ? (
              <Empty title="暂无数据" />
            ) : null}
          </>
        )}
      </div>
    </>
  );
});
