import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import {
  MesHistoryType,
  GetMesSessionIdParamsType,
  SendMessageParamsType,
  PatientMessageListType,
  AllMesListType,
} from "../../models/entity/instantMessage";
import { Patient } from "../../models/entity/patient";
import { UserIdentityType } from "../../models/entity/user";

class UserSendMesStore {
  sessionId = null as (number | string) | null; // 会话id
  messageList = [] as MesHistoryType[]; // 历史消息记录
  patientMessageList = [] as PatientMessageListType[];
  allMesList = [] as AllMesListType[];
  patientInfo = {} as Patient;

  keyword = "" as string;
  visible = false as boolean;

  constructor() {
    makeAutoObservable(this);
  }

  setKeyword = (val: string) => {
    this.keyword = val;
  };

  setVisible = (val: boolean) => {
    this.visible = val;
  };

  setCollapse = () => {
    this.visible = !this.visible;
  };

  setMessageList = (val: MesHistoryType[]) => {
    this.messageList = [...this.messageList, ...val];
  };

  fetchPatientMessageList = async (params: {
    empiOrUserid: string;
    type: UserIdentityType;
  }) => {
    this.patientMessageList =
      await serviceClient.instantMessage.patientMessageList(params);
  };

  fetchPatientMessageStatus = async (params: string[]) => {
    await serviceClient.instantMessage.patientMessageStatus(params);
  };

  fetchPatientInfo = async (params: {
    empiOrUserid: string;
    projectId: string;
    type: UserIdentityType;
  }) => {
    this.patientInfo = await serviceClient.instantMessage.getPatientInfo(
      params
    );
  };

  fetchGetMesSessionList = async () => {
    this.allMesList = await serviceClient.instantMessage.getMesSessionList();
    this.messageList = [];
  };

  fetchDelSessionList = async (sessionId: number | string) => {
    await serviceClient.instantMessage.delSessionList(sessionId);
  };

  // 获取当前的会话id
  fetchMesSessionId = async (
    params: GetMesSessionIdParamsType,
    empiOrUserid: string
  ) => {
    const _sessionId = await serviceClient.instantMessage.getMesSessionId(
      params
    );
    // 拿到会话id 再获取消息记录
    this.fetchMesHistory(_sessionId, empiOrUserid);
    this.sessionId = _sessionId;
  };

  // 获取消息记录
  fetchMesHistory = async (
    sessionId: number | string,
    empiOrUserid: string
  ) => {
    const list = await serviceClient.instantMessage.getMesHistory(sessionId);

    // 更改消息状态
    const ids = list
      .filter((item: any) => item.empiOrUserid !== empiOrUserid)
      .filter((c: any) => c.mesStatus !== 1)
      .map((k: any) => k.id);
    this.fetchUpdateMesRead(ids);

    // 获取医生头像
    const info = this.allMesList.find(
      (item: any) => item.sessionId === sessionId
    );

    this.messageList = list.map((item) => {
      return {
        ...item,
        avatarUrl: info?.avatarUrl || "",
      };
    });
  };

  // 更改消息状态
  fetchUpdateMesRead = async (ids: number[]) => {
    if (ids.length) {
      await serviceClient.instantMessage.updateMesRead(ids);
    }
  };

  // 发送消息
  fetchSendMessgae = async (params: SendMessageParamsType) => {
    await serviceClient.instantMessage.sendMessage(params);
    // 清空消息
    this.setKeyword("");
  };
}

export default UserSendMesStore;
