import React from "react";
import { useTheme } from "../../theme";
import { ArrowIcon } from "../../components";
import { DownFill } from "antd-mobile-icons";

export interface DropdownIconType {
  fill?: boolean; // icon 是否填充
  isSelected?: boolean;
}

export default React.memo(function DropdownIcon(props: DropdownIconType) {
  const { fill = true, isSelected = false } = props;
  const theme = useTheme();

  return (
    <>
      {fill ? (
        <DownFill
          fontSize={12}
          color={
            isSelected ? `${theme.primaryColor}` : `${theme.graySecondaryColor}`
          }
        />
      ) : (
        <ArrowIcon direction={"down"} />
      )}
    </>
  );
});
