import { useRef } from "react";

/*
 * @Author: yangxuefeng yangxuefeng@clinbrain.com
 * @Description:
 */
type VideoViewersProps = {
  srcUrl: string;
  showVideo?: boolean;
  controls?: boolean;
  playsInline?: boolean;
} & React.HTMLAttributes<HTMLVideoElement>;

function VideoViewers(props: VideoViewersProps) {
  const { srcUrl, showVideo = true } = props;
  const videoRef = useRef<any>(null);

  return (
    <video
      controls
      width="100%"
      preload="metadata"
      x5-playsinline={true} //安卓需要设置的属性
      playsInline={true} //ios需要设置的属性
      webkit-playsinline={true}
      {...props}
      style={
        {
          // display: showVideo ? "block" : "none",
          // width: showVideo ? "100%" : "0",
        }
      }
      onClick={() => {
        if (!showVideo) {
          videoRef?.current?.play();
        }
      }}
      ref={videoRef}
    >
      <source src={`${srcUrl}#t=1`} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoViewers;
