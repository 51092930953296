import { useRef, forwardRef, useImperativeHandle, ReactNode } from "react";
import { throttle } from "lodash";

type Props = {
  children: ReactNode;
  lazy: any;
};

const LazyLoading = ({ children, lazy }: Props, ref: any) => {
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    scrollTop,
  }));

  const scrollTop = () => {
    scrollBoxRef.current!.scrollTop = 0;
  };

  const scroll = () => {
    // // 容器可视区高度
    const tableBodyHeight: number = scrollBoxRef.current?.clientHeight
      ? scrollBoxRef.current?.clientHeight
      : 0;

    // 内容高度
    const contentHeight = scrollBoxRef.current?.scrollHeight
      ? scrollBoxRef.current?.scrollHeight
      : 0;
    // 距离顶部的高度
    const toTopHeight = scrollBoxRef.current?.scrollTop
      ? scrollBoxRef.current?.scrollTop
      : 0;

    if (contentHeight - (toTopHeight + tableBodyHeight) <= 20) {
      lazy();
    }
  };

  return (
    <div
      ref={scrollBoxRef}
      onScroll={throttle(scroll, 100)}
      className=" h-full overflow-auto"
    >
      {children}
    </div>
  );
};

export default forwardRef(LazyLoading);
