/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { DoctorRouter } from "../../router/Doctor";

export default observer(function AppMain() {
  return (
    <main
      css={css`
        flex-grow: 1;
        overflow-y: auto;
      `}
    >
      <DoctorRouter />
    </main>
  );
});
