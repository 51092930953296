/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Typography } from "../../../components";
import FormGather from "../../FormEditor/FormGather";
import SubmitSuccessPage from "../../SubmitSuccessPage";
import SecondaryHead from "./SecondaryHead";
import HtmlEleContent from "../PreachAndTeach/Content";
import { useSearchParams } from "react-router-dom";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
import { useDoctorRouter } from "../../../router/Doctor";
import { usePatientRouter } from "../../../router/Patient";
import { FormListType, talkType } from "../../../models/entity/followUpProject";
import moment from "moment";
import { action } from "../../../utils/action";
import { useTheme } from "../../../theme";
import { Toast } from "antd-mobile";

export default observer(function FormBrowse() {
  const theme = useTheme();
  const [search] = useSearchParams();
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const [visible, setVisible] = useState<boolean>(false);
  const [nextData, setNextData] = useState<any>(null);

  const [talkList, setTalkList] = useState<talkType[]>([]); // 表单对话状态列表

  const formId = useMemo(() => search.get("formId"), [search]);
  const type = useMemo(() => search.get("formOrigin"), [search]);
  const getPayload = useMemo(() => search.get("payload"), [search]);

  const payload = useMemo(() => {
    return getPayload ? JSON.parse(getPayload) : null;
  }, [getPayload]);

  const iType = useMemo(() => {
    return type === "FollowUpProject" || type === "HealthManageProject";
  }, [type]);

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const router = useMemo(() => {
    return isDoctor ? routerDoctor : routerPatient;
  }, [isDoctor, routerDoctor, routerPatient]);

  const { followUpStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const { projectPlanInfo, followUpProjectInfo } = useMemo(
    () => doctorStore.followUpProjectStore,
    [doctorStore.followUpProjectStore]
  );

  const userInfo = useMemo(
    () => doctorStore.userLogicStore.userInfo,
    [doctorStore.userLogicStore.userInfo]
  );

  const { info } = useMemo(() => {
    return followUpStore;
  }, [followUpStore]);

  // 判断是否可以对话
  const isHas = useMemo(() => {
    return talkList?.find((item) => item.patientId === payload?.id)?.status;
  }, [talkList, payload]);

  // 根据当前节点拿到当前节点下的所有表单
  const formByNodeId = useMemo(() => {
    return info?.nodes?.find((item) => item.nodeId === payload?.nodeId);
  }, [info?.nodes, payload?.nodeId]);

  const timeRange = useMemo(() => {
    const { floatingNum, floatingDownNum } = formByNodeId || {};
    const nodeTime = payload?.nodeTime;
    if (nodeTime) {
      const beforeMonth =
        moment(nodeTime).subtract(floatingNum, "days").month() + 1;
      const beforeDays = moment(nodeTime).subtract(floatingNum, "days").date();
      const _before = `${beforeMonth}月${beforeDays}日`;
      const afterMonth =
        moment(nodeTime).add(floatingDownNum, "days").month() + 1;
      const afterDays = moment(nodeTime).add(floatingDownNum, "days").date();
      const _after = `${afterMonth}月${afterDays}日`;
      return [_before, _after];
    } else {
      return [];
    }
  }, [formByNodeId, payload?.nodeTime]);

  const currentNode = useMemo(() => {
    return projectPlanInfo?.find((item) => {
      return item.nodeId === payload?.nodeId;
    });
  }, [payload?.nodeId, projectPlanInfo]);

  // 项目信息的GatherList处理
  const projeftInfo = useMemo(() => {
    const _gatherList = currentNode?.forms?.find((item) => {
      return item.formId === formId;
    });

    const _newGatherList = (_gatherList?.gatherList || []).map((item) => {
      return { ...item, formType: _gatherList?.formType };
    });

    return type === "FollowUpProject" ? (_newGatherList as FormListType[]) : [];
  }, [formId, type, currentNode]);

  // console.log(
  //   "currentNode?.formscurrentNode?.formscurrentNode?.forms",
  //   currentNode?.forms
  // );

  // 处理患者详情进入表单、患者端随访进入表单的患者表单集合
  const handleGatherList = useMemo(() => {
    return iType
      ? projeftInfo
      : formByNodeId?.forms?.map((item) => {
          return {
            ...item,
            age: info?.age,
            gender: info?.gender,
            id: info?.patientId as string,
            patientId: info?.patientId as string,
            patientName: info?.patientName,
          };
        }) || [];
  }, [
    formByNodeId?.forms,
    iType,
    info?.age,
    info?.gender,
    info?.patientId,
    info?.patientName,
    projeftInfo,
  ]);

  const index = useMemo(() => {
    return (
      handleGatherList?.findIndex((item) => {
        return iType ? item.id === payload?.id : item.formId === formId;
      }) || 0
    );
  }, [formId, handleGatherList, iType, payload?.id]);

  const currentItem = useMemo(() => {
    const currentNode = followUpProjectInfo?.find((item) => {
      return item.nodeId === payload?.nodeId;
    });
    const curentforms = currentNode?.forms?.find((item) => {
      return item.formId === formId;
    });

    return handleGatherList?.length ? handleGatherList[index] : curentforms;
  }, [followUpProjectInfo, formId, handleGatherList, index, payload?.nodeId]);

  // const serachPatientList = useMemo(() => {
  //   if (isDoctor) {
  //     const { patientEntityStore } = doctorStore;
  //     return patientEntityStore.serachPatientList;
  //   } else {
  //     return [];
  //   }
  // }, [doctorStore, isDoctor]);

  // 判断是否可以对话
  // const isHas = useMemo(() => {
  //   return serachPatientList.find((item) => item.empi === currentItem?.empiId)
  //     ?.isHas;
  // }, [currentItem?.empiId, serachPatientList]);

  const toMesPage = useCallback(() => {
    router.navigateTo("messageDetail", {
      state: {
        patientId: currentItem?.empiId,
        projectId: payload?.projectId,
        name: currentItem?.patientName,
      },
    });
  }, [
    currentItem?.empiId,
    currentItem?.patientName,
    payload?.projectId,
    router,
  ]);

  const toPatientDetail = useCallback(() => {
    currentItem?.id &&
      routerDoctor.navigateTo("patientDetail", {
        state: { id: currentItem?.id },
      });
  }, [currentItem?.id, routerDoctor]);

  /**
   *  获取表单是否可以对话
   * */
  const getFormIsTalk = useCallback(async () => {
    if (type === "FollowUpProject") {
      const patientIds = (currentNode?.patients?.map(
        (item: any) => item.patientId
      ) || []) as string[];

      if (!patientIds.length) return setTalkList([]);

      const params = {
        empiOrUserid: userInfo?.roleId || "",
        projectId: payload?.projectId || "",
        patientIds,
      };

      const res = await followUpStore.talk(params);

      setTalkList(res);
    }
  }, [
    currentNode?.patients,
    followUpStore,
    payload?.projectId,
    type,
    userInfo?.roleId,
  ]);

  useEffect(() => {
    getFormIsTalk();
    action.onGlobalStateChange((state, prev) => {
      // 提交成功后监听随访发过来的消息后，逻辑处理
      if (
        state?.app === "FollowUpMiniProgramFormGatner" &&
        state?.event?.type === "submitSuccessfully"
      ) {
        setVisible(true);
        if (state.event?.formType === 0) {
          if (type === "HealthManage" || type === "FollowUp") {
            const arr =
              type === "HealthManage"
                ? followUpStore.patientHealthInfo?.noWriteList
                : handleGatherList;
            const isExit = arr?.find(
              (item) => item.formId === state.event?.formId
            );
            setNextData(
              isExit
                ? {
                    type,
                    userName: payload?.userName || "",
                    nodeName: payload?.nodeName,
                    formId: state.event?.formId,
                    formType: state.event?.formType,
                    currentFormName: state.event?.currentFormName,
                    formName: state.event?.formName,
                    patientId: state.event?.patientId,
                    projectId: state.event?.projectId,
                    nodeId: state.event?.nodeId,
                    time: (payload?.time as string[]) || timeRange,
                  }
                : null
            );
          } else {
            setNextData({
              type,
              userName: payload?.userName || "",
              nodeName: payload?.nodeName,
              formId: state.event?.formId,
              formType: state.event?.formType,
              currentFormName: state.event?.currentFormName,
              formName: state.event?.formName,
              patientId: state.event?.patientId,
              projectId: state.event?.projectId,
              nodeId: state.event?.nodeId,
              time: (payload?.time as string[]) || timeRange,
            });
          }
        } else {
          setNextData(null);
        }
      }

      // 保存成功后监听随访发过来的消息后，逻辑处理
      if (
        state?.app === "FollowUpMiniProgramFormGatner" &&
        state?.event?.type === "saveSuccessfully"
      ) {
        setVisible(false);
        Toast.show("保存成功");
      }
    });
    // action.setGlobalState({
    //   app: "clinbrain_scientific_wechatapplet_h5",
    //   data: { formId },
    // });
  }, [followUpStore.patientHealthInfo?.noWriteList, getFormIsTalk, handleGatherList, payload?.nodeName, payload?.time, payload?.userName, router, timeRange, type]);

  return (
    <div className="flex flex-col h-full">
      <SecondaryHead
        index={index}
        nodeTime={(payload?.time as string[]) || timeRange}
        formName={payload?.formName as string}
        nodeName={payload?.nodeName as string}
        gatherList={handleGatherList}
      />

      {/* 表单组件 */}
      {/*  */}
      <div
        className="flex-1 overflow-auto"
        style={{
          display:
            currentItem?.formType === 0 || payload?.formType === 0
              ? "block"
              : "none",
        }}
      >
        <FormGather />
      </div>
      {/*  */}
      {/* 宣教组件 */}
      {(currentItem?.formType === 1 || payload?.formType === 1) && (
        <div className="flex-1 overflow-auto">
          <HtmlEleContent id={formId || ""} />
        </div>
      )}
      {type === "FollowUpProject" && (
        <div
          className="pt-4 pb-8 w-full flex justify-around items-center"
          css={css`
            background: ${theme.borderColor};
          `}
        >
          <Button
            color="primary"
            fill="outline"
            css={css`
              width: 42%;
            `}
            disabled={!isHas}
            onClick={toMesPage}
          >
            <Typography colorType="primaryColor">对话</Typography>
          </Button>
          <Button
            color="primary"
            fill="solid"
            css={css`
              width: 42%;
            `}
            disabled={!currentItem?.empiId}
            onClick={toPatientDetail}
          >
            <Typography colorType="whiteColor">患者详情</Typography>
          </Button>
        </div>
      )}

      <SubmitSuccessPage
        popupVisible={visible}
        onVisibleHandle={(val: boolean) => {
          setVisible(val);
        }}
        nextData={nextData}
      />
    </div>
  );
});
