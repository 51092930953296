/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import {
  RightOutline,
  DownOutline,
  LeftOutline,
  UpOutline,
  DownFill,
} from "antd-mobile-icons";
import { css } from "@emotion/react";
import { useTheme } from "../theme";

export interface ArrowIconProps
  extends PropsWithChildren,
    React.HTMLAttributes<HTMLSpanElement> {
  direction: "up" | "down" | "right" | "left";
  isFilled?: boolean;
  className?: string;
}

export default React.memo(function ArrowIcon(props: ArrowIconProps) {
  const { direction, className, children, isFilled } = props;
  const theme = useTheme();

  const commonStyle = css`
    color: ${theme.graySecondaryColor};
    margin-left: 4px;
  `;

  const arrowRender = useMemo(() => {
    const _component: Partial<
      Record<Exclude<ArrowIconProps["direction"], undefined>, ReactElement>
    > = {
      up: isFilled ? (
        <UpOutline
          css={[
            commonStyle,
            css`
              font-size: 12px;
              line-height: 16px;
            `,
          ]}
          className={className}
        />
      ) : (
        <UpOutline
          css={[
            commonStyle,
            css`
              font-size: 12px;
              line-height: 16px;
            `,
          ]}
          className={className}
        />
      ),
      down: isFilled ? (
        <DownFill
          css={[
            commonStyle,
            css`
              font-size: 12px;
              line-height: 16px;
            `,
          ]}
          className={className}
        />
      ) : (
        <DownOutline
          css={[
            commonStyle,
            css`
              font-size: 12px;
              line-height: 16px;
            `,
          ]}
          className={className}
        />
      ),
      right: (
        <RightOutline
          css={[
            commonStyle,
            css`
              font-size: 16px;
              line-height: 22px;
            `,
          ]}
          className={className}
        />
      ),
      left: (
        <LeftOutline
          css={[
            commonStyle,
            css`
              font-size: 16px;
              line-height: 22px;
              margin-left: 0;
              margin-right: 4px;
            `,
          ]}
          className={className}
        />
      ),
    };

    return _component[direction] ?? null;
  }, [className, commonStyle, direction, isFilled]);

  return children ? (
    <span
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {children}
      {arrowRender}
    </span>
  ) : (
    arrowRender
  );
});
