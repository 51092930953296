/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { PatientRouter } from "../../router/Patient";

export default observer(function AppMain() {
  return (
    <main
      css={css`
        flex-grow: 1;
        overflow-y: auto;
      `}
    >
      <PatientRouter />
    </main>
  );
});
