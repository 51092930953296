import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import {
  GetHomeProjectListParamsType,
  GetHomeProjectListPromiseType,
} from "./../../models/entity/followUpProjectAll";

class FollowUpProjectAllStore {
  projectAllList = [] as GetHomeProjectListPromiseType[];
  inProgressList = [] as GetHomeProjectListPromiseType[];
  endProgressList = [] as GetHomeProjectListPromiseType[];
  constructor() {
    makeAutoObservable(this);
  }

  getHomeProjectList = async (params: GetHomeProjectListParamsType) => {
    this.projectAllList =
      await serviceClient.followUpProjectAll.getHomeProjectList(params);

    this.inProgressList = this.projectAllList.filter((v) => v.nodeStatus === 1);
    this.endProgressList = this.projectAllList.filter(
      (v) => v.nodeStatus !== 1
    );
  };
}

export default FollowUpProjectAllStore;
