/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dialog, Form, Input, Toast } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components";
import { FollowUpUser } from "../../models/entity/followUpUser";
import { useRouter } from "../../router";
import { serviceClient } from "../../service";
import usePatientStore from "../../store/patient";
import SelectGender from "./components/SelectGender";
import SelectUserRelation from "./components/SelectUserRelation";

export default observer(function FollowUpUserUpdate({
  viewMode = "update",
}: {
  viewMode?: "add" | "update";
}) {
  const router = useRouter();
  const [search] = useSearchParams();
  const userId = useMemo(() => search.get("id"), [search]);
  const [userDetail, setUserDetail] = useState<FollowUpUser | undefined>();

  const [form] = Form.useForm();
  const [isAllFilled, setIsAllFilled] = useState(false);
  const { userLogicStore } = usePatientStore();
  const { userProfile, userInfo } = userLogicStore;

  const initialValues = useMemo(() => {
    if (viewMode === "update" && userDetail) {
      const { relation, phoneNumber, name, sex, idcard } = userDetail;
      return {
        relation,
        phoneNumber,
        name,
        sex,
        idcard,
      };
    } else {
      return {
        relation: undefined,
        phoneNumber: undefined,
        name: undefined,
        sex: undefined,
        idcard: undefined,
      };
    }
  }, [userDetail, viewMode]);

  const userUpdateCallback = useCallback(async () => {
    await userLogicStore.initializeUserInfo();
    if (viewMode === "add") {
      Toast.show("添加成功");
    } else {
      Toast.show("修改成功");
    }
    router.navigateTo("followUpUser");
  }, [router, userLogicStore, viewMode]);

  const onAddFollowUpUser = useCallback(async () => {
    const { relation, phoneNumber, name, sex, idcard } = form.getFieldsValue();
    const info = JSON.parse(localStorage.getItem("wxInfo") || "{}");
    const payload: Partial<FollowUpUser> = {
      relation,
      phoneNumber,
      name,
      sex,
      idcard,
      hospitalId: userInfo?.hospitalId,
      type: userProfile?.type,
      wxPhoneNumber: userProfile?.wxPhoneNumber,
      openId: info?.openId,
    };

    await serviceClient.followUpUser.addFollowUpUser(payload);
    await userUpdateCallback();
  }, [
    form,
    userUpdateCallback,
    userInfo?.hospitalId,
    userProfile?.type,
    userProfile?.wxPhoneNumber,
  ]);

  const onUpdateFollowUpUser = useCallback(async () => {
    const { relation, phoneNumber, name, sex, idcard } = form.getFieldsValue();

    const payload: Partial<FollowUpUser> = {
      relation,
      phoneNumber,
      name,
      sex,
      idcard,
      roleId: userId ?? "",
      hospitalId: userInfo?.hospitalId,
      type: userProfile?.type,
      wxPhoneNumber: userProfile?.wxPhoneNumber,
    };

    await serviceClient.followUpUser.updateFollowUpUser(payload);
    await userUpdateCallback();
  }, [
    form,
    userUpdateCallback,
    userId,
    userInfo?.hospitalId,
    userProfile?.type,
    userProfile?.wxPhoneNumber,
  ]);

  const onValuesChange = useCallback(
    (changedValues: any, allValues: object) => {
      const emptyCount = Object.entries(allValues).filter(([key, value]) => {
        return [undefined, null, ""].includes(value);
      });
      setIsAllFilled(!emptyCount.length);
    },
    []
  );

  const getUserDetail = useCallback(async () => {
    if (userId) {
      const value = await serviceClient.followUpUser.getFollowUpUserDetail(
        userId
      );
      setUserDetail(value);
    }
  }, [userId]);

  const onDeleteFollowUpUser = useCallback(async () => {
    if (userDetail?.roleId) {
      Dialog.confirm({
        content: "确定删除",
        onConfirm: async () => {
          await serviceClient.followUpUser.deleteFollowUpUser(
            userDetail?.roleId
          );
          Toast.show("删除成功");
          router.navigateTo("followUpUser");
        },
      });
    }
  }, [router, userDetail?.roleId]);

  useEffect(() => {
    if (viewMode === "update" && userId) {
      getUserDetail();
    }
  }, [getUserDetail, userId, viewMode]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <div className="page-padding">
      <Form
        css={css`
          --prefix-width: 76px;
          --border-top: 0px;
          .adm-list {
            .adm-list-item {
              padding-left: 0;
              .adm-list-item-content-prefix {
                padding-right: 4px;
              }
            }
          }
        `}
        form={form}
        layout="horizontal"
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <Form.Item label="与随访人关系" name="relation">
          <SelectUserRelation />
        </Form.Item>
        <Form.Item label="手机号" name="phoneNumber">
          <Input placeholder="请输入随访人在医院预留的手机号" type="number" />
        </Form.Item>
        <Form.Item label="姓名" name="name">
          <Input
            placeholder="请输入随访人姓名"
            disabled={viewMode === "update"}
          />
        </Form.Item>
        <Form.Item label="性别" name="sex">
          <SelectGender />
        </Form.Item>
        <Form.Item label="身份证号" name="idcard">
          <Input
            placeholder="请输入随访人身份证号"
            disabled={viewMode === "update"}
          />
        </Form.Item>
      </Form>
      {viewMode === "add" ? (
        <Button
          className="mt-6"
          size="large"
          fill="solid"
          color="primary"
          block
          disabled={!isAllFilled}
          onClick={onAddFollowUpUser}
        >
          添加
        </Button>
      ) : (
        <Button
          className="mt-6"
          size="large"
          fill="solid"
          color="primary"
          block
          disabled={!isAllFilled}
          onClick={onUpdateFollowUpUser}
        >
          确定
        </Button>
      )}
      {viewMode === "update" && userDetail?.status !== 0 && (
        <Button
          className="mt-10"
          fill="none"
          color="danger"
          block
          onClick={onDeleteFollowUpUser}
        >
          删除随访人
        </Button>
      )}
    </div>
  );
});
