import React, { useContext } from "react";

export type AppUserIdentityTypeContextType =
  | "doctor"
  | "patient"
  | "bindingRole"
  | "ssoLogin"
  | undefined;

export const AppUserIdentityTypeContext =
  React.createContext<AppUserIdentityTypeContextType>(undefined);

export const useAppUserIdentityType = () => {
  const value = useContext(AppUserIdentityTypeContext);
  return value;
};
