/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MesHistoryType } from "../../models/entity/instantMessage";
import { Toast } from "antd-mobile";

export default function useWebSocket({
  sessionId,
  empiOrUserid,
  updateMesRead,
}: {
  sessionId: string | number | null;
  empiOrUserid: string;
  updateMesRead: (ids: number[]) => void;
}) {
  const ws = useRef<WebSocket | null>(null);
  const [message, setMessage] = useState<MesHistoryType>({} as MesHistoryType);
  const [status, setStatus] = useState({ key: 0, value: "正在连接中..." });

  const url = useMemo(() => {
    const address = window._settings.apiUrl.split("//")[1];
    return sessionId && empiOrUserid
      ? `wss://${address}/api/scf/webSocket/${sessionId}&${empiOrUserid}`
      : "";
  }, [empiOrUserid, sessionId]);

  const createWebSocket = useCallback(() => {
    const statusArr = [
      { key: 0, value: "正在连接中..." },
      { key: 1, value: "已经连接并且可以通讯" },
      { key: 2, value: "连接正在关闭" },
      { key: 3, value: "连接已关闭或者连接没有成功" },
    ];

    ws.current = new WebSocket(url);
    ws.current.onopen = function () {
      if (
        (ws.current as WebSocket).readyState === (ws.current as WebSocket).OPEN
      ) {
        console.log("WS 连接成功!");
        setStatus(statusArr[ws.current?.readyState ?? 0]);
      }
      if (
        (ws.current as WebSocket).readyState ===
        (ws.current as WebSocket).CLOSED
      ) {
        console.log("WS 连接错误!");
        Toast.show("连接错误!");
        setStatus(statusArr[ws.current?.readyState ?? 0]);
      }
    };

    ws.current.onclose = function () {
      console.log("WS 连接关闭!");
      setStatus(statusArr[ws.current?.readyState ?? 0]);
    };

    ws.current.onerror = function () {
      console.log("WS 连接错误!");
      Toast.show("连接错误!");
      setStatus(statusArr[ws.current?.readyState ?? 0]);
    };

    ws.current.onmessage = function (event) {
      if (
        (ws.current as WebSocket).readyState ===
        (ws.current as WebSocket).CLOSED
      ) {
        console.log("WS 连接错误!");
        Toast.show("连接错误!");
      }
      const data = JSON.parse(event.data);
      // 更改消息状态
      updateMesRead([data.id]);
      setMessage(data);
    };
  }, [updateMesRead, url]);

  // 关闭websocket
  const closeWebsocket = useCallback(() => {
    if (ws.current) {
      ws.current.close();
    }
  }, []);

  // 重连
  const reconnect = useCallback(() => {
    try {
      closeWebsocket();
      ws.current = null;
      createWebSocket();
    } catch (e) {
      console.log(e);
    }
  }, [closeWebsocket, createWebSocket]);

  const websocketInit = useCallback(() => {
    if (!ws.current || ws.current.readyState === 3) {
      createWebSocket();
    }
  }, [createWebSocket]);

  // 发送数据
  const sendMessage = useCallback((str: MesHistoryType) => {
    if (ws.current) {
      setMessage(str);
      ws.current.send(str.mesContent || "");
    }
  }, []);

  useEffect(() => {
    if (url !== "") {
      websocketInit();
    }

    return () => {
      closeWebsocket();
    };
  }, [closeWebsocket, url, websocketInit]);

  return {
    message,
    status,
    reconnect,
    closeWebsocket,
    sendMessage,
  };
}
