/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, Input, Toast } from "antd-mobile";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../../components";
import { DoctorUserUpdate } from "../../models/entity/user";
import { useRouter } from "../../router";
import { serviceClient } from "../../service";
import useDoctorStore from "../../store/doctor";
import DepartmentSelect from "./components/DepartmentSelect";

export default observer(function UserUpdate() {
  const router = useRouter();

  const [form] = Form.useForm();
  const [isAllFilled, setIsAllFilled] = useState(false);
  const { userLogicStore } = useDoctorStore();
  const { userProfile, userInfo } = userLogicStore;

  const initialValues = useMemo(() => {
    return {
      depId: userInfo?.depId,
      jobNumber: userInfo?.jobNumber,
    };
  }, [userInfo?.depId, userInfo?.jobNumber]);

  const userUpdateCallback = useCallback(async () => {
    await userLogicStore.initializeUserInfo();

    Toast.show("修改成功");
    router.navigateTo("user");
  }, [router, userLogicStore]);

  const onUpdateUser = useCallback(async () => {
    const { depId, jobNumber } = form.getFieldsValue();

    const payload: Partial<DoctorUserUpdate> = {
      depId,
      jobNumber,
      phoneNumber: userInfo?.phoneNumber,
      roleId: userInfo?.roleId,
      hospitalId: userInfo?.hospitalId,
      type: userProfile?.type,
      wxPhoneNumber: userProfile?.wxPhoneNumber,
    };

    await serviceClient.user.updateDoctorInfo(payload);
    await userUpdateCallback();
  }, [
    form,
    userInfo?.hospitalId,
    userInfo?.phoneNumber,
    userInfo?.roleId,
    userProfile?.type,
    userProfile?.wxPhoneNumber,
    userUpdateCallback,
  ]);

  const onValuesChange = useCallback(
    (changedValues: any, allValues: object) => {
      const emptyCount = Object.entries(allValues).filter(([key, value]) => {
        return [undefined, null, ""].includes(value);
      });
      setIsAllFilled(!emptyCount.length);
    },
    []
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues, userInfo]);

  return (
    <div className="page-padding">
      <Form
        css={css`
          --border-top: 0px;
          .adm-list {
            .adm-list-item {
              padding-left: 0;
            }
          }
        `}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <Form.Item label="科室" name="depId">
          <DepartmentSelect />
        </Form.Item>
        <Form.Item label="工号" name="jobNumber">
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>

      <Button
        className="mt-6"
        size="large"
        fill="solid"
        color="primary"
        block
        disabled={!isAllFilled}
        onClick={onUpdateUser}
      >
        完成
      </Button>
    </div>
  );
});
