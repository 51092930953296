/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import cx from "classnames";
import React, { PropsWithChildren } from "react";
import { useTheme } from "../theme";

export interface CardProps extends PropsWithChildren {
  className?: string;
}

export default React.memo(function Card(props: CardProps) {
  const theme = useTheme();
  const { className, ...restProps } = props;

  return (
    <div
      css={[
        css`
          box-shadow: ${theme.styles.boxShadow};
          border-radius: 6px;
          flex-basis: 45%;
        `,
      ]}
      className={cx("p-3", className)}
      {...restProps}
    ></div>
  );
});
