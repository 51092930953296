import { CommonColorsType } from "../../theme/index";
import { UserIdentityType } from "./user";

export interface SerachPatientCountType {
  empis: string[];
  projectId: string;
}

export interface SerachPatientCountPromiseType {
  count: number;
  projectId: string;
}

export interface GetProjectListParamsType {
  empiOrUserid: number | string;
  type: UserIdentityType;
}

export type GetProjectListPromiseType = {
  empIds: string[];
  id: string;
  progress?: string;
  programmeId?: string;
  projectId?: string;
  projectName: string;
  sort?: number;
  status: number;
  statusName: string;
  totalCount?: number;
};

export interface GatherListType {
  age: number;
  formContent: string;
  formId: string;
  formJson: string;
  gender: string;
  id: string;
  patientId: string;
  patientName: string;
}

export interface FormListType {
  age?: number;
  gender?: string;
  empiId?: string;
  patientId?: string;
  patientName?: string;
  formId: string;
  formName: string;
  formType: number;
  formWrite?: number;
  id?: string;
  gatherList?: GatherListType[];
  nodeId: string;
  projectId?: string;
  formContent?: string;
  formJson?: string;
}

export interface HealthInfoType {
  noWriteList?: FormListType[];
  writeList?: any[];
}

export interface FollowupPlanInfoType {
  age: number;
  gender: string;
  beginType?: number;
  currentNodeIng?: number;
  floatingDownNum: number;
  floatingNum: number;
  followUp: boolean;
  forms: FormListType[];
  nodeFirst?: boolean;
  nodeId: string;
  nodeName: string;
  nodeType?: number;
  nodeTime?: string;
  operationCode?: string;
  overdueDays?: number;
  projectId?: string;
  programmeId?: string;
  specifiedUnit?: string;
  status: number;
  statusName?: string;
  todayStatus?: number;
  commitCount?: number;
  patients?: [];
  totalCount?: number;
  patientName?: string;
  systemType?: number;
}

export interface EducationInfoType {
  createBy: string;
  createId: string;
  createTime: string;
  deleted: string;
  educationContent: string;
  educationVersion: string;
  id: string;
  name: string;
  parentId: string;
  quotecount: number;
  status: number;
  totalVersion: number;
  type: number;
  updateTime: string;
}

export interface statusListType {
  label: string;
  value: string;
  colorType: keyof CommonColorsType;
  background: string;
}

export const statusList: statusListType[] = [
  {
    label: "未开始",
    value: "0",
    colorType: "grayLightColor",
    background: "rgba(153, 153, 153, 0.06)",
  },
  {
    label: "进行中",
    value: "1",
    colorType: "primaryColor",
    background: "rgba(38, 118, 237, 0.06)",
  },
  {
    label: "已完成",
    value: "2",
    colorType: "successColor",
    background: "rgba(12, 181, 122, 0.06)",
  },
  {
    label: "已逾期",
    value: "3",
    colorType: "dangerColor",
    background: "rgba(214, 46, 52, 0.06)",
  },
  {
    label: "失访",
    value: "4",
    colorType: "dangerColor",
    background: "rgba(214, 46, 52, 0.06)",
  },
  {
    label: "死亡",
    value: "5",
    colorType: "dangerColor",
    background: "rgba(214, 46, 52, 0.06)",
  },
  {
    label: "终止随访",
    value: "6",
    colorType: "dangerColor",
    background: "rgba(214, 46, 52, 0.06)",
  },
];

export interface talkType {
  patientId: string;
  status: number;
}
