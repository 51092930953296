/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import ActionBar from "./ActionBar";
import MessageContent from "./MessageContent";
import useWebSocket from "./useWebSocket";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";

export default observer(function SendMessage() {
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, userSendMesStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const { message, sendMessage } = useWebSocket({
    sessionId: userSendMesStore.sessionId,
    empiOrUserid: userProfile?.empiOrUserid || "",
    updateMesRead: userSendMesStore.fetchUpdateMesRead,
  });

  useEffect(() => {
    message.mesContent && userSendMesStore.setMessageList([message]);
  }, [message, userSendMesStore]);

  return (
    <div
      className="flex flex-col justify-between"
      css={css`
        height: 100%;
        background: #f7f8fa;
        overflow: hidden;
      `}
    >
      <MessageContent />

      <ActionBar handleSend={sendMessage} />
    </div>
  );
});
