import { observer } from "mobx-react-lite";
import { Typography } from "../../components";
import { useMemo } from "react";
import usePatientStore from "../../store/patient";

export default observer(function Home() {
  const patientStore = usePatientStore();
  const { userLogicStore } = patientStore;

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  return (
    <div className="my-4">
      <Typography type="title-bold" block className="mb-2">
        {userInfo?.name || "访客"}
      </Typography>
      <Typography type="secondary" block colorType="grayLightColor">
        {userInfo?.hospitalName || "--"}
      </Typography>
    </div>
  );
});
