import { BrowserRouter } from "react-router-dom";
import Layout from "./pagesPatient/Layout";
import { rootStore, StoreContext } from "./store/patient";
import { setSaasTokenByUrl } from "./utils";

function AppPatient() {
  setSaasTokenByUrl();
  // const base = process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxsf/" : "/";

  const base = process.env.REACT_APP_PUBLIC_URL || "/";

  return (
    <BrowserRouter basename={base}>
      <StoreContext.Provider value={rootStore}>
        <Layout />
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default AppPatient;
