import { hospitalHttpInstance } from "../utils/http";
import {
  VisitRecordType,
  CheckOutRecordType,
  CheckUpRecordType,
  CheckOutItemRecordType,
} from "../models/entity/hospitalData";

export class HospitalDataClient {
  // 获取检验记录明细
  async getCheckOutItemRecord(
    reportId: string
  ): Promise<CheckOutItemRecordType> {
    return hospitalHttpInstance.get(
      `/system/WxSystem/getCheckOutItemRecord/${reportId}`
    );
  }

  // 获取就诊记录
  async getVisitRecord(params: {
    empiseqid: string;
    type: string;
    visitType: string;
  }): Promise<VisitRecordType[]> {
    return hospitalHttpInstance.post("/system/WxSystem/getVisitRecord", params);
  }

  // 获取检验记录
  async getCheckOutRecord(params: {
    empiseqid: string;
    type: string;
  }): Promise<CheckOutRecordType[]> {
    return hospitalHttpInstance.post(
      "/system/WxSystem/getCheckOutRecord",
      params
    );
  }

  // 获取检查记录
  async getCheckUpRecord(params: {
    empiseqid: string;
    type: string;
  }): Promise<CheckUpRecordType[]> {
    return hospitalHttpInstance.post(
      "/system/WxSystem/getCheckUpRecord",
      params
    );
  }
}
