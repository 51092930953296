/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useMemo } from "react";
import {
  Avatar as AntdAvatar,
  AvatarProps as AntdAvatarProps,
} from "antd-mobile";
import { useTheme } from "../../theme";
import { Typography } from "../../components";

export interface AvatarProps extends AntdAvatarProps {
  children?: ReactNode;
  fill?: boolean;
  size?: number;
  name: ReactNode;
}

export default React.memo(function PatientAvatar(props: AvatarProps) {
  const { fill = true, size = 32, children, name, ...rest } = props;
  const theme = useTheme();

  const _className = useMemo(() => {
    return [
      css`
        --size: ${size}px;
      `,
    ];
  }, [size]);

  return (
    <div
      className="flex items-center rounded-md pr-3"
      css={css`
        background: ${fill ? theme.grayWeakColor : "transparent"};
      `}
    >
      <AntdAvatar {...rest} css={_className} />

      <div className="ml-2">
        <Typography type="primary" className="font-medium">
          {name}
        </Typography>

        {children}
      </div>
    </div>
  );
});
