/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import FormBrowse from "../FollowUp/FormBrowse";

export type FormOriginType =
  | "formBrowse"
  | "FollowUpProject"
  | "FollowUpPatient"
  | "FollowUp"
  | "HealthManageProject"
  | "HealthManagePatient"
  | "HealthManage";

//表单路由参数,最终转为search
export interface FormGatherRouterSearch extends Object {
  formId: string;
  formAttrs: {
    isDisabled?: boolean; //默认false
    hideOperationButtons?: boolean; //默认false
  };
  formOrigin: FormOriginType;
  payload?: Record<string, unknown>;
}

export default observer(function FormGatherWrapper() {
  const [search] = useSearchParams();
  const formOrigin: FormOriginType | null = useMemo(
    () => search.get("formOrigin") as FormOriginType,
    [search]
  );

  const renderFormWrapper = useMemo(() => {
    let _component = null;

    switch (formOrigin) {
      case "formBrowse":
        _component = <FormBrowse />;
        break;
      default:
        _component = <FormBrowse />;
        break;
    }

    return _component;
  }, [formOrigin]);

  return renderFormWrapper;
});
