import { makeAutoObservable } from "mobx";

export default class AppLogicStore {
  title = " " as string;

  constructor() {
    makeAutoObservable(this);
  }

  setTitle = (title: string) => {
    this.title = title;
  };
}
