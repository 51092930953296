import { makeAutoObservable } from "mobx";
import { Department, Hospital } from "../../models/entity/hospital";
import { serviceClient } from "../../service";

export default class HospitalEntityStore {
  hospitalList = null as Hospital[] | null;
  departmentList = null as Department[] | null;

  constructor() {
    makeAutoObservable(this);
  }
  getHospitalList = async () => {
    this.hospitalList = await serviceClient.system.getHospitalList();
  };
  getDepartmentList = async (hospitalId: string) => {
    this.departmentList = await serviceClient.system.getDepartmentList(
      hospitalId
    );
  };
}
