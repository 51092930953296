/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useAppUserIdentityType } from "../../../hooks";
import { css } from "@emotion/react";
import { Card, Typography } from "../../../components";
import { GetHomeNodeListPromiseType } from "../../../models/entity/followUpOutpatient";
import usePatientStore from "../../../store/patient";
import Empty from "../../../coreUIComponents/Empty";

const Status = observer(function (
  props: PropsWithChildren<{
    item: GetHomeNodeListPromiseType;
  }>
) {
  const { item } = props;

  return (
    <div
      css={css`
        background-color: ${item.status === 1 ? "#F2F3F5" : "#E8F3FF"};
        border-radius: 2px;
      `}
    >
      <Typography
        block
        type="mini"
        css={css`
          color: ${item.status === 1 ? "#4E5969" : "#165DFF"};
        `}
        className="mt-1 mb-1 ml-1 mr-1"
      >
        {item.status === 1 ? "未开始" : "请按时就诊"}
      </Typography>
    </div>
  );
});

export default observer(function Node() {
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, followUpOutpatientStore } = useMemo(
    () => patientStore,
    [patientStore]
  );

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const { selectedOpcProject, homeNodeList } = followUpOutpatientStore;

  useEffect(() => {
    if (!selectedOpcProject.projectId) return;
    followUpOutpatientStore.getHomeNodeList({
      empiOrUserid: userProfile?.empiOrUserid || "",
      type: isDoctor ? 2 : 1,
      projectId: selectedOpcProject.projectId,
    });
  }, [followUpOutpatientStore, isDoctor, selectedOpcProject.projectId, userProfile?.empiOrUserid]);

  return (
    <>
      {homeNodeList.length > 0 ? (
        homeNodeList.map((item: GetHomeNodeListPromiseType, index: number) => {
          return (
            <div key={index} className="p-3 mt-1">
              <Card className="p-3">
                <div className="flex justify-between items-center mb-3">
                  <Typography
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    {item.department}
                  </Typography>
                  <Status item={item} />
                </div>

                <Typography
                  colorType={
                    item.status === 0 ? "primaryColor" : "grayMainColor"
                  }
                  block
                  className="mb-3"
                >
                  {item.formatTime}
                </Typography>

                <Typography colorType="graySecondaryColor">
                  {item.projectName}
                </Typography>
              </Card>
            </div>
          );
        })
      ) : (
        <Empty title="暂无门诊随访" />
      )}
    </>
  );
});
