/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { Badge } from "antd-mobile";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { Typography } from "../../components";
import useDoctorStore from "../../store/doctor";
import { useDoctorRouter } from "../../router/Doctor";
import calendarImg from "../../assets/icons/doctorPhoto.svg";
import ListItemCard from "../../coreUIComponents/HospitalData/ListItemCard";
import { getConfirmation } from "../../utils/http/index";

import {
  AllMesListType,
  HandleMesListType,
} from "../../models/entity/instantMessage";
import Empty from "../../coreUIComponents/Empty";

export default observer(function InstantMessage() {
  const router = useDoctorRouter();
  const doctorStore = useDoctorStore();
  const { userLogicStore, userSendMesStore, followUpStore } = doctorStore;
  const { saasToken } = userLogicStore;
  const { allMesList } = userSendMesStore;
  const { projectList } = followUpStore;

  // 消息数据处理 [{ project: "", list: [] }, { project: "", list: [] }]
  const handleData = useMemo(() => {
    const result = allMesList.reduce((newArr: any, curItem) => {
      (newArr[curItem.projectId] = newArr[curItem.projectId] || []).push(
        curItem
      );
      return newArr;
    }, {});
    return Object.keys(result).map((item) => {
      const curProject = projectList.find((o) => o.id === item);
      if (curProject?.id) {
        return {
          list: [...result[item]],
          projectName: curProject.projectName,
          projectId: curProject?.id,
        };
      } else {
        return [] as unknown as HandleMesListType;
      }
    });
  }, [allMesList, projectList]);

  useEffect(() => {
    if (window._settings.isWXLogin) {
      saasToken && userSendMesStore.fetchGetMesSessionList();
    }
  }, [saasToken, userSendMesStore]);

  const handleClick = useCallback(
    (val: AllMesListType) => {
      getConfirmation();

      router.navigateTo("messageDetail", {
        state: {
          patientId: val.empiOrUserId,
          projectId: val.projectId,
          name: val.name,
        },
      });
    },
    [router]
  );

  return (
    <div className="page-padding">
      {handleData?.length > 0 ? (
        <>
          {handleData?.map((child: HandleMesListType) => {
            return (
              <div key={child.projectId} className="pb-3">
                <div
                  className="flex items-center py-2 px-4 -mx-4"
                  css={css`
                    background: #f5f7fa;
                  `}
                >
                  <Typography block className="font-medium">
                    #{child?.projectName}
                  </Typography>
                </div>
                {child?.list?.map((item: AllMesListType) => {
                  return (
                    <ListItemCard
                      key={item.sessionId}
                      type="row"
                      handleClick={() => handleClick(item)}
                      className="py-3"
                    >
                      <div className="flex">
                        <Badge content={item.unread === 0 ? "" : item.unread}>
                          <img
                            src={item?.avatarUrl || calendarImg}
                            className="w-9 h-9"
                            alt=""
                          />
                        </Badge>
                        <div className="flex flex-col ml-3">
                          <Typography
                            css={css`
                              line-height: 18px;
                              font-weight: 500;
                            `}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            colorType="grayLightColor"
                            css={css`
                              line-height: 20px;
                            `}
                          >
                            {item?.info?.mesType === 1
                              ? "[文件]"
                              : item?.info?.mesContent}
                          </Typography>
                        </div>
                      </div>
                      <Typography type="mini" block>
                        {item?.info?.mesTime
                          ? moment(item?.info?.mesTime).isSame(moment(), "day")
                            ? moment(item?.info?.mesTime).format("HH:mm")
                            : moment(item?.info?.mesTime).isSame(
                                moment().subtract(1, "day"),
                                "day"
                              )
                            ? `昨天 ${moment(item?.info?.mesTime).format(
                                "HH:mm"
                              )}`
                            : moment(item?.info?.mesTime).format("YYYY-MM-DD")
                          : null}
                      </Typography>
                    </ListItemCard>
                  );
                })}
              </div>
            );
          })}
        </>
      ) : (
        <Empty title="暂无数据" />
      )}
    </div>
  );
});
