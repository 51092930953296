import React, { useMemo } from "react";
import AppPatient from "./AppPatient";
import AppDoctor from "./AppDoctor";
import BindingRole from "./AppBindingRole";
import SsoLogin from "./pages/SsoLogin";

import { AppUserIdentityTypeContext } from "./hooks";
import "./styles/common.css";
import NotFound from "./coreUIComponents/NotFound";
import { getAppUserIdentityType } from "./utils";

export default function App() {
  const appUserIdentityType = useMemo(() => {
    return getAppUserIdentityType();
  }, []);

  /*
   * 退出登录时，清除localStorage和sessionStorage
   if (window._settings.isWXLogin) 
    window.addEventListener("pagehide", function () {
      localStorage.clear();
      sessionStorage.clear();
    });
  } */

  return (
    <AppUserIdentityTypeContext.Provider value={appUserIdentityType}>
      {appUserIdentityType === "doctor" && <AppDoctor />}
      {appUserIdentityType === "patient" && <AppPatient />}
      {appUserIdentityType === "bindingRole" && <BindingRole />}
      {appUserIdentityType === "ssoLogin" && <SsoLogin />}
      {!appUserIdentityType && <NotFound />}
    </AppUserIdentityTypeContext.Provider>
  );
}
