import { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { Divider } from "antd-mobile";
import ListItemCard from "./ListItemCard";
import { Typography, ArrowIcon } from "../../components";
import DropdownSelect from "../DropdownSelect";
import DropdownTitle from "../DropdownSelect/DropdownTitle";
import { useDoctorRouter } from "../../router/Doctor";
import { usePatientRouter } from "../../router/Patient";
import { TimeList } from "../../models/entity/hospitalData";
import { SelectListType } from "../../models/entity/patient";
import { useAppUserIdentityType } from "../../hooks";
import useDoctorStore from "../../store/doctor";
import usePatientStore from "../../store/patient";
import {
  CheckOutRecordType,
  CheckUpRecordType,
} from "../../models/entity/hospitalData";
import Empty from "../../coreUIComponents/Empty";

export default observer(function ReportList() {
  const { state } = useLocation();
  const { empid } = state || {};
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const appUserIdentityType = useAppUserIdentityType();
  const patientStore = usePatientStore();
  const doctorStore = useDoctorStore();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, hospitalDataStore, appLogicStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const router = useMemo(() => {
    return isDoctor ? routerDoctor : routerPatient;
  }, [isDoctor, routerDoctor, routerPatient]);

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const { checkOutRecord, checkUpRecord, selectedTime, activeKey } =
    useMemo(() => {
      return hospitalDataStore;
    }, [hospitalDataStore]);

  const list = useMemo(() => {
    return activeKey === "checkout" ? checkOutRecord : checkUpRecord;
  }, [activeKey, checkOutRecord, checkUpRecord]);

  const params = useMemo(() => {
    return {
      empiseqid: empid,
      type: selectedTime.value || "0",
    };
  }, [empid, selectedTime.value]);

  useEffect(() => {
    activeKey === "checkout" && hospitalDataStore.getCheckOutRecord(params);
  }, [activeKey, hospitalDataStore, params]);

  useEffect(() => {
    activeKey === "inspect" && hospitalDataStore.getCheckUpRecord(params);
  }, [activeKey, hospitalDataStore, params]);

  const handleClick = useCallback(
    (item: CheckOutRecordType | CheckUpRecordType) => {
      if (activeKey === "checkout") {
        router.navigateTo("reportDetail", {
          state: {
            activeKey,
            reportid: (item as CheckOutRecordType)?.reportid,
          },
        });
        appLogicStore.setTitle((item as CheckOutRecordType)?.labgroupitemname);
      } else {
        router.navigateTo("reportDetail", {
          state: {
            activeKey,
            details: JSON.stringify(item as CheckUpRecordType),
          },
        });
        appLogicStore.setTitle((item as CheckUpRecordType)?.requestitemname);
      }
    },
    [activeKey, appLogicStore, router]
  );

  return (
    <div className={isDoctor ? "" : "page-padding"}>
      <DropdownSelect<SelectListType>
        className="flex-none"
        selectValue={selectedTime}
        keyId="date"
        list={TimeList}
        titleRender={
          <DropdownTitle
            type="title"
            title={selectedTime.label || "近3个月"}
            isSelected={!!selectedTime.label}
          />
        }
        checkedItem={(item: SelectListType) => {
          hospitalDataStore.setSelectedTime(item);
        }}
      >
        {(val: SelectListType) => (
          <DropdownTitle type="title" title={val.label} changeColor={false} />
        )}
      </DropdownSelect>
      <Divider className="-mx-4 mt-0.5 mb-1"></Divider>

      <div>
        {list?.length > 0 ? (
          <>
            {list.map((item, index) => {
              return (
                <ListItemCard
                  type="row"
                  key={index}
                  handleClick={() => handleClick(item)}
                  className="py-4"
                >
                  <div>
                    <Typography type="title" block className="mb-2">
                      {(item as CheckOutRecordType)?.labgroupitemname ||
                        (item as CheckUpRecordType)?.requestitemname}
                    </Typography>
                    <Typography
                      type="secondary"
                      colorType="grayLightColor"
                      className="mr-2"
                    >
                      {item?.reporteddatetime || "--"}
                    </Typography>
                    <Typography type="secondary" colorType="grayLightColor">
                      {activeKey === "checkout"
                        ? userInfo?.hospitalName || "--"
                        : (item as CheckUpRecordType)?.hospitalname || "--"}
                    </Typography>
                  </div>
                  <ArrowIcon direction="right"></ArrowIcon>
                </ListItemCard>
              );
            })}
          </>
        ) : (
          <Empty title="暂无数据" />
        )}
      </div>
    </div>
  );
});
