import { SelectListType } from "./patient";

interface InfoType {
  accepteddate?: string;
  hospitalname: string;
  patientname: string;
  acceptdeptname?: string;
  currentdeptname?: string;
  admissiondxdescription?: string;
  dxdescription?: string;
  attendingdoctorname?: string;
  inhospitaldate?: string;
  dischargedate?: string;
  acceptedphysicianname?: string;
}

export interface VisitRecordType {
  info: InfoType;
  visitTypeStr: string;
}

export interface CheckOutRecordType {
  labgroupitemname: string;
  reporteddatetime: string;
  reportid: string;
}

export interface ItemRecordType {
  assessorname: string;
  hospitalname: string;
  labgroupitemname: string;
  laboperatorname: string;
  labsubitemnamecn: string;
  labvalue: string;
  patientname: string;
  referencesrange: string;
  reporteddatetime: string;
  specimensubmitdatetime: string;
  unit: string;
}

export interface CheckOutItemRecordType {
  assessorname: string;
  laboperatorname: string;
  reporteddatetime: string;
  list: ItemRecordType[];
}

export interface CheckUpRecordType {
  obsphysiciansname: string;
  assessorname: string;
  clinicaldiagname: string;
  findings: string;
  hospitalname: string;
  obsdiagtext: string;
  patientname: string;
  reporteddatetime: string;
  requestitemname: string;
}

export const TimeList: SelectListType[] = [
  {
    label: "近3个月",
    value: "0",
  },
  {
    label: "近6个月",
    value: "1",
  },
  {
    label: "近1年",
    value: "2",
  },
  {
    label: "近2年",
    value: "3",
  },
];

export const VisitTypeList: SelectListType[] = [
  {
    label: "全部",
    value: "0",
  },
  {
    label: "门诊",
    value: "1",
  },
  {
    label: "住院",
    value: "2",
  },
];
