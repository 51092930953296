import { AxiosRequestConfig } from "axios";
import {
  MesHistoryType,
  GetMesSessionIdParamsType,
  SendMessageParamsType,
  PatientMessageListType,
  AllMesListType,
} from "../models/entity/instantMessage";
import { Patient } from "../models/entity/patient";
import { UserIdentityType } from "../models/entity/user";
import { saasHttpInstance, hospitalHttpInstance } from "../utils/http";

export class InstantMessageClient {
  // 获取患者端消息列表
  async patientMessageList(params: {
    empiOrUserid: string;
    type: UserIdentityType;
  }): Promise<PatientMessageListType[]> {
    return hospitalHttpInstance.get("/mini/project/messageList", { params });
  }

  async patientMessageStatus(params: string[]) {
    return hospitalHttpInstance.post("/mini/project/messageStatus", params);
  }

  // 获取或者资料卡
  async getPatientInfo(params: {
    empiOrUserid: string;
    projectId: string;
    type: UserIdentityType;
  }): Promise<Patient> {
    return saasHttpInstance.post("/system/WxUser/getPatientInfo", params);
  }

  async getMesSessionList(): Promise<AllMesListType[]> {
    return saasHttpInstance.get("/system/WxMes/getMesSessionList", {
      customConfig: { isShowLoading: false },
    } as AxiosRequestConfig<any>);
  }

  async delSessionList(
    sessionId?: number | string
  ): Promise<MesHistoryType | boolean> {
    return saasHttpInstance.get(`/system/WxMes/getMesSessionList/${sessionId}`);
  }

  async getMesHistory(sessionId: number | string): Promise<MesHistoryType[]> {
    return saasHttpInstance.get(`/system/WxMes/getMesHistory/${sessionId}`);
  }

  async getMesSessionId(
    params: GetMesSessionIdParamsType
  ): Promise<number | string> {
    return saasHttpInstance.post("/system/WxMes/getMesSessionId", params);
  }

  async sendMessage(params: SendMessageParamsType): Promise<any> {
    return saasHttpInstance.post("/system/WxMes/sendMessage", params);
  }

  async uploadFile(formData: FormData): Promise<string> {
    return saasHttpInstance({
      method: "post",
      url: "/system/WxMes/send",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // onUploadProgress: onUploadProgress || "", //  监听文件上传进度，实时更新变量uploadPercent进行显示
      transformRequest: [
        function () {
          return formData;
        },
      ],
    });
  }

  async updateMesRead(params: number[]): Promise<boolean> {
    return saasHttpInstance.post("/system/WxMes/updateMesRead", params);
  }
}
