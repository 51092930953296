/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAppUserIdentityType } from "../../../hooks";
import useDoctorStore from "../../../store/doctor";
import usePatientStore from "../../../store/patient";
// import { Typography } from "../../../components";
import Item from "./Item";
import PatientCard from "../../Patient/PatientCard";
import calendarImg from "../../../assets/icons/doctorPhoto.svg";

export default observer(function MessageContent() {
  const { state } = useLocation();
  const doctorStore = useDoctorStore();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const {
    doctorId: doctorIdState,
    patientId: patientIdState,
    projectId,
  } = state || {};

  const MessageRef = useRef<HTMLDivElement>(null);

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const { userLogicStore, userSendMesStore } = useMemo(
    () => (isDoctor ? doctorStore : patientStore),
    [doctorStore, isDoctor, patientStore]
  );

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const doctorId = useMemo(() => {
    return isDoctor ? userProfile?.empiOrUserid || "" : doctorIdState;
  }, [doctorIdState, isDoctor, userProfile?.empiOrUserid]);

  const patientId = useMemo(() => {
    return isDoctor ? patientIdState : userProfile?.empiOrUserid || "";
  }, [isDoctor, patientIdState, userProfile?.empiOrUserid]);

  // 拿到消息的历史记录，区分是对方的消息还是自己的消息
  const currentMes = useMemo(() => {
    return userSendMesStore.messageList.filter((item: any, index: number) => {
      item.num = index;

      return item.empiOrUserid === userProfile?.empiOrUserid;
    });
  }, [userSendMesStore.messageList, userProfile?.empiOrUserid]);

  const otherMes = useMemo(() => {
    const otherId = isDoctor ? patientId : doctorId;
    return userSendMesStore.messageList.filter((item: any, index: number) => {
      item.num = index;

      return item.empiOrUserid === otherId;
    });
  }, [doctorId, isDoctor, patientId, userSendMesStore.messageList]);

  useEffect(() => {
    if (doctorId && patientId && projectId) {
      userSendMesStore.fetchMesSessionId(
        { doctorId, patientId, projectId },
        userProfile?.empiOrUserid || ""
      );
    }
  }, [doctorId, patientId, projectId, userProfile?.empiOrUserid, userSendMesStore]);

  const getPatientInfo = useCallback(() => {
    if (isDoctor && patientIdState) {
      userSendMesStore.fetchPatientInfo({
        empiOrUserid: patientIdState,
        projectId,
        type: 1,
      });
    }
  }, [isDoctor, patientIdState, projectId, userSendMesStore]);

  // 获取患者卡片
  useEffect(() => {
    getPatientInfo();
  }, [getPatientInfo]);

  useEffect(() => {
    if (MessageRef.current && userSendMesStore.messageList.length > 0) {
      const ele = (MessageRef.current as any)?.children;
      const length = ele?.length;
      // const _length = isDoctor ? length - 1 : length;
      // 滚动条定位到最新的消息
      const eleChild = document.getElementById(String(length - 1));
      if (eleChild && (length > 0 || userSendMesStore.visible)) {
        eleChild.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }
  }, [isDoctor, userSendMesStore.messageList.length, userSendMesStore.visible]);

  /**
   * 拿到消息的历史记录，区分是对方的消息还是自己的消息、时间显示
   * {
   *    time: 时间
   *    otherMes： 对方消息
   *    currentMes：自己消息
   * }
   */
  // const groupedDays = useCallback((messages: any[]) => {
  //   return messages.reduce((acc, el, i) => {
  //     el.num = i; // 设置 flex 的 order 属性
  //     const messageDay = moment(el.mesTime).format("YYYY-MM-DD");
  //     if (acc[messageDay]) {
  //       return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
  //     }
  //     return { ...acc, [messageDay]: [el] };
  //   }, {});
  // }, []);

  // const generateItems = useMemo(() => {
  //   const days = groupedDays(userSendMesStore.messageList);
  //   let newArr: any = [];
  //   Object.keys(days).forEach((key: string) => {
  //     const curKeyValue = days[key];
  //     const otherId = isDoctor ? patientId : doctorId;
  //     const newObj = {
  //       time: key,
  //       currentMes: curKeyValue.filter((item: any, index: number) => {
  //         return item.empiOrUserid === userProfile?.empiOrUserid;
  //       }),
  //       otherMes: curKeyValue.filter((item: any, index: number) => {
  //         return item.empiOrUserid === otherId;
  //       }),
  //     };
  //     newArr.push(newObj);
  //   });
  //   return newArr;
  // }, [
  //   doctorId,
  //   groupedDays,
  //   isDoctor,
  //   patientId,
  //   userProfile?.empiOrUserid,
  //   userSendMesStore.messageList,
  // ]);

  return (
    <div
      className="flex flex-col flex-1 overflow-auto p-4"
      onClick={() => {
        userSendMesStore.setVisible(false);
      }}
    >
      {isDoctor && (
        <PatientCard
          type="box"
          curItem={userSendMesStore?.patientInfo}
          className="p-3 mb-4 max-h-64"
          // handleCallBack={getPatientInfo}
        />
      )}

      <div
        className="flex flex-col flex-1 overflow-auto px-4 -mx-4"
        ref={MessageRef}
      >
        {/* todo：时间显示 */}
        {/* <Typography type="mini" block className="text-center mb-4">
          上午 11:00
        </Typography> */}

        {/* 展示对方的信息 */}
        {otherMes.map((item) => {
          return (
            <div
              key={item.id}
              className="flex mb-4"
              id={String(item.num)}
              css={css`
                order: ${item.num};
              `}
            >
              <img
                src={item?.avatarUrl || calendarImg}
                className="w-9 h-9"
                alt=""
              />
              <Item className="flex flex-col ml-3" data={item} />
            </div>
          );
        })}

        {/* 展示自己的信息 */}
        {currentMes.map((item) => {
          return (
            <div
              key={item.id}
              className="flex justify-end mb-4"
              id={String(item.num)}
              css={css`
                order: ${item.num};
              `}
            >
              <Item
                className="flex flex-col justify-end items-end mr-3"
                data={item}
              />
              <img
                src={
                  JSON.parse(localStorage.getItem("wxInfo") || "{}")
                    ?.avatarUrl || calendarImg
                }
                className="w-9 h-9"
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
