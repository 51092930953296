import { useCallback } from "react";
import { useTheme } from "../../theme";
import { Divider } from "antd-mobile";
import { CheckOutline } from "antd-mobile-icons";

export interface DropdownListType<T> {
  list: T[];
  labelStr?: string;
  valueStr?: string;
  children: Function;
  selectValue: T;
  handleSelect?: Function;
}

export default function DropdownList<T>(props: DropdownListType<T>) {
  const {
    list,
    valueStr = "value",
    children,
    selectValue,
    handleSelect,
  } = props;
  const theme = useTheme();

  const handleCallBack = useCallback(
    (item: T, index: number) => {
      handleSelect && handleSelect(item, index);
    },
    [handleSelect]
  );

  return (
    <div className="p-4 min-h-0 max-h-96 overflow-scroll overscroll-none">
      {list.map((item: T, index: number) => {
        return (
          <div key={(item as never)[valueStr]}>
            <div
              className="flex justify-between items-center"
              onClick={() => handleCallBack(item, index)}
            >
              {children(item)}

              {(selectValue as never)[valueStr] ===
              (item as never)[valueStr] ? (
                <CheckOutline fontSize={20} color={`${theme.primaryColor}`} />
              ) : null}
            </div>
            {index === list.length - 1 ? null : <Divider />}
          </div>
        );
      })}
    </div>
  );
}
