/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { LeftOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
const AgreementStatement = () => {
  const navigateTo = useNavigate();
  return (
    <section
      css={css`
        padding: 0 10px 10px 10px;
        line-height: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <LeftOutline onClick={() => navigateTo(-1)} />
        <h3
          css={css`
            text-align: center;
            margin: 15px 0;
          `}
        >
          用户服务协议及隐私声明
        </h3>
        <span></span>
      </div>

      <span>
        本协议为您与本微信小程序管理者之间所订立的契约，具有
        合同的法律效力，请您仔细阅读。
      </span>
      <br />
      <article>
        <header
          css={css`
            font-weight: 600;
            margin: 5px 0;
          `}
        >
          一、本协议内容、生效、变更
        </header>
        本协议内容包括协议正文及所有本微信小程序已经发布的或
        将来可能发布的各类规则。所有规则为本协议不可分割的组
        成部分，与协议正文具有同等法律效力。如您对协议有任何
        疑问，应向本微信小程序管理员进行咨询。您在同意所有协
        议条款并完成登记程序，才能成为本站的正式用户，您点击
        “我以阅读并同意本微信小程序用户协议和法律协议”按钮
        后，本协议即生效，对双方产生约束力。只要您使用本微
        信小程序服务，则本协议即对您产生约束，届时您不应以未
        阅读本协议的内容或者未获得本微信小程序对您问询的解答
        等理由，主张本协议无效，或要求撤销本协议。您确认:本
        协议条款是处理双方权利义务的契约，始终有效，法律另有
        强制性规定或双方另有特别约定的，依其规定。您承诺接
        受并遭守本协议的约定。如果您不同意本协议的约定，您应
        立即停止停止使用本微信小程序服务。本微信小程序有权根
        据需要不定期地制订、修改本协议及/或各类规则，并在本
        微信小程序平台公示，不再另行单独通知用户。变更后的协
        议和规则一经在网站公布，立即生效。如您不同意相关变
        更，应当立即停止使用本微信小程序服务。您继续使用本微
        信小程序服务的，即表明您接受修订后的协议和规则。
      </article>
      <article>
        <header
          css={css`
            font-weight: 600;
          `}
        >
          二、登记
        </header>
        登记资格用户须具有法定的相应权利能力和行为能力的自然人、法人或其他组织，能够独立承担法律责任。您完成登记信息程序或其他本微信公众号平台同意的方式实际使用本平台服务时，即视为您确认自己具备主体资格，能够独立承担法律责任。若因您不具备主体资格，而导致的一切后果，由您及您的监护人自行承担。
      </article>
      <article>
        <header>登记资料</header>
        2.1用户应自行诚信向本站提供登记资料，用户同意其提供的登记资料真实、准确、完整、合法有效，用户登记资料如有变动的，应及时更新其登记资料。如果用户提供的登记资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本微信公众号保留终止用户使用本平台各项服务的权利。
        <br />
        2.2用户在本站进行浏览等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。
      </article>
      <article>
        <header
          css={css`
            font-weight: 600;
          `}
        >
          三、账户
        </header>
        3.1您登记成功后，即成为本微信公众号平台随访模块的用户，将持有本微信公众号平台随访唯一编号的账户信息。
        <br />
        3.2您应谨慎合理的保存、使用您的用户名和密码，应对通过您的用户名和密码实施的行为负责。除非有法律规定或司法裁定，否则，用户名和密码不得以任何方式转让、赠与或继承。
        <br />
        3.4用户不得将在本站登记获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
        <br />
        3.5如果发现任何非法使用等可能危及您的账户安全的情形时，您应当立即以有效方式通知本微信公众号要求暂停相关服务，并向公安机关报案。您理解本微信公众号对您的请求采取行动需要合理时间，本微信公众号对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
      </article>
      <article>
        <header
          css={css`
            font-weight: 600;
          `}
        >
          四、用户信息的合理使用
        </header>
        4.1您同意本微信公众号平台拥有消息提醒的形式，向在本站登记用户发送信息等告知信息的权利。
        <br />
        4.2您了解并同意，本微信公众号有权应国家司法、行政等主管部门的要求，向其提供您在本微信公众号平台填写的登记信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权，则本微信公众号亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
        <br />
        4.3用户同意本微信公众号有权使用用户的登记信息、用户名、密码等信息，登陆进入用户的登记账户，进行证据保全，包括但不限于公证、见证等。
      </article>
      <article>
        <header
          css={css`
            font-weight: 600;
          `}
        >
          五、免责条款
        </header>
        5.1本平台仅提供信息对接，发生一切纠纷问题皆与本平台无关，请通过仲裁部门维护各自权益。
      </article>
    </section>
  );
};

export default AgreementStatement;
