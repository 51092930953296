import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../../service";
import {
  FollowupPlanInfoType,
  HealthInfoType,
} from "../../../models/entity/followUpProject";

class FollowUpProjectStore {
  followUpProjectInfo = [] as FollowupPlanInfoType[];
  projectPlanInfo = [] as FollowupPlanInfoType[];
  projectHealthInfo = {} as HealthInfoType;

  constructor() {
    makeAutoObservable(this);
  }

  followUpPlanInfo = async (id: string) => {
    const res = await serviceClient.followUpProject.followUpPlanInfo(id);
    this.followUpProjectInfo = res || [];
    this.projectPlanInfo = res?.filter((item) => {
      return item.nodeType === 1;
    });
    const healthInfo =
      res?.find((item) => {
        return item.nodeType === 0;
      })?.forms || [];

    this.projectHealthInfo = {
      noWriteList: healthInfo,
      writeList: [],
    };
  };
}

export default FollowUpProjectStore;
