export interface CommonColorsType {
  grayMainColor: string;
  graySecondaryColor: string;
  grayLightColor: string;
  grayWeakColor: string;

  primaryColor: string;
  secondaryColor: string;
  successColor: string;
  dangerColor: string;
  warningColor: string;
  whiteColor: string;
}

interface ThemeType extends CommonColorsType {
  borderColor: string;
  boxShadowColor: string;

  boxBackgroundColor: string;

  styles: {
    border: string;
    boxShadow: string;
  };
}

export const commonColors: CommonColorsType = {
  grayMainColor: "#333333",
  graySecondaryColor: "#666666",
  grayLightColor: "#999999",
  grayWeakColor: "#C8C9CC",

  primaryColor: "#0052E1",
  secondaryColor: "#5c95f7",
  successColor: "#0CB57A",
  dangerColor: "#D62E34",
  warningColor: "#F7AA57",
  whiteColor: "#ffffff",
};

const defaultTheme: ThemeType = {
  ...commonColors,

  borderColor: "#F5F7FA",
  boxShadowColor: "rgba(0, 82, 225, 0.06)",

  boxBackgroundColor: "rgba(247, 248, 250, 0.6)",

  styles: {
    border: "1px solid #f5f7fa",
    boxShadow: "0px 1px 10px rgba(0, 82, 225, 0.06)",
  },
};

export const useTheme = () => defaultTheme;
