export const getWxCode = () => {
  const appid = window._settings.appid; //个人公众号appid(),在微信公众平台获取
  const redirectUrl = window._settings.redirectUri;

  //url中没有code,重定向到微信接口获取code，此时code在url中
  window.location.href =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
    appid +
    "&redirect_uri=" +
    redirectUrl +
    "/bindingRole" +
    "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
};

export const getUrlParam = (name: any) => {
  // 获取url指定参数
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标的正则表达式对象
  const r = window.location.search.substr(1).match(reg); // 匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; // 返回参数值
};
