/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../../theme";
import PatientFileItem from "./PatientFileItem";
import { Typography, SearchBar } from "../../../components";
import ActionSheet from "./ActionSheet";
import { Checkbox, Space } from "antd-mobile";
import { useDoctorRouter } from "../../../router/Doctor";
import useDoctorStore from "../../../store/doctor";
import { serviceClient } from "../../../service";
import {
  FileListPromiseType,
  FilesListType,
} from "../../../models/entity/patient";
import { useDebounce } from "../SearchResult";
import Empty from "../../../coreUIComponents/Empty";

interface PatientFileListType {
  type?: "list" | "search";
  activeKey: string;
  checkValue?: string[];
  onchange?: (val: string[]) => void;
}

export default observer(function PatientFileList(props: PatientFileListType) {
  const theme = useTheme();
  const { state } = useLocation();
  const router = useDoctorRouter();
  const doctorStore = useDoctorStore();
  const { patientIdO, projectId } = state || {};
  const { patientEntityStore, userLogicStore } = doctorStore;
  const { type = "list", activeKey, checkValue, onchange } = props;

  const { searchVal, fileList } = useMemo(
    () => patientEntityStore,
    [patientEntityStore]
  );

  const userInfo = useMemo(
    () => userLogicStore.userInfo,
    [userLogicStore.userInfo]
  );

  const useDebounceKey = useDebounce(searchVal, 500);

  const params = useMemo(() => {
    return {
      patientId: patientIdO,
      projectId,
      searchVal: useDebounceKey,
      type: Number(activeKey),
    };
  }, [activeKey, patientIdO, projectId, useDebounceKey]);

  const patientFileList = useCallback(() => {
    patientEntityStore.patientFileList(params);
  }, [params, patientEntityStore]);

  useEffect(() => {
    patientFileList();
  }, [patientFileList]);

  const handleChange = useCallback(
    (val: string[]) => {
      onchange && onchange(val);
    },
    [onchange]
  );

  // 修改名字方法
  const onConfirm = useCallback(
    (id: string, name: string) => {
      patientEntityStore.renameFile({ id, name }, params);
      handleChange([]);
    },
    [handleChange, params, patientEntityStore]
  );

  const handleDelFile = useCallback(
    (val: string[]) => {
      patientEntityStore.delFile(val, params);
      handleChange([]);
    },
    [handleChange, params, patientEntityStore]
  );

  const handleUploadFile = useCallback(
    async (e: any) => {
      const arr: any = Array.from(e.target.files);
      const formData = new FormData();
      arr.forEach((item: File) => {
        formData.append("filelist", item);
      });
      formData.append("patientId", state?.patientIdO);
      formData.append("projectId", state?.projectId);
      formData.append("operator", userInfo?.name || "");
      const res = await serviceClient.patient.fileUpload(formData);
      if (res?.code === 200) {
        patientFileList();
      }
    },
    [patientFileList, state?.patientIdO, state?.projectId, userInfo?.name]
  );

  const toSearchPage = useCallback(() => {
    router.navigateTo("patientSearch", { state: { type: "file", activeKey } });
  }, [activeKey, router]);

  const _style = useMemo(() => {
    return [
      css`
        .adm-checkbox-content {
          width: 100%;
        }
      `,
      type === "search" &&
        css`
          .adm-checkbox-icon {
            display: none;
          }
          .adm-checkbox-content {
            padding-left: 0;
          }
        `,
    ];
  }, [type]);

  return (
    <div>
      {type === "list" && fileList?.length > 0 ? (
        <div
          css={css`
            border-bottom: 1px solid ${theme.borderColor};
          `}
          className="py-2"
        >
          <SearchBar placeholder="查询文件" onFocus={toSearchPage} />
        </div>
      ) : null}
      <div className="mt-2 pb-14">
        {fileList?.length > 0 ? (
          <>
            {fileList.map((item: FileListPromiseType, index: number) => {
              return (
                <div key={activeKey + index} className="my-3">
                  <Typography
                    type="mini"
                    colorType="graySecondaryColor"
                    block
                    className="mb-2"
                  >
                    {item.timeFormat}
                  </Typography>
                  <Checkbox.Group
                    value={checkValue}
                    onChange={(val) => {
                      handleChange(val as string[]);
                    }}
                  >
                    <Space direction="vertical" className="w-full">
                      {item.files.map((file: FilesListType) => {
                        return (
                          <Checkbox
                            key={file.id}
                            value={file.id}
                            block
                            className="mb-2"
                            css={_style}
                          >
                            <PatientFileItem
                              data={file}
                              keyword={searchVal}
                              confirm={onConfirm}
                            />
                          </Checkbox>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </div>
              );
            })}
          </>
        ) : (
          <Empty title="暂无数据" />
        )}
      </div>
      <ActionSheet
        checkboxValue={checkValue}
        fileList={fileList}
        onDelFile={handleDelFile}
        onUploadFile={handleUploadFile}
      />
    </div>
  );
});
